import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as DeleteIcon } from '../../assets/delicon.svg';
import { ReactComponent as EditIcon } from '../../assets/editicon.svg';
import Tag from '../Tag/Tag';
import { styled } from '@mui/system';
import StyledAvatar from '../Avatar/Avatar';
import { Typography } from '@mui/material';
import { getAllPermissions } from '../../redux/services/permissions';
import { useDispatch } from 'react-redux';
import Modal from '../Modal/Modal';
import RemoveUser from '../RemoveUser/RemoveUser';
import UpdateUserInviteForm from '../InviteUserForm/UpdateUserInviteForm';
import CheckPermissions from '../CheckPermissions/CheckPermissions';
import apiConfig from 'src/config/api.service';
import { useTranslation } from 'react-i18next';

const TagsWrapper = styled('div')({
  display: 'flex',
  alignItem: 'center',
  flexWrap: 'wrap',
  marginBottom: '-8px',
});
const StyledTableCell = styled(TableCell)({
  padding: '8px 16px',
  borderBottom: 'none',
  fontFamily: 'Open Sans',
  fontWeight: '500',
  '& .name': {
    width: '130px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .email': {
    width: '185px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const StyledTableHead = styled(TableHead)({
  '& .MuiTableRow-root .MuiTableCell-root': {
    color: '#7B858F',
    fontSize: '12px',
  },
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFBF5',
  },
});
const ActionsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '68px',
  cursor: 'pointer',
});
const AvatarContainer = styled('div')({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  marginRight: '5px',
  '& img': {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
});
const NameCell = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const PendingStatus = styled('div')({
  width: '100%',
  height: '100%',
  border: '1px dotted #BEC2C7',
  borderRadius: '50%',
  background: 'white',
  boxSizing: 'border-box',
});
function UsersList({
  users,
  filter,
  filteredUsers,
  setUsers,
  setOpenSnackbar,
  setMessageObject,
}) {
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState();
  const [handleModalKey, setHandleModalKey] = useState(users);
  const { t } = useTranslation();

  const handleClickOpen = (data, key) => {
    setOpen(true);
    setUserData(data);
    setHandleModalKey(key);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  // console.log('user', users);

  useEffect(() => {
    dispatch(getAllPermissions)()
      .then((res) => {})
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);

  // let filteredUsers = users;
  // if (filter) {
  //   // console.log('INSIDE filter');
  //   filteredUsers = filteredUsers.filter((user) =>
  //     user?.name?.toLowerCase().includes(filter?.toLowerCase())
  //   );
  // }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 1100 }}>
        <StyledTableHead>
          <TableRow>
            <StyledTableCell>{t(`Name`)}</StyledTableCell>
            <StyledTableCell>{t(`Email Address`)}</StyledTableCell>
            <StyledTableCell>{t(`Permissions`)}</StyledTableCell>
            <CheckPermissions
              requiredPermission={
                ['63da31567c1f7ad8118adedb'] || ['63da30f46763aed6744ad976']
              }
            >
              <StyledTableCell>{t(`Actions`)}</StyledTableCell>
            </CheckPermissions>
          </TableRow>
        </StyledTableHead>

        <TableBody>
          {filteredUsers &&
            filteredUsers?.map((ele, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <NameCell>
                    {/* {console.log('Status', ele?.status)} */}
                    {ele?.status === 'pending' ? (
                      <>
                        <AvatarContainer>
                          <StyledAvatar width={24} height={24}>
                            <PendingStatus />
                          </StyledAvatar>
                        </AvatarContainer>
                        <Typography
                          sx={{
                            color: '#7B858F',
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontFamily: 'Open Sans',
                          }}
                        >
                          {ele.status}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <AvatarContainer>
                          {/* {console.log('ele', ele)} */}
                          {ele.image ? (
                            <StyledAvatar
                              src={apiConfig.imageUrlPrefix + ele.image}
                              width={24}
                              height={24}
                            />
                          ) : (
                            <StyledAvatar width={24} height={24}>
                              {ele.name ? ele.name.charAt(0).toUpperCase() : ''}
                            </StyledAvatar>
                          )}
                        </AvatarContainer>
                        <p className='name'> {ele.name || t('user')} </p>
                      </>
                    )}
                  </NameCell>
                </StyledTableCell>
                <StyledTableCell>
                  <p className='email'> {ele.email} </p>
                </StyledTableCell>
                <StyledTableCell>
                  <TagsWrapper>
                    {ele.permissions.map((ele, i) =>
                      ele.name ? (
                        <Tag style={{ marginBottom: '8px' }} key={i}>
                          {t(ele.name)}
                        </Tag>
                      ) : null
                    )}
                  </TagsWrapper>
                </StyledTableCell>
                <StyledTableCell>
                  <ActionsWrapper>
                    <CheckPermissions
                      requiredPermission={['63da31567c1f7ad8118adedb']}
                    >
                      <DeleteIcon onClick={() => handleClickOpen(ele, 'del')} />
                    </CheckPermissions>

                    <CheckPermissions
                      requiredPermission={['63da30f46763aed6744ad976']}
                    >
                      <EditIcon onClick={() => handleClickOpen(ele, 'edit')} />
                    </CheckPermissions>
                  </ActionsWrapper>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {open && (
        <Modal
          handleClose={handleClose}
          open={open}
          title={handleModalKey === 'del' ? t('Remove User') : t('Edit User')}
        >
          {handleModalKey === 'del' ? (
            <RemoveUser
              handleClose={handleClose}
              userData={userData}
              setUsers={setUsers}
              users={users}
              setOpenSnackbar={setOpenSnackbar}
              setMessageObject={setMessageObject}
            />
          ) : (
            <UpdateUserInviteForm
              user={userData}
              handleClose={handleClose}
              users={users}
              setUsers={setUsers}
              setOpenSnackbar={setOpenSnackbar}
              setMessageObject={setMessageObject}
            />
          )}
        </Modal>
      )}
    </TableContainer>
  );
}

export default UsersList;
