/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from 'react';
import FileUploadIcon from 'src/assets/fileUpload.png';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";


const DragDropContainer = styled('div')`
  min-windth: 400px;
  min-height: 200px;
  background: #f6eee0;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  .text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 500;
    color: #292d32;
  }
  .file-format-text {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    color: #7b858f;
  }
`;

const dragDropDashedBorder = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  border: '1px dashed #BB8D0B',
  margin: '10px',
  minWidth: '384',
  minHeight: '184px',
};

const dragDropFileUpload = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
};

const DragDrop = ({ handleFileUpload }) => {
  const inputRef = useRef();
  const {t}=useTranslation();

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <DragDropContainer
      onClick={handleClick}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={handleFileUpload}
    >
      <div style={dragDropDashedBorder}>
        <div style={dragDropFileUpload}>
          <img
            src={FileUploadIcon}
            style={{ marginBottom: '7px' }}
            width='38px'
            height='38px'
          />
          <input
            ref={inputRef}
            type='file'
            webkitdirectory
            directory
            multiple
            onChange={handleFileUpload}
            hidden
            accept='image/png, image/jpeg, image/gif'
          />
          <p className='text'>{t(`Drop your file folder here or browse`)}</p>
          <p className='file-format-text'>PNG, JPG, or GIF</p>
        </div>
      </div>
    </DragDropContainer>
  );
};

export default DragDrop;
