export const actions = {
  SET_DRAWER: 'SET_DRAWER',
  SET_PAGES: 'SET_PAGES',
  SET_TAGS: 'SET_TAGS',
  SET_JOURNAL_DETAILS: 'SET_JOURNAL_DETAILS',
  SET_LANGUAGE_TAGS: 'SET_LANGUAGE_TAGS',
  SET_LOCALE: 'SET_LOCALE',
};

export const setDrawer = (data) => ({
  type: actions.SET_DRAWER,
  data,
});

export const setPages = (data) => ({
  type: actions.SET_PAGES,
  data,
});

export const setAllTags = (data) => ({
  type: actions.SET_TAGS,
  data,
});

export const setAllLanguageTags = (data) => ({
  type: actions.SET_LANGUAGE_TAGS,
  data,
});

export const setJournalDetails = (data) => ({
  type: actions.SET_JOURNAL_DETAILS,
  data,
});

export const setLocale = (data) => ({
  type: actions.SET_LOCALE,
  data,
});
