import { setCategory } from '../actions/category';
import { request } from '../../shared/verb';
import { setPages } from '../actions/general';



export const getPresignedURL = (data) =>
  request(`/users/presigned-url`, { ...data }, 'post').then((res) => {
    return res.data;
  });

export const addCategory = (data,locale) =>
  request(`/category`, { ...data }, 'post',null,null,locale).then((res) => {
    return res;
  });

export const getAllCategories = (dispatch, locale) => () => {
   console.log(locale)
  return request(`/category`, 'get',null,null,null,locale).then((res) => {
    dispatch(setCategory(res.data));
    return res.data;
  });
}

export const deleteCategory = (data) =>
  request(`/category/${data}`, {}, 'delete').then((res) => {
    return res;
  });

export const updateCategory = (data, id, locale) =>
  request(`/category/${id}`, { ...data }, 'put',null,null,locale).then((res) => {
    return res;
  });

export const addPage = (data) => {
  return request(`/page`, { ...data }, 'post').then((res) => {
    return res?.data?.data;
  });
};
export const getPages = (journalId, dispatch, locale) => {
  console.log(locale);
  return request(`/page?journal=${journalId}`, 'get',null ,null,null, locale)
    .then((res) => {
      dispatch(setPages(res?.data?.data));
      return res?.data?.data;
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const deletePage = (pageId) => {
  return request(`/page/${pageId}`, {}, 'delete').then((res) => {
    return res;
  });
};

export const updatePage = (pageId, data, language) => {
  return request(`/page/${pageId}`, { ...data }, 'put',null,null, language).then((res) => {
    return res?.data?.data;
  });
};

export const sortPages = (data) => {
  return request(`/page/sort-pages`, { ...data }, 'put').then((res) => {
    // console.log(res);
    return res?.data?.data;
  });
};
