import { actions } from '../actions/auth';

const initialState = {
  user: null,
  authenticated: null,
  profileImage: null,
};

export const auth = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_USER:
      return { ...state, user: data };
    case actions.UPDATE_USER:
      return { ...state, user: { ...state.user, ...data } };
    case actions.SET_AUTHENTICATED:
      return { ...state, authenticated: data };
    case actions.LOGOUT_USER:
      return {
        user: null,
        authenticated: null,
      };
    case actions.PROFILE_UPLOAD_USER:
      return { ...state, profileImage: data };
    default:
      return state;
  }
};
