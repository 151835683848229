import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from '../../containers/Register/Register';
import SignIn from '../../containers/SignIn/SignIn';
import ForgotPassword from '../../containers/ForgotPassword/ForgotPassword';
import ResetPassword from '../../containers/ResetPassword/ResetPassword';
import Invite from '../../containers/Invite/Invite';
import ResetPasswordInvite from '../../containers/ResetPasswordInvite/ResetPasswordInvite';

function Authentication() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path='/'
            element={
              <SignIn
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
              />
            }
          />
          <Route
            path='/invite'
            element={<Invite setOpenSnackbar={setOpenSnackbar} />}
          />
          <Route
            path='/register'
            element={<Register />}
            // element={
            //   localStorage.getItem('InviteData')
            //     ? // <Register />
            //       console.log(localStorage.getItem('InviteData'))
            //     : // <Navigate to='/' />
            //       console.log(localStorage.getItem('InviteData'))
            // }
          />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/new-pass' element={<ResetPassword />} />
          <Route path='/reset-password' element={<ResetPasswordInvite />} />
          <Route exact path='*' element={<SignIn />} />
        </Routes>
      </Router>
    </div>
  );
}

export default Authentication;
