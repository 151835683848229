export const actions = {
  SET_USER: 'SET_USER',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  UPDATE_USER: 'UPDATE_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  PROFILE_UPLOAD_USER: 'PROFILE_UPLOAD_USER',
};

export const setUser = (data) => ({
  type: actions.SET_USER,
  data,
});
export const updateUserState = (data) => ({
  type: actions.UPDATE_USER,
  data,
});
export const setAuthenticated = (data) => ({
  type: actions.SET_AUTHENTICATED,
  data,
});
export const setLogoutState = (data) => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('token');
  localStorage.removeItem('time');

  return {
    type: actions.LOGOUT_USER,
    data,
  };
};

export const setUserProfileImage = (data) => ({
  type: actions.PROFILE_UPLOAD_USER,
  data,
});
