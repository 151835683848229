import React from 'react';
import RegisterLayout from '../../components/RegisterLayout/RegisterLayout';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';

function ResetPassword() {
  return (
    <RegisterLayout>
      <ResetPasswordForm
        title='NEW PASSWORD?'
        subtitle='Enter your new password below to reset your password.'
      />
    </RegisterLayout>
  );
}

export default ResetPassword;
