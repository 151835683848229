import { request } from '../../shared/verb';
import { setAllLanguageTags, setAllTags } from '../actions/general';


export const getAllTags = (dispatch,locale) => () =>{
  return request(`/tag`, 'get').then((res) => {
    // console.log(res);
    dispatch(setAllTags(res?.data));
    return res?.data;
  });
}

  //?translateTags=1

export const getAllLanguagesTags =(dispatch, locale)=>{
  return request(`/tag?translateTags=1`, 'get',null,null,null, locale).then((res) => {
    // console.log(res);
    dispatch(setAllLanguageTags(res?.data?.data));
    return res?.data?.data;
  });
}