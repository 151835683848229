import React from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';

const AppWrapperComponent = styled('div')(({ drawer }) => ({
  marginTop: '56px',
  height: 'calc(100vh - 56px)',
  overflow: 'auto',
  marginLeft: drawer ? '170px' : '62px',
}));

function AppWrapper({ children }) {
  const { drawer } = useSelector((state) => state.general);

  return (
    <div>
      <Header/>
      <Sidebar />
      <AppWrapperComponent drawer={drawer}>{children}</AppWrapperComponent>
    </div>
  );
}

export default AppWrapper;
