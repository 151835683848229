import React from 'react'
import NotFound from 'src/assets/datanotfound.png'

const notFoundStyles={
 display: 'flex',
 flexDirection: 'column',
 alignItems: 'center',
 alignSelf: 'center',
 width: '252px',
 height: '291px',
 position: 'absolute',
 top: '35%',
 left: '45%',

}
const textStyle={
  fontFamily: 'brandon-grotesque',
  fontWeight: '420',
  fontSize: '20px',
  color: '#BB8D0B'
}
const DataNotFound = ({data="Data"}) => {
  return (
    <div style={notFoundStyles}>
      <img src={NotFound} width="252px" height="250px" alt="not-found"/>
      <p style={textStyle}>No {data} found</p>
    </div>
  )
}

export default DataNotFound;