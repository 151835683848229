import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UploadIcon } from '../../assets/upload-icon.svg';

const Container = styled('div')({
  boxSizing: 'border-box',
  position: 'relative',
  padding: (props) => (props.secondary === 'true' ? '0px' : '6px'),
  // background: '#F6EEE0',
  background: (props) => (props.secondary === 'true' ? '#F2F2F2' : '#F6EEE0'),
  // width: '200px',
  width: (props) => (props.secondary === 'true' ? '100%' : '200px'),
  // height: '200px',
  height: (props) => (props.secondary === 'true' ? '477px' : '200px'),
  margin: 'auto',
  '& img': {
    width: '100%',
    height: '100%',
  },
});
const Icon = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
});

function ImagePreview({
  src,
  alt,
  id,
  onClick,
  secondary,
  apiCachePayload,
  ...props
}) {
  return (
    <Container secondary={secondary} {...props}>
      <Icon>
        <UploadIcon onClick={onClick} />
      </Icon>
      <img src={src} alt={alt} id={id} />
    </Container>
  );
}

export default ImagePreview;
