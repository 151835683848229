import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/closeicon.svg';
import { Typography } from '@mui/material';
import TranslateButton from 'src/components/Button/TranslateBtn';
import { ReactComponent as TranslateIcon } from 'src/assets/translate-icon.svg';
import { useTranslation } from "react-i18next";

const ModalContainer = styled(Dialog)({
  '& .MuiPaper-root': {
    // minHeight: '350px',
    minHeight: (props) =>
      props.variant === 'image-cropper' ? '525px' : '350px',
    // minWidth: '430px',
    minWidth: (props) =>
      props.variant === 'image-cropper' ? '600px' : '430px',
    boxShadow: '0px 10px 40px rgb(0 0 0 / 10%)',
    '& .ReactCrop__child-wrapper': {
      width: '100%',
    },
  },
  '& .MuiBackdrop-root': {
    background: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(2px)',
  },
});

const TitleWrapper= styled('div')({
  fontSize: "16px",
  height: "56px",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  fontFamily: "Open Sans",
  width: '92%',
  fontWeight: "600",
  flexDirection: 'row',
  justifyContent: 'space-between',

})

function Modal({
  title,
  style = {},
  handleClose,
  open,
  subtitle,
  children,
  variant,
  categoryTranslation,
  translateBtn=false, 
  loading,
}) {
  const {t}=useTranslation();
  return (
    <>
      {" "}
      <ModalContainer
        PaperProps={{
          style: { ...style },
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        variant={variant}
      >
        <DialogTitle
          sx={{
            m: 0,
            borderBottom: "1px solid #F2F2F2",
            fontSize: "16px",
            height: "56px",
            padding: "8px 16px",
            boxSizing: "border-box",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            marginBottom: variant === "image-cropper" ? "0px" : "16px",
            fontFamily: "Open Sans",
            fontWeight: "600",
          }}
        >
          <TitleWrapper>
          <p>{title}</p>
          {translateBtn ? 
          <TranslateButton onClick={categoryTranslation} loading={loading}>
            <TranslateIcon style={{ marginRight: "4px" }} />
            {t(`Translate`)}
          </TranslateButton> : null
          }
          </TitleWrapper>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography
          variant="subtitle"
          align="left"
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            fontFamily: "Open Sans",
            padding: "0px 16px",
          }}
        >
          {subtitle}
        </Typography>
        {children}
      </ModalContainer>
    </>
  );
}

export default Modal;
