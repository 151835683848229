import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  en: {
    translation: {
      FILES: 'FILES',
    },
  },
  sw: {
    translation: {
      FILES: 'FILER',
      'Search for tags...': 'Sök efter taggar...',
      'New File': 'Ny fil',
      Everything: 'Allt',
      'File Title': 'Filens titel',
      Category: 'Kategori',
      'About this file': 'Om den här filen',
      Contributor: 'Arkivbildare',
      Year: 'År',
      'Short Description': 'Kort beskrivning',
      Tags: 'Taggar',
      Pages: 'Sidor',
      'Upload Page': 'LADDA UPP SIDA',
      'Total Pages': 'Totalt antal sidor',
      'View All Pages': 'Visa alla sidor',
      'Save Details': 'Spara detaljer',
      Max: 'Max',
      characters: 'tecken',
      Translate: 'Översätt',
      BACK: 'TILLBAKA',
      'File Name': 'Filnamn',
      Uploader: 'Uppladdare',
      'Last Update': 'Senaste uppdateringen',
      'EDIT FILE': 'REDIGERA FIL',
      'ADD NEW FILE': 'LÄGG TILL NY FIL',
      'Add Detail': 'Lägg till detalj',
      'Replace this page': 'Byt ut den här sidan',
      'Page Number': 'Sidnummer',
      'Page Description': 'Sidbeskrivning',
      'Add Page Detail': 'Lägg till siddetalj',
      'Remove Page': 'Ta bort sida',
      Remove: 'Avlägsna',
      'TEST JOURNAL': 'TESTJOURNAL',
      CATEGORY: 'KATEGORI',
      CATEGORIES: 'KATEGORIER',
      'New Category': 'Ny kategori',
      SETTINGS: 'INSTÄLLNINGAR',
      Settings: 'inställningar',
      'Account Details': 'Kontouppgifter',
      'Ideal dimensions': 'Idealiska dimensioner',
      'Max size': 'Max storlek',
      'Full Name': 'Fullständigt namn',
      'Email address': 'E-postadress',
      Password: 'Lösenord',
      'Change Password': 'Ändra lösenord',
      'Save Changes': 'Spara ändringar',
      'Click to upload Image': 'Klicka för att ladda upp bild',
      Users: 'Användare',
      Files: 'Filer',
      Logout: 'Logga ut',
      USERS: 'Användare',
      Name: 'namn',
      'Email Address': 'E-postadress',
      Permissions: 'Behörighet',
      Actions: 'Handlingar',
      'Invite User': 'Bjud in användare',
      'Drop your file folder here or browse':
        'Släpp din filmapp här eller bläddra',
      'Upload and attach file': 'Ladda upp och bifoga fil',
      Upload: 'Ladda upp',
      Cancel: 'Annullera',
      'Remove File': 'Ta bort fil',
      'Update File': 'Uppdatera fil',
      'Update Category': 'Uppdatera kategori',
      'Add/Remove Category': 'Lägg till/ta bort kategori',
      'Add File': 'Lägg till fil',
      'Remove User': 'Ta bort användare',
      'Update User': 'Uppdatera användare',
      'Send invite': 'Skicka inbjudan',
      'All invited people will be granted access to the site.':
        'Alla inbjudna personer kommer att ges tillgång till webbplatsen.',
      'Edit User': 'Redigera användare',
      Send: 'Skicka',
      'Are you really want to remove': 'Vill du verkligen ta bort',
      'If you remove this page, it will permanently remove from file.':
        'Om du tar bort den här sidan kommer den att tas bort permanent från filen.',
      'If you remove this file, it will permanently remove from file’s list.':
        'Om du tar bort den här filen kommer den att tas bort permanent från filens lista.',
      'If you remove this user, it will remove permanently from user’s list.':
        'Om du tar bort den här användaren tas den bort permanent från användarens lista.',
      'Remove Category': 'Ta bort kategori',
      'Edit Category': 'Redigera kategori',
      'If you remove this category, it will remove permanently from the list.':
        'Om du tar bort den här kategorin tas den bort permanent från listan.',
      'Category Name': 'Kategorinamn',
      'Category Description': 'Kategoribeskrivning',
      'Search here...': 'Sök här...',
      'Category name cannot be greater than 120 characters.':
        'Kategorinamnet får inte vara längre än 120 tecken.',
      'This field is required.': 'Detta fält är obligatoriskt.',
      'Category description cannot be greater than 5000 characters.':
        'Kategoribeskrivningen får inte vara längre än 5 000 tecken.',
      'You need to provide a file': 'Du måste tillhandahålla en fil',
      'Add Category': 'Lägg till Kategori',
      'Enter Category Description': 'Ange Kategoribeskrivning',
      'Enter Category Name': 'Ange Kategorinamn',
      'Must be less than 120 characters': 'Måste vara mindre än 120 tecken',
      'Must be less than 5000 characters': 'Måste vara mindre än 5000 tecken',
      'Must be less than 1000 characters': 'Måste vara mindre än 1000 tecken',
      'File Not Supported': 'Filen stöds inte',
      'Category added successfully': 'Kategori har lagts till',
      'Category updated': 'Kategori uppdaterad',
      'Journal removed': 'Journal borttagen',
      'File type/size not supported.': 'Filtyp/storlek stöds inte.',
      'Category removed': 'Kategori borttagen',
      'User removed': 'Användaren har tagits bort',
      'Create Your Journal': 'Skapa din journal',
      'Journal added successfully': 'Journalen har lagts till',
      'Journal Updated.': 'Journalen uppdaterad.',
      'SIGN IN': 'LOGGA IN',
      'Welcome to the Åland Maritime Museum admin panel':
        'Välkommen till Ålands sjöfartsmuseums adminpanel',
      'Invite link is invalid or expired.':
        'Inbjudningslänken är ogiltig eller har löpt ut.',
      'Enter page description': 'Ange sidbeskrivning',
      'Enter file title': 'Ange filtitel',
      'Select Category': 'Välj kategori',
      'Enter details about this file': 'Ange information om denna fil',
      'Enter contributor': 'Ange arkivbildare',
      'Enter year': 'Ange år',
      'Enter short description': 'Ange kort beskrivning',
      'Forgot password?': 'Glömt ditt lösenord?',
      'FORGOT PASSWORD?': 'GLÖMT DITT LÖSENORD?',
      Email: 'E-post',
      'We’ve sent the password reset instructions to your email if it has been registered with us.':
        'Vi har skickat instruktionerna för återställning av lösenord till din e-post om det har registrerats hos oss.',
      'No problem! Just enter the email address that you signed up with to reset it.':
        'Inga problem! Ange bara e-postadressen som du registrerade dig med för att återställa den.',
      'SEND RESET INSTRUCTION': 'SKICKA ÅTERSTÄLLNINGSINSTRUKTION',
      'Please enter valid email.': 'Vänligen ange giltig e-postadress.',
      'Email is Invalid': 'E-postadressen är felaktig',
      'Password must be at least 6 characters.':
        'Lösenordet måste bestå av minst 6 tecken.',
      'Password is incorrect': 'Lösenord är inkorrekt',
      'Choose File': 'Välj FIL',
      'Ideal dimensions: 100x100 pixels. (Max size - 5mb )':
        'Idealiska mått: 100x100 pixlar. (Max storlek - 5 MB)',
      REGISTER: 'REGISTRERA',
      'Confirm Password': 'Bekräfta lösenord',
      'Both password need to be the same': 'Båda lösenorden måste vara desamma',
      'Full name is required.': 'Fullständigt namn krävs.',
      'Page Updated': 'Sidan uppdaterad',
      'Hero Title': 'Huvud Titel',
      'Hero Sub-Heading': 'Huvud Underrubrik',
      'Hero Image': 'Huvud Bild',
      'Aspect Ratio': 'Bildförhållande',
    },
  },
};

const getLocale = () => {
  return localStorage.getItem('locale') || 'sw';
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLocale(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
