import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import UploadJournalCoverForm from 'src/components/AddJournalForm/UploadJournalCover/UploadJournalCoverForm';
import AppWrapper from 'src/components/AppWrapper/AppWrapper';
import TopBar from 'src/components/TopBar/PublicSite';
import PublicSiteTopRightBar from 'src/components/TopRightBar/PublicSite';
import { ReactComponent as CoverIcon } from '../../assets/cover-icon.svg';
import AddHeroSectionForm from 'src/components/AddHeroSectionForm/AddHeroSectionForm';
import { addHeroSectionSchema } from '../../components/AddHeroSectionForm/validation';
import {
  translateData,
  translateDataSWToEN,
} from '../../redux/services/general';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addConfig, getConfig } from '../../redux/services/publicSite';
// import Loader from 'src/components/Loader/Loader';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import Loader from 'src/components/Loader/Loader';

const StyledGrid = styled(Grid)({
  height: 'calc(100vh - 125px)',
  padding: '19px 17px',
  // maxWidth: '622px',
});
const StyledImageHeader = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '11px 12px',
  maxWidth: '517px',
  border: '1px solid #F2F2F2',
});

function PublicSite() {
  const [fileCover, setFileCover] = useState('');
  const [fileData, setFileData] = useState();
  const [open, setOpen] = useState(false);
  const [swFields, setSwFields] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [translateBtnLoading, setTranslateBtnLoading] = useState(false);
  const [apiCachePayload, setApiCachePayload] = useState({});
  const { locale } = useSelector((state) => state.general);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});

  const { t } = useTranslation();
  const handleTranslateData = async () => {
    const payload = {};
    const { pb_subtitle_en, pb_title_en, pb_subtitle_sw, pb_title_sw } =
      fileData || {};

    if (locale === 'en') {
      if (apiCachePayload?.pb_subtitle_sw !== pb_subtitle_en)
        payload.pb_subtitle_sw = pb_subtitle_en;
      if (apiCachePayload?.pb_title_sw !== pb_title_en)
        payload.pb_title_sw = pb_title_en;
      console.log('payload', payload, apiCachePayload);
    } else {
      if (apiCachePayload?.pb_subtitle_en !== pb_subtitle_sw)
        payload.pb_subtitle_en = pb_subtitle_sw;
      if (apiCachePayload?.pb_title_en !== pb_title_sw)
        payload.pb_title_en = pb_title_sw;
      console.log('payload', payload, apiCachePayload);
    }

    setApiCachePayload((state) => ({ ...state, ...payload }));
    let translatedData;
    if (locale === 'en') {
      translatedData = await translateData(payload);
    } else {
      translatedData = await translateDataSWToEN(payload);
    }
    // const response = await translateData(payload, locale);
    setFileData({ ...fileData, ...translatedData });
    // console.log(response);

    setSwFields(true);
  };

  useEffect(() => {
    // console.log('file Data', fileData);
    addHeroSectionSchema
      .validate(fileData)
      .then(function (value) {
        setDisabledButton(false);
        // console.log(fileData);
      })
      .catch(function (err) {
        console.log('err', err);
        setDisabledButton(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  const handleSaveDetails = () => {
    setLoading(true);
    const data = {
      ...fileData,
      image: fileCover || fileData?.image,
    };
    console.log('Final Data -> ', data);

    addConfig(data, locale)
      .then((res) => {
        console.log('RESPONSE', res);
        setLoading(false);
        setOpenSnackbar(true);
        setMessageObject({ severity: 'success', text: res?.message });
      })
      .catch((err) => {
        console.log('ERR', err);
        setLoading(false);
        setOpenSnackbar(true);
        setMessageObject({ severity: 'error', text: err?.message });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    // console.log('fileData', fileData);
    getConfig(locale)
      .then((res) => {
        console.log(res);
        let data;
        if (locale === 'en') {
          data = {
            image: res?.data?.image,
            pb_subtitle_en: res?.data?.pb_subtitle,
            pb_title_en: res?.data?.pb_title,
          };
        } else {
          data = {
            image: res?.data?.image,
            pb_subtitle_sw: res?.data?.pb_subtitle,
            pb_title_sw: res?.data?.pb_title,
          };
        }
        console.log('data', data);
        setFileData((prevData) => {
          return { ...prevData, ...data };
        });
      })
      .catch((err) => console.log(err));
    setSwFields(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
  const handleSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <AppWrapper>
      <TopBar
        fontSize='20px'
        title='PUBLIC SITE'
        translate={true}
        setTranslateBtnLoading={setTranslateBtnLoading}
        translateBtnLoading={translateBtnLoading}
        handleTranslateData={handleTranslateData}
        rightSide={
          <PublicSiteTopRightBar
            disabledButton={disabledButton}
            handleClickOpen={handleSaveDetails}
            loading={loading}
          />
        }
      />
      {fileData ? (
        <StyledGrid container>
          <Grid
            item
            xs={7}
            sx={{ p: 1, height: 'calc(100vh - 150px)', overflow: 'auto' }}
          >
            <AddHeroSectionForm
              // setEditedFields={setEditedFields}

              swFields={swFields}
              setFileData={setFileData}
              fileData={fileData}
            />
          </Grid>
          <Grid item xs={5} sx={{ p: 1 }}>
            <StyledImageHeader>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  fontFamily: 'Open Sans',
                  color: '#292D32',
                  letterSpacing: '1px',
                }}
              >
                {t('Hero Image')}
              </Typography>

              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '24px',
                  fontFamily: 'Open Sans',
                  color: '#292D32',
                  display: 'flex',
                  letterSpacing: '1px',
                }}
              >
                16:9{' '}
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '24px',
                    fontFamily: 'Open Sans',
                    color: '#7B858F',
                    letterSpacing: '1px',
                    ml: 1,
                  }}
                >
                  {t('Aspect Ratio')}
                </Typography>
              </Typography>
            </StyledImageHeader>
            <Grid
              item
              sx={{ height: '290px', maxWidth: '517px', background: '#F2F2F2' }}
            >
              <UploadJournalCoverForm
                setFileCover={setFileCover}
                open={open}
                setOpen={setOpen}
                fileCover={fileCover}
                fileData={fileData}
                height='290px'
                publicsite
                aspectRatio={588 / 337}
                // setMessageObject={setMessageObject}
                // setOpenSnackbar={setOpenSnackbar}
              />

              {!fileData?.image && (
                <CoverIcon
                  style={{
                    display: fileCover ? 'none' : 'flex',
                    margin: 'auto',
                    height: '100%',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </StyledGrid>
      ) : (
        <Loader />
      )}
      {openSnackbar ? (
        <StyledSnackbar
          handleClose={handleSnackbar}
          open={openSnackbar}
          messageObject={messageObject}
        />
      ) : null}
    </AppWrapper>
  );
}

export default PublicSite;
