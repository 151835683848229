import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as Logo } from '../../assets/logo-2.svg';
import Logout from '../Logout/Logout';
import { useSelector } from 'react-redux';
import Switcher from 'src/components/LanguageSwitcher/Switcher'

const HeaderContainer = styled('div')({
  boxSizing: 'border-box',
  width: '100%',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  padding: '10px 26px',
  borderBottom: '1px solid #F2F2F2',
  position: 'fixed',
  top: 0,
  left: 0,
  background: '#FFFFFF',
  zIndex: 100,
  justifyContent: 'space-between',
});
const switcherStyle={
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}
function Header() {
  const authenticate = useSelector((state) => state.auth.authenticated);

  return (
    <HeaderContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo />
        <Typography
          variant='subtitle'
          align='left'
          sx={{
            fontSize: '14px',
            color: '#BB8D0B',
            letterSpacing: '1.5px',
            px: 1,
            fontFamily: 'brandon-grotesque',
            fontWeight: '420',
          }}
        >
          ALANDS SJOFART MUSEUM
        </Typography>
      </div>
      <div style={switcherStyle}>
      <Switcher  />
      {authenticate === 'authenticated' ? <Logout /> : null}
      </div>
    </HeaderContainer>
  );
}

export default Header;
