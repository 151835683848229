import { request } from '../../shared/verb';
export const getAllJournals = (pageSize, page, search, categoryId, locale) => {
  console.log(locale)
  let url = `/journal?createdAt:-1&pageSize=${
    pageSize || 10
  }&pageNumber=${page}`;
  if (categoryId !== 'everything') {
    url = url + `&category=${categoryId}`;
  }
  if (search !== null && search !== '') {
    url = url + `&search=${search}`;
  }
  return request(url, 'get',null,null,null, locale).then((res) => {
    return res.data;
  });
};

export const addJournal = (data, locale) =>
  request(`/journal`, data, 'post',null,null,locale).then((res) => {
    // console.log(res?.data);
    return res.data;
  });

export const getSingleJournal = (id, locale) =>
  request(`/journal/${id}?translateTags=1`, 'get', null,null,null, locale).then((res) => {
    return res.data;
  });

export const updateJournal = (id, data,locale) =>
  request(`/journal/${id}`, data, 'put',null,null,locale).then((res) => {
    return res.data;
  });

export const removeJournal = (id) =>
  request(`/journal/${id}`, {}, 'delete').then((res) => {
    return res.data;
  });
