import React, { useEffect } from 'react';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';

function DefaultComponent() {
  const Navigate = useNavigate();
  useEffect(() => {
    Navigate('/files');
    // eslint-disable-next-line
  }, []);

  return <Loader />;
}

export default DefaultComponent;
