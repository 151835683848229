import { Box } from '@mui/material';
import React from 'react';
import StyledButton from '../Button/Button';
import StyledSearchBar from '../SearchBar/SearchBar';
import { ReactComponent as AddIcon } from '../../assets/add.svg';
import CheckPermissions from '../CheckPermissions/CheckPermissions';
import { useTranslation } from "react-i18next";
function UsersTopRightBar({ handleClickOpen, text, ...props }) {
  const {t}=useTranslation();
  return (
    <>
      <StyledSearchBar
        handleChange={props.handleChange}
        placeholder= {t('Search here...')}
      />
      <CheckPermissions requiredPermission={['63d8fc9b276b03dfbf65dbb6']}>
        <Box style={{ width: '200px', height: '36px', marginLeft: '16px' }}>
          <StyledButton iconButton={true} onClick={handleClickOpen}>
            <AddIcon /> {text}
          </StyledButton>
        </Box>
      </CheckPermissions>
    </>
  );
}

export default UsersTopRightBar;
