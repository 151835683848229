import { actions } from '../actions/category';

const initialState = {
  categories: null,
};

export const allCategories = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_CATEGORIES:
      console.log('SET CATEGORY', data);
      return { ...state, categories: data };
    case actions.ADD_CATEGORIES:
      return {
        ...state,
        categories: { data: [...state.categories.data, data] },
      };
    case actions.REMOVE_CATEGORIES:
      return {
        ...state,
        categories: {
          data: state.categories.data.filter((ele) => ele._id !== data),
        },
      };
    case actions.UPDATE_CATEGORIES:
      state.categories.data.forEach((ele) => {
        if (ele._id === data.id) {
          ele.name = data.updatedData.name;
          ele.description = data.updatedData.description;
          ele.image = data.updatedData.image;
        }
      });
      return {
        ...state,
        categories: {
          data: state.categories.data,
        },
      };
    default:
      return state;
  }
};
