import React, { useEffect } from 'react';
import AppWrapper from '../../components/AppWrapper/AppWrapper';

function Dashboard() {
  useEffect(() => {
    console.log('here');
    // navigate('/files');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper>
      <div>Dashboard</div>
    </AppWrapper>
  );
}

export default Dashboard;
