/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import AppWrapper from '../../components/AppWrapper/AppWrapper';
import JournalsList from '../../components/JournalsList/JournalsList';
import Tag from '../../components/Tag/Tag';
import TopBar from '../../components/TopBar/TopBar';
import { getAllJournals } from '../../redux/services/journals';
import { styled } from '@mui/system';
import { Grid, Pagination } from '@mui/material';
import JournalsTopRightBar from '../../components/TopRightBar/Journals';
import NewJournal from './NewJournal/NewJournal';
import Loader from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { validateFileUpload } from 'src/helpers/utils';

import {
  addPage,
  getPages,
  getPresignedURL,
} from 'src/redux/services/category';

import { formatBytes, imgFileNameConvention } from 'src/helpers/utils';
import axios from 'axios';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setPages } from 'src/redux/actions/general';
import { createProgressBar } from 'src/helpers/fileprogress';
import DataNotFound from 'src/components/DataNotFound/DataNotFound';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledPagination = styled(Pagination)({
  '&.MuiPagination-root': {
    display: 'flex',
    padding: '20px 0px',
    justifyContent: 'center',

    borderTop: '1px solid #F2F2F2',
    '& .MuiPaginationItem-root.Mui-selected': {
      background: '#F4E7CF',
      border: '1px solid #BB8D0B !important',
      color: '#BB8D0B',
    },
  },
  '& .MuiButtonBase-root': {
    border: '1px solid #E9D5B2',
    fontFamily: 'Open Sans !important',
    height: '36px',
    width: '36px',
    borderRadius: '50%',
  },
});
const StyledGrid = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

function Journals() {
  const [journals, setJournals] = useState(null);
  const [filter, setFilter] = useState(null);
  const [selectedOption, setSelectedOption] = useState('everything');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [newFileComponent, setNewFileComponent] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [files, setFiles] = useState();
  const [fileSize, setFileSize] = useState();
  const [journalId, setJournalId] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const [pagesUploaded, setPagesUploaded] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [toalFileCount, setTotalFileCount] = useState(0);
  const [uploadNewPagesCount, setUploadNewPagesCount] = useState(0);
  const { pages, locale } = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  let pageCount = Math.ceil(count / 10);

  const getJournalsList = (page, filter, categoryId) => {
    filter = filter !== null ? filter.trim() : filter;
    getAllJournals(null, page, filter, categoryId, locale)
      .then((res) => {
        setJournals(res?.data?.journals);
        setCount(res?.data?.totalCount);
      })
      .catch((err) => console.log(err));
  };

  const handleClick = () => {
    let p = location?.state?.page ? location?.state?.page?.page : page;
    getJournalsList(p, filter, selectedOption);
  };

  const updateNewJournal = (newJournal) => {
    const tempJournals = [...journals];
    newJournal = { ...newJournal, pagesCount: files?.length };

    tempJournals.unshift(newJournal);
    console.log('original journals', journals);
    console.log('temp journals', tempJournals);

    console.log('NEW Journals', newJournal);
    tempJournals.pop();
    setJournals(tempJournals);
    setCount((prev) => prev + 1);
  };
  console.log('FILES', files?.length, toalFileCount);
  const updateExistingJournal = (existingJournal, id) => {
    const tempJournals = [...journals];

    console.log('Update Page Count', files?.length);
    console.log('ugh', existingJournal);

    console.log('ugh', tempJournals);
    console.log(tempJournals.find((ele) => ele._id === id));
    const index = journals
      .map(function (x) {
        return x._id;
      })
      .indexOf(id);
    if (index > -1) {
      existingJournal = {
        ...existingJournal,
        pagesCount: tempJournals[index]?.pagesCount + uploadNewPagesCount,
      };
      console.log(
        'OLD PAGES COUNT',
        tempJournals[index]?.pagesCount,
        'files length',
        files?.length
      );
      tempJournals[index] = existingJournal;
    }
    console.log('UPDATE JOURNAL', tempJournals, id);
    setJournals(tempJournals);
  };
  const removeFromJournalsList = (id) => {
    const tempJournals = [...journals];

    setJournals(tempJournals?.filter((ele) => ele?._id !== id));
    console.log('ID ->', id);
  };

  const uploadImages = async (files, journalId) => {
    console.log('INSIDE upload', files.length);
    if (journalId) {
      setUploadNewPagesCount(files?.length);
      let fileCount = 0;
      setDisableBtn(true);
      try {
        setUploadImageModal(false);
        for (let i = 0; i < files.length; i++) {
          //getPresignedUrl
          const { presignedUrl, imgKey } = await getPresignedURL({
            contentType: files[i]?.type,
            key: `/journal/:${journalId}/page/:${imgFileNameConvention(
              files[i]?.name
            )}`,
          });

          //Upload Image On S3 Bucket
          axios({
            method: 'PUT',
            data: files[i],
            headers: {
              'Content-Type': files[i]?.type,
            },
            url: presignedUrl,
            // eslint-disable-next-line no-loop-func
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let precentage = Math.floor((loaded * 100) / total);
              if (precentage === 100) {
                fileCount++;
              }
              createProgressBar(
                files[i]?.name,
                `${formatBytes(total)}`,
                precentage,
                fileCount,
                files?.length
              );
            },
          }).then((res) => {
            //Add Page Call
            const data = {
              image: imgKey,
              journal: journalId,
              pageNum: i + 1 + (pagesCount || 0),
            };
            addPage(data)
              .then((res) => {})
              .catch((err) => {
                let currentFileprogress = document.getElementById(
                  `${journalId}${files[i]?.name}1`
                );
                let progressContainer = document.getElementById(
                  `${journalId}${files[i]?.name}`
                );
                let fileSize = document.getElementById(
                  `${journalId}${files[i]?.name}2`
                );
                progressContainer.children[1].children[0].children[0].style.color =
                  'red';
                fileSize.children[0].style.color = 'red';
                currentFileprogress.style.background = 'red';
                currentFileprogress.style.border = '1px solid red';
              });
          });
        }
      } catch (err) {
        console.log(err);
        //Upload Fail
        // let fileUpload = document.getElementById("file-count");
        // fileUpload.innerHTML = "Failed";
        // setPagesCount(pagesCount);
      } finally {
        setUploadImageModal(false);
        setDisableBtn(false);
        setPagesUploaded(false);
        setFiles('');
        setPagesCount(pagesCount + files?.length);
      }
    } else {
      setUploadImageModal(false);
      setOpenSnackbar(true);
      setMessageObject({ severity: 'info', text: t('Create Your Journal') });
      setPagesUploaded(true);
      setPagesCount(0);
    }
  };

  const handleFileUpload = async (event) => {
    event?.preventDefault();

    let files;
    let fileSize = 0;
    let fileCount = 0;
    let index = 0;
    if (event?.type === 'change') {
      files = event?.target?.files;
    } else if (event?.type === 'drop') {
      files = event?.dataTransfer?.files;
    }

    // eslint-disable-next-line array-callback-return
    let allowedFiles = Object.entries(files)
      // eslint-disable-next-line array-callback-return
      ?.filter((file) => {
        if (validateFileUpload(['jpg', 'jpeg', 'png', 'gif'], file[1]?.name)) {
          fileCount++;
          return file;
        }
      })
      .reduce((accum, [, v]) => {
        console.log(index, v);
        accum[index++] = v;
        return accum;
      }, {});
    allowedFiles.length = fileCount;

    //Total Size Of Files
    for (let i = 0; i < allowedFiles.length; i++) {
      fileSize += allowedFiles[i]?.size;
    }

    if (fileCount >= 1) {
      setFileSize(formatBytes(fileSize));
      setFiles(allowedFiles);
      setTotalFileCount(toalFileCount + allowedFiles?.length);
    } else {
      setOpenSnackbar(true);
      setMessageObject({ severity: 'info', text: t('File Not Supported') });
    }
  };

  useEffect(() => {
    // console.log('selected option', selectedOption);
    handleClick();
    // eslint-disable-next-line
  }, [locale]);

  useEffect(() => {
    if (newFileComponent) {
      // console.log('selected option', selectedOption);
      handleClick();
    }
    // eslint-disable-next-line
  }, [newFileComponent]);

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearch = (e) => {
    setFilter(e.target.value);
    handleClick();
  };
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    // console.log('selected value', e.target.value);
  };
  const handlePagination = (event, value) => {
    getJournalsList(value, filter, selectedOption);
    navigate(null, { replace: true });
    setPage(() => value);
    // console.log('page count', value);
  };
  const handleSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getJournalPages = async () => {
    try {
      const res = await getPages(journalId, dispatch);
      console.log(res);
      // dispatch(setJournalDetails(res?.pages[0]?.journal));
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (journalId) {
      getJournalPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);

  useEffect(() => {
    if (pages) {
      setPagesCount(pages?.totalCount);
    }
  }, [pages]);

  //Remove Pages On Unmount
  useEffect(() => {
    return () => {
      setPagesCount(0);
      dispatch(setPages([]));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {}, []);

  console.log('STATE', location?.state?.page);
  return (
    <AppWrapper>
      {newFileComponent === false ? (
        <StyledGrid>
          <Grid item>
            <TopBar
              fontSize='20px'
              title={t('FILES')}
              tag={
                <Tag>
                  {count} {t('FILES')}
                </Tag>
              }
              rightSide={
                <JournalsTopRightBar
                  text={t('New File')}
                  handleChange={handleChange}
                  handleSelectChange={handleSelectChange}
                  handleClick={handleClick}
                  handleSearch={handleSearch}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  handleClickOpen={() => navigate('/files/add')}
                />
              }
            />

            {journals ? (
              journals?.length >= 1 ? (
                <JournalsList
                  journals={journals}
                  page={page}
                  setPage={setPage}
                  count={count}
                  setNewFileComponent={setNewFileComponent}
                  setJournalId={setJournalId}
                />
              ) : (
                <DataNotFound data='files' />
              )
            ) : (
              <Loader />
            )}
          </Grid>
          {/* {journals?.length === 0 && filter !== null ? (
            <Message>No results found!</Message>
          ) : (
            ''
          )} */}
          {journals?.length > 0 && (
            <Grid item>
              <StyledPagination
                count={pageCount}
                onChange={handlePagination}
                page={
                  location?.state?.page ? location?.state?.page?.page : page
                }
              />
            </Grid>
          )}
        </StyledGrid>
      ) : (
        <NewJournal
          files={files}
          setFiles={setFiles}
          fileSize={fileSize}
          uploadImageModal={uploadImageModal}
          setUploadImageModal={setUploadImageModal}
          uploadImages={uploadImages}
          pagesUploaded={pagesUploaded}
          handleFileUpload={handleFileUpload}
          setNewFileComponent={setNewFileComponent}
          journalId={journalId}
          disableBtn={disableBtn}
          setJournalId={setJournalId}
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          setMessageObject={setMessageObject}
          handleSnackbar={handleSnackbar}
          messageObject={messageObject}
          pagesCount={pagesCount}
          updateNewJournal={updateNewJournal}
          journals={journals}
          updateExistingJournal={updateExistingJournal}
          removeFromJournalsList={removeFromJournalsList}
          locale={locale}
        />
      )}

      {openSnackbar && (
        <StyledSnackbar
          handleClose={handleSnackbar}
          open={openSnackbar}
          messageObject={messageObject}
        />
      )}
    </AppWrapper>
  );
}

export default Journals;
