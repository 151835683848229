import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import RegisterLayout from '../../components/RegisterLayout/RegisterLayout';

function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    const inviteData = localStorage.getItem('InviteData');
    if (!inviteData) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegisterLayout>
      <RegisterForm
        title='REGISTER'
        subtitle='Welcome to the Åland Maritime Museum admin panel'
      />
    </RegisterLayout>
  );
}

export default Register;
