import * as yup from 'yup';

export const userRegistrationSchema = yup.object().shape({
  fullname: yup.string().trim().required('Full name is required.'),
  password: yup
    .string()
    .trim()
    .min(6, 'Password must be at least 6 characters.')
    .required('This field is required.'),
  confirmPassword: yup
    .string()
    .trim()
    .required('This field is required.')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref('password'), null],
          'Both password need to be the same'
        ),
    }),
});
