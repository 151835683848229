import { request } from '../../shared/verb';

export const translateData = (data,locale) =>{
  return request(`/translate?source_lang=EN&target_lang=SV`, data, 'post',null,null,locale).then(
    (res) => {
      return res.data?.data;
    }
  )};

  export const translateDataSWToEN = (data,locale) =>{
    return request(`/translate?source_lang=SV&target_lang=EN`, data, 'post',null,null,locale).then(
      (res) => {
        return res.data?.data;
      }
    )};
