import React from 'react';
import Snackbar from '@mui/material/Snackbar';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';

function StyledSnackbar({
  handleClose,
  open,
  messageObject,
  horizontal = 'right',
  vertical = 'top',
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      // message={messageObject.text}
      // action={action}
    >
      <Alert
        // onClose={handleClose}
        severity={messageObject.severity}
        variant='filled'
        sx={{ width: '100%', fontFamily: 'Open Sans' }}
      >
        {messageObject.text}
      </Alert>
    </Snackbar>
  );
}

export default StyledSnackbar;
