import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from 'src/redux/actions/general';

const SwitchWrapper = styled('div')({
  width: '96px',
  height: '36px',
  display: 'flex',
  color: '#FFFFFF',
  justifyContent: 'space-around',
  alignItems: 'center',
  background: '#BB8D0B',
  borderRadius: '24px',
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    fontSize: '14px',
    margin: '4px',
    cursor: 'pointer',
    fontWeight: '420',
    fontFamily: 'brandon-grotesque',
    width: '44px',
    height: '28px',
    borderRadius: '24px',
    textAlign: 'center',
    alignSelf: 'center',
  },
  '.selectedDiv': {
    background: '#FFFAF1',
    color: '#BB8D0B',
  },
});

const Switcher = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state?.general);
  const changeLanguage = (lng) => {
    console.log(lng);
    localStorage.setItem('locale', lng);
    i18n.changeLanguage(lng);
    dispatch(setLocale(lng));
  };
  return (
    <SwitchWrapper>
      <div
        className={`${locale === 'en' ? 'selectedDiv' : null}`}
        onClick={() => changeLanguage('en')}
      >
        EN
      </div>
      <div
        className={`${locale === 'sw' ? 'selectedDiv' : null}`}
        onClick={() => changeLanguage('sw')}
      >
        SW
      </div>
    </SwitchWrapper>
  );
};

export default Switcher;
