import { request } from '../../shared/verb';
export const getAllUsers = () =>
  request(`/users`, 'get').then((res) => {
    return res.data;
  });

export const removeUser = (data) =>
  request(`/users/remove`, { ...data }, 'delete').then((res) => {
    return res;
  });

export const updateUser = (data) =>
  request(`/users/me`, { ...data }, 'put').then((res) => {
    return res.data;
  });

export const changePassword = (data) =>
  request(`/users/change-password`, { ...data }, 'post').then((res) => {
    return res;
  });
