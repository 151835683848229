import { Autocomplete, IconButton, InputAdornment } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';
import StyledInput from '../Input/Input';
import { ReactComponent as AddIcon } from '../../assets/add-circle.svg';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';

const CustomAutoComplete = styled(Autocomplete)({
  // background: 'red',
  position: 'relative',
  fontFamily: 'Open Sans !important',

  '& .MuiChip-root': {
    fontFamily: 'Open Sans !important',
    fontSize: '14px',
    color: '#292D32',
    lineHeight: '24px',
    // textTransform: 'capitalize',
    background: '#F6EEE0',
    margin: '5px',
    // paddingTop: '3px',
    // paddingBottom: '3px',
    maxWidth: '80%',
  },
  '&.MuiInputBase-root': {
    display: 'flex',
  },
  '&.css-1kxbtff-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input':
    {
      display: 'flex',
    },
  '& .MuiAutocomplete-tag': {
    background: '#F6EEE0',
  },
  '& .MuiChip-deleteIcon': {
    display: 'none',
  },
  '& .MuiAutocomplete-listbox': {
    padding: '12px',
  },
  '& .MuiTextField-root .MuiInputBase-root': {
    border: (props) =>
      props.errorclass ? '1px solid #B1484E' : '1px solid #D9DEE2',
    paddingRight: '38px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: '14px',
  padding: '6px 12px',
  fontFamily: 'Open Sans !important',
  color: '#7B858F',
  border: '1px solid #D9DEE2',
  '&.MuiButtonBase-root:not(:last-child)': {
    borderBottom: 'none',
  },
  '&.MuiButtonBase-root:hover': {
    background: 'none',
    color: '#BB8D0B',
  },
  '&.MuiButtonBase-root.Mui-selected': {
    background: 'none',
    color: '#BB8D0B',
  },
  '&.MuiButtonBase-root:has(> .MuiList-root)': {
    padding: '0px',
  },
  '&.MuiList-root-MuiMenu-list': {
    padding: '18px',
  },
});

function StyledAutoComplete({
  placeholder,
  handleInputChange,
  name,
  value,
  variant2,
  options,
  errorclass,
  disabled = false,
  handleAddBtnClick,
  page=false,
  ...props
}) {
  const inputRef = useRef();
  return (
    <>
    {page ?
    <CustomAutoComplete
      multiple
      {...props}
      clearOnBlur
      autoCapitalize='none'
      freeSolo
      errorclass={errorclass}
      id='size-small-standard-multi'
      size='small'
      name={name}
      value={value || []}
      disableClearable
      variant2={variant2}
      onChange={handleInputChange}
      disabled={disabled}
      options={options || []}
      getOptionLabel={(option) => option.name || option || option.name_en || option.name_sw}
      renderOption={(props, option) => (
        // <StyledMenuItem
        //   {...props}
        //   key={`${props.id}-${props["data-option-index"]}`}
        // >
        //   {option.name}
        // </StyledMenuItem>
        <StyledMenuItem
          {...props}
          key={`${props.id}-${props['data-option-index']}`}
        >
          {option?.name}
        </StyledMenuItem>
      )}
      defaultValue={[]}
      renderInput={(params) => (
        <StyledInput
          {...params}
          placeholder={placeholder}
          errorclass={errorclass}
          autoheight='true'
          inputRef={inputRef}
          variant3={variant2}
          // secondary='true'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                sx={{ position: 'absolute', right: '5px', top: '20px' }}
              >
                <IconButton
                  onClick={() => {
                    console.log("hello" +inputRef.current.value)
                    if(inputRef.current.value){
                    handleAddBtnClick(inputRef.current.value)
                    }
                    // if (inputRef.current.value.trim() !== '') {
                    //   if (
                    //     !value.find(
                    //       (tag) =>
                    //         tag?.trim() === inputRef?.current?.value.trim() ||
                    //         (typeof tag === 'object' &&
                    //           tag?.name === inputRef?.current?.value.trim())
                    //     )
                    //   ) {
                    //     handleAddBtnClick([...value, inputRef?.current?.value]);
                    //   }
                    // }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    ></CustomAutoComplete> : 
    <CustomAutoComplete
      multiple
      {...props}
      autoCapitalize='none'
      freeSolo
      errorclass={errorclass}
      id='size-small-standard-multi'
      size='small'
      name={name}
      renderTags={(tagValue, getTagProps) => {
        console.log('Render Tags');
        return tagValue?.map((option, index) => {
          return <Chip key={index} label={option?.name || option} />;
        });

      }}
      value={value || []}
      disableClearable
      variant2={variant2}
      onChange={handleInputChange}
      disabled={disabled}
      options={options || []}
      getOptionLabel={(option) => option.name || option || option.name_en || option.name_sw}
      renderOption={(props, option) => (
        // <StyledMenuItem
        //   {...props}
        //   key={`${props.id}-${props["data-option-index"]}`}
        // >
        //   {option.name}
        // </StyledMenuItem>
        <StyledMenuItem
          {...props}
          key={`${props.id}-${props['data-option-index']}`}
        >
          {option?.name}
        </StyledMenuItem>
      )}
      defaultValue={[]}
      renderInput={(params) => (
        <StyledInput
          {...params}
          placeholder={placeholder}
          errorclass={errorclass}
          autoheight='true'
          inputRef={inputRef}
          variant3={variant2}
          // secondary='true'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                sx={{ position: 'absolute', right: '5px', top: '20px' }}
              >
                <IconButton
                  onClick={() => {
                    console.log("hello" +inputRef.current.value)
                    
                    if (inputRef.current.value.trim() !== '') {
                      if (
                        !value.find(
                          (tag) =>
                            tag?.trim() === inputRef?.current?.value.trim() ||
                            (typeof tag === 'object' &&
                              tag?.name === inputRef?.current?.value.trim())
                        )
                      ) {
                        handleAddBtnClick([...value, inputRef?.current?.value]);
                      }
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    ></CustomAutoComplete>
    
    }
    </>
  );
}

export default StyledAutoComplete;
