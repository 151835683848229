import { Button } from '@mui/material';
import { styled } from '@mui/system';
import Gallery from 'src/assets/gallery.png';
import Complete from 'src/assets/complete.png';
import { initializeStore } from 'src/redux/configureStore';

const ProgressWrapper = styled('div')({
  position: 'absolute',
  right: '0',
  bottom: '0',
  display: 'none',
  zIndex: '10',
  padding: '15px',
  flexDirection: 'column',
  background: '#FFFFFF',
  minWidth: '428px',
  maxHeight: '252px',
  '.file-upload': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #F2F2F2',
    div: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      p: {
        fontFamily: 'Open Sans',
        fontWeight: '500',
        fontSize: '14px',
        color: '#BB8D0B',
      },
    },
    '#cancel-files':{
       display: 'none',
    },
    ".cancel-btn": {
      minWidth: "160px",
      minHeight: "36px",
      background: "#fff",
      color: "#BB8D0B",
      border: "1px solid #D9DEE2",
      borderRadius: "0",
    },
  },
});

const ProgressBar = styled("div")({
  display: "flex",
  zIndex: "10",
  flexDirection: "column",
  background: "#FFFFFF",
  minWidth: "428px",
  maxHeight: "252px",
  overflowY: "auto",
  ".file-progress-container": {
    display: "flex",
    flexDirection: "row",
    gap: "9px",
    alignItems: "center",
    border: "1px solid #F2F2F2",
    minWidth: "396px",
    minHeight: "56px",
    padding: "5px",
    ".file-details-container": {
      display: "flex",
      gap: "6px",
      minWidth: "340px",
      marginTop: "2px",
      flexDirection: "column",
      ".file-details": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: "340px",
        '.fileInfo': {
          display: "flex",
          flexDirection: 'column',
          height: '50px',
          fontFamily: "Open Sans",
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "24px",
         '#filename': {
          color: "#292D32",
          height: '20px',
          width: '250px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '#journalName':{
            color: '#7B858F',
            height: '20px',
            width: '250px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
      },
        div: {
          display: "flex",
          gap: "6px",
          alignItems: "center",
          fontFamily: "Open Sans",
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "24px",
          p: {
            color: '#7B858F',
          },
        },
      },
      '.file-progress': {
        width: '340px',
        background: '#E9D5B2',
        border: '1px solid #E9D5B2',
        borderRadius: '5px',
      },
    },
  },
});

export const createProgressBar = (
  fileName,
  fileSize,
  precentage,
  fileCount,
  totalCount
) => {
  console.log(fileName, fileSize, precentage, fileCount,totalCount);

  const store = initializeStore();
  const { journalDetails } = store?.getState().general;
  console.log(journalDetails);
  let cancelFiles=document.getElementById('cancel-files');
  let fileUpload = document.getElementById("file-count");
  cancelFiles.style.display="none";

  //All files Uploaded
  if (fileCount === totalCount) {
    // let minMaxDiv = document.querySelector(".file-upload");
    // let progressWrapper = document.querySelector("#progress-wrapper");
    cancelFiles.style.display="flex";
    fileUpload.innerHTML = "";
    // setTimeout(() => {
    //   minMaxDiv.style.display = "none";
    //   progressWrapper.style.display = "none";
    // }, [6000]);
  } else {
    fileUpload.innerHTML = `${fileCount} of ${totalCount} uploading...`;
  }

  let fileId = document.getElementById(`${journalDetails?._id}${fileName}`);
  
  if (fileId?.getAttribute("id")) {
    //Increasing Width of Progress Bar
    let currentFileprogress = document.getElementById(
      `${journalDetails?._id}${fileName}1`
    );
    let completeDiv = document.getElementById(
      `${journalDetails?._id}${fileName}2`
    );
    currentFileprogress.style.width = `${precentage}%`;

    let imgComplete=document?.getElementById(`${journalDetails?._id}${fileName}img`);
    //File Progress Completed
    if (precentage === 100 && !imgComplete) {
      let completeImg = document.createElement("img");
      completeImg.setAttribute('id',`${journalDetails?._id}${fileName}img`)
      completeImg.src = Complete;
      completeImg.style.width = '13px';
      completeImg.style.height = '13px';
      completeDiv.appendChild(completeImg);

      //remove that element
      // setTimeout(() => {
      //   fileId.remove();
      // }, 5000);
    }
  } else {
    let progressWrapper = document.querySelector('#progress-wrapper');
    progressWrapper.style.display = 'flex';
    let progressBar = document.querySelector('#progress-bar');
    progressBar.scrollTop = progressBar.scrollHeight;

    let minMaxDiv = document.querySelector('.file-upload');
    minMaxDiv.style.display = 'flex';

    let progressContainer = document.createElement("div");
    progressContainer.classList.add("file-progress-container");
    progressContainer.setAttribute("id", `${journalDetails?._id}${fileName}`);

    let gallery = document.createElement('img');
    gallery.src = Gallery;
    gallery.style.width = '24px';
    gallery.style.height = '24px';

    let fileDetailsContainer = document.createElement('div');
    fileDetailsContainer.classList.add('file-details-container');

    let fileDetails = document.createElement("div");
    fileDetails.classList.add("file-details");
    
    
    let para1 = document.createElement("p");
    let para2 = document.createElement("p");
    let para3 = document.createElement("p");
    let div = document.createElement("div");
    div.setAttribute("id", `${journalDetails?._id}${fileName}2`);

    para1.innerHTML = fileName;
    para2.innerHTML = fileSize;

    div.appendChild(para2);
    para1.innerHTML = fileName;
    para1.setAttribute('id','filename');
    para2.innerHTML = fileSize;
    para3.innerHTML= journalDetails?.title;
    para3.setAttribute('id','journalName')
    
    let div2 = document.createElement("div");
    div2.classList.add("fileInfo");
    div2.appendChild(para1);
    div2.appendChild(para3);

    


    let imgComplete=document?.getElementById(`${journalDetails?._id}${fileName}img`);
    //File Progress Completed
    if (precentage === 100 && !imgComplete) {
      let completeImg = document.createElement("img");
      completeImg.setAttribute('id',`${journalDetails?._id}${fileName}img`)
      completeImg.src = Complete;
      completeImg.style.width = '13px';
      completeImg.style.height = '13px';
      div.appendChild(completeImg);
      //remove that element
      // setTimeout(() => {
      //   progressContainer.remove();
      // }, 5000);
    }

    fileDetails.appendChild(div2);
    fileDetails.appendChild(div);

    let fileProgress = document.createElement('div');
    fileProgress.classList.add('file-progress');

    let progress = document.createElement("div");
    progress.setAttribute("id", `${journalDetails?._id}${fileName}1`);
    progress.style.background = "#BB8D0B";
    progress.style.border = "1px solid #BB8D0B";
    progress.style.borderRadius = "5px";
    progress.style.width = `${precentage}%`;

    fileProgress.appendChild(progress);
    fileDetailsContainer.appendChild(fileDetails);
    fileDetailsContainer.appendChild(fileProgress);
    progressContainer.appendChild(gallery);
    progressContainer.appendChild(fileDetailsContainer);
    progressBar.appendChild(progressContainer);
  }
};

export const minimizeProgress = (setMinimize, minimize) => {
  let progressWrapper = document.querySelector('#progress-bar');
  if (!minimize) {
    progressWrapper.style.display = 'none';
  } else {
    progressWrapper.style.display = 'flex';
  }
  setMinimize(!minimize);
};

export const removeFileProgress=()=>{
   let progressBar=document.getElementById('progress-bar');
   let progressWrapper = document.querySelector("#progress-wrapper");
   let minMaxDiv = document.querySelector(".file-upload");
   while (progressBar.hasChildNodes()) {
    progressBar.removeChild(progressBar.firstChild);
  }
   minMaxDiv.style.display = "none";
   progressWrapper.style.display="none";  
}

export const progressBarHTML = (setMinimize, minimize) => {
  return (
    <ProgressWrapper id='progress-wrapper'>
      <ProgressBar id='progress-bar'>
        {/* <div className="file-progress-container">
      <img src={Gallery} width="24px" height="24px" />
      <div className="file-details-container">
        <div className="file-details">
          <div className="fileInfo">
          <p id="filename">filename</p>
          <p id="journalName">Journal</p>
          </div>
          <div>
            <p id="filesize">5 MB</p>
            <img src={Complete} width="13px" height="13px" />
          </div>
        </div>
        <div className="file-progress"></div>
      </div>
    </div> */}
      </ProgressBar>
      <div className='file-upload'>
        <div>
          <img src={Gallery} width='24px' alt='' height='24px' />
          <p id='file-count'></p>
        </div>
        <Button
          id="cancel-files"
          className="cancel-btn"
          onClick={removeFileProgress}
        >
          CANCEL
        </Button>
        <Button
          className="cancel-btn"
          onClick={() => minimizeProgress(setMinimize, minimize)}
        >
          {minimize ? 'Maximize' : 'Minimize'}
        </Button>
      </div>
    </ProgressWrapper>
  );
};
