import { Typography, Grid, Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import { useFormik } from 'formik';
import { addCategorySchema } from './validation';
import { styled } from '@mui/system';
import ImagePreview from '../ImagePreview/ImagePreview';
import { updateCategory, getPresignedURL } from '../../redux/services/category';
import { updateCategory as updateCategoryInRedux } from '../../redux/actions/category';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import apiConfig from 'src/config/api.service';
import StyledTextArea from '../Input/TextArea';
import { useTranslation } from "react-i18next";

const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  // marginTop: '5px',
  lineHeight: '24px',
  marginBottom: '12px',
});

// Upload Image function
const uploadImage = async (
  values,
  id,
  setLoading,
  handleClose,
  category,
  description,
  locale
) => {
  // get presign url
  // console.log('valueses', values);
  let data;
  if (typeof values.fileName !== 'string') {
    // eslint-disable-next-line no-unused-vars
    const { presignedUrl, imgKey } = await getPresignedURL({
      contentType: values.fileName.type,
      key: values.fileName.name.split(' ').join('-'),
    });
    // console.log('res', presignedUrl, imgUrl_3200);
    // upload image
    // eslint-disable-next-line no-unused-vars
    const res = await axios({
      method: 'PUT',
      data: values.fileName,
      headers: {
        'Content-Type': values.fileName.type,
      },
      url: presignedUrl,
    });
    if (locale === 'en') {
      data = {
        image: imgKey,
        name_en: values.category.trim() || '',
        name_sw: category.trim() || '',
        description_en: values.description.trim() || '',
        description_sw: description.trim() || '',
      };
    } else {
      data = {
        image: imgKey,
        name_en: category || '',
        name_sw: values.category || '',
        description_en: description || '',
        description_sw: values.description || '',
      };
    }
  } else {
    if (locale === 'en') {
      data = {
        image: values?.fileName,
        name_en: values.category || '',
        name_sw: category || '',
        description_en: values.description || '',
        description_sw: description || '',
      };
    } else {
      data = {
        image: values?.fileName,
        name_en: category|| '',
        name_sw: values?.category || '',
        description_en: description || '',
        description_sw: values?.description || '',
      };
    }
  }
  // update category
  const responseData = await updateCategory(data, id, locale);
  setLoading(false);
  // handleClose(true);

  return responseData?.data?.data;
};

function UpdateCategoryForm({
  category,
  handleClose,
  setMessageObject,
  setOpenSnackbar,
  categoryNameSW,
  categoryDesSW,
  setCategoryNameSW,
  setCategoryDesSW,
  categoryNameEN,
  categoryDesEN,
  setCategoryNameEN,
  setCategoryDesEN,
  translate = false,
  locale,
}) {
  const {t}=useTranslation();
  const dispatch = useDispatch();
  const initialValues = {
    category: locale === "en" ? category?.name_en || category.name : category?.name_sw || category.name,
    description:locale === "en" ? category.description_en || category.description : category.description_sw || category.description,
    fileName: category.image,
  };
  console.log('Category', category, 'Initial Values', initialValues);
  const [file, setFile] = useState(apiConfig.imageUrlPrefix + category.image);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const [showTypeError, setShowTypeError] = useState(false);

  const toggleDisplay = (file) => {
    return file ? 'none' : '';
  };

  const handleToggle = () => {
    // setFile(null);
    inputRef.current.click();
  };
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addCategorySchema,
      onSubmit: (values) => {
        setLoading(true);
        uploadImage(
          values,
          category._id,
          setLoading,
          handleClose,
          categoryNameSW,
          categoryDesSW,
          locale
        )
          .then((data) => {
            let updatedData = {
              name: data?.name_en,
              description: data?.description_en,
              image: data?.image,
            };
            console.log('UPDATE CATEGORY', updatedData);
            // console.log('Response from update category -->', data);
            // console.log('updated data', updatedData);
            setMessageObject({
              text: t('Category updated'),
              severity: 'success',
            });
            setOpenSnackbar(true);
            dispatch(updateCategoryInRedux(updatedData, category._id));
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setMessageObject({
              text: err?.response?.data?.message
                ? err?.response?.data?.message
                : err?.response?.data?.data,
              severity: 'error',
            });
            setOpenSnackbar(true);
            // handleClose(true);
            setLoading(false);
          });
      },
      validate: (values) => {
        setCategoryNameEN(values?.category);
        setCategoryDesEN(values?.description);
      },
    });

  useEffect(() => {
    setCategoryNameEN(values?.category);
    setCategoryDesEN(values?.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} sx={{ p: 2 }}>
        <form onSubmit={handleSubmit} noValidate={true}>
          <Box sx={{ mb: 2 }}>
            <StyledInput
              type='file'
              style={{ display: toggleDisplay(file), marginBottom: '8px' }}
              ref={inputRef}
              multiple={false}
              name='fileName'
              onChange={(e) => {
                if (
                  !['image/png', 'image/jpg', 'image/jpeg'].includes(
                    e?.target?.files[0]?.type
                  ) ||
                  e?.target?.files[0]?.size / 1000000 > 10
                ) {
                  setFile('');
                  setShowTypeError(true);
                  // setTimeout(() => {
                  //   setShowTypeError(false);
                  // }, 3000);
                  setFieldValue('fileName', null);
                  return;
                }
                setShowTypeError(false);
                handleChange(e);
                setFieldValue('fileName', e.target.files[0]);
                setFile(URL.createObjectURL(e.target.files[0]));
              }}
              errorclass={
                errors.fileName && touched.fileName ? 'errorMessage' : null
              }
            />
            <ImagePreview
              style={{ display: toggleDisplay(!file) }}
              id='image-preview'
              // src={apiConfig.imageUrlPrefix + file}
              src={file}
              alt={values.fileName?.name}
              onClick={handleToggle}
            />

            {errors.fileName && touched.fileName && showTypeError ? (
              <ErrorMessage>File type/size not supported.</ErrorMessage>
            ) : errors.fileName && touched.fileName ? (
              <ErrorMessage>{errors.fileName}</ErrorMessage>
            ) : showTypeError ? (
              <ErrorMessage>File type/size not supported.</ErrorMessage>
            ) : null}
            <Typography
              variant='subtitle1'
              align='left'
              fontFamily='Open Sans !important'
              sx={{ fontSize: '12px', mb: 1 }}
            >
              {t(`Category Name`)}
            </Typography>
            <>
              <StyledInput
                placeholder='Enter Category Name'
                type='text'
                fontFamily='Open Sans !important'
                name='category'
                variant3='true'
                secondary='true'
                value={values.category}
                onChange={handleChange}
                errorclass={
                  errors.category && touched.category ? 'errorMessage' : null
                }
              />
              {translate ? (
                <StyledInput
                  placeholder='Enter Category Name'
                  type='text'
                  fontFamily='Open Sans !important'
                  name='category'
                  variant3='true'
                  secondary='true'
                  value={categoryNameSW}
                  onChange={(e) => {
                    setCategoryNameSW(e.target.value);
                  }}
                  errorclass={
                    errors.category && touched.category ? 'errorMessage' : null
                  }
                />
              ) : null}
            </>

            {errors.category && touched.category ? (
              <ErrorMessage>{errors.category}</ErrorMessage>
            ) : null}
            <Typography
              variant='subtitle1'
              align='left'
              fontFamily='Open Sans'
              sx={{ fontSize: '12px', my: 1 }}
            >
              {t(`Category Description`)}
            </Typography>
            <>
              <StyledTextArea
                placeholder='Enter Category Description'
                type='text'
                name='description'
                value={values.description}
                variant3='true'
                secondary='true'
                onChange={handleChange}
                errorclass={
                  errors.description && touched.description
                    ? 'errorMessage'
                    : null
                }
              />
              {translate ? (
                <StyledTextArea
                  placeholder='Enter Category Description'
                  type='text'
                  name='description'
                  style={{ marginTop: '-8px' }}
                  value={categoryDesSW}
                  variant3='true'
                  secondary='true'
                  onChange={(e) => {
                    setCategoryDesSW(e.target.value);
                  }}
                  errorclass={
                    errors.description && touched.description
                      ? 'errorMessage'
                      : null
                  }
                />
              ) : null}
            </>
            {errors.description && touched.description ? (
              <ErrorMessage>{errors.description}</ErrorMessage>
            ) : null}
          </Box>
          <StyledButton btnvariant='true' type='submit' loading={loading}>
            {t(`Update Category`)}
          </StyledButton>
        </form>
      </Grid>
    </Grid>
  );
}

export default UpdateCategoryForm;
