import * as yup from 'yup';

export const addNewFileSchema = yup.object().shape({
  fileTitle: yup
    .string()
    .trim()
    .required('This field is required.')
    .max(120, 'Must be less than 120 characters'),
  category: yup.string().required('This field is required.'),
  details: yup
    .string()
    .trim()
    .required('This field is required.')
    .max(5000, 'Must be less than 5000 characters'),
  contributor: yup.string().trim(),
  // year: yup.string(),
  year: yup.string().trim(),
  description: yup
    .string()
    .trim()
    .max(1000, 'Must be less than 1000 characters'),
  tag: yup.array(),
  fileTitle_sw: yup
    .string()
    .trim()
    .max(120, 'Must be less than 120 characters')
    .when('showSW', {
      is: true,
      then: yup.string().required('This field is required.'),
    }),
  // category_sw: yup.string().when('showSW', {
  //   is: true,
  //   then: yup.string().required('This field is required.'),
  // }),
  category_sw: yup.string(),
  details_sw: yup
    .string()
    .trim()
    .when('showSW', {
      is: true,
      then: yup.string().required('This field is required.'),
    })
    .max(5000, 'Must be less than 5000 characters'),
  contributor_sw: yup.string().trim(),
  year_sw: yup.string().trim(),
  description_sw: yup
    .string()
    .trim()
    .max(1000, 'Must be less than 1000 characters'),
  tag_sw: yup.array(),
});

export const uploadJournalCover = yup.object().shape({
  fileName: yup.mixed().required('You need to provide a file'),
  // .test(2500, 'File Size is too large', (value) => value.size <= 2500),
  // .test(
  //   'type',
  //   'Only the following formats are accepted: .jpeg, .jpg, .png',
  //   (value) => {
  //     return (
  //       value &&
  //       (value[0].type === 'image/jpeg' ||
  //         value[0].type === 'image/gif' ||
  //         value[0].type === 'image/png' ||
  //         value[0].type === 'application/jpg')
  //     );
  //   }
  // ),
});
