/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import StyledAvatar from '../Avatar/Avatar';
import { ReactComponent as InfoIcon } from '../../assets/info-circle.svg';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { uploadProfilePictureSchema } from './validation';
import StyledInput from '../Input/Input';
import { getPresignedURL } from '../../redux/services/category';
import axios from 'axios';
import { updateUser } from '../../redux/services/users';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfileImage, updateUserState } from '../../redux/actions/auth';
import apiConfig from '../../config/api.service';
import { useTranslation } from 'react-i18next';

const InfoContainer = styled('div')({
  color: '#7B858F',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  display: 'flex',
  marginBottom: '12px',
});
const IconWrapper = styled('div')({
  marginRight: '2px',
  display: 'flex',
});

const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  // marginTop: '5px',
  lineHeight: '24px',
  marginBottom: '12px',
});

function ProfilePictureUpload({ setMessageObject, setOpenSnackbar }) {
  const {t}=useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // const [file, setFile] = useState(user.image);
  const [loading, setLoading] = useState(false);
  // const [showTypeError, setShowTypeError] = useState(false);
  // console.log('image upload comp  -', user.image);
  const initialValues = {
    fileName: user?.image,
  };

  // Upload Image function
  const uploadImage = async (values) => {
    console.log('Inside Image upload -', values);
    // get presign url
    const { presignedUrl, imgKey } = await getPresignedURL({
      contentType: values.fileName?.type,
      key: values.fileName?.name
        .split(' ')
        .join('-')
        // eslint-disable-next-line
        .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ''),
    });

    // upload image
    const res = await axios({
      method: 'PUT',
      data: values.fileName,
      headers: {
        'Content-Type': values.fileName.type,
      },
      url: presignedUrl,
    });
    // console.log('AXIOS', res);
    let data = {
      image: imgKey,
    };
    //update user
    updateUser(data)
      .then((res) => {
        dispatch(updateUserState(res?.data?.user));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    // submitForm,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: uploadProfilePictureSchema,
    onSubmit: (values) => {
      setLoading(true);
      console.log(values);
      uploadImage(values);
    },
  });

  // const submitUpload = (value) => {
  //   console.log('hehe', handleSubmit);
  // };

  return (
    <form noValidate={true} style={{ marginBottom: '24px' }}>
      <Typography
        variant='subtitle1'
        align='left'
        sx={{
          fontSize: '12px',
          mb: 1,
          fontFamily: 'Open Sans',
          color: '#292D32',
          fontWeight: '600',
        }}
      >
        Avatar
      </Typography>

      <Box>
        <StyledAvatar
          width={80}
          height={80}
          loading={loading}
          marginBottom={'12px'}
          src={user?.image ? apiConfig.imageUrlPrefix + user?.image : null}
        >
          {/* {user?.name ? user?.name.charAt(0).toUpperCase() : ''} */}
        </StyledAvatar>
        {/* {showTypeError ? (
          <ErrorMessage>File type/size not supported.</ErrorMessage>
        ) : null} */}
        <InfoContainer>
          <IconWrapper>
            <InfoIcon />
          </IconWrapper>
          {t('Ideal dimensions: 100x100 pixels. (Max size - 5mb )')}
        </InfoContainer>
        <StyledInput
          placeholder=''
          loading={loading}
          profileUpload={true}
          type='file'
          name='fileName'
          values={values.name}
          onChange={async (e) => {
            if (e.target.files[0]) {
              if (
                !['image/png', 'image/jpg', 'image/jpeg'].includes(
                  e?.target?.files[0]?.type
                ) ||
                e?.target?.files[0]?.size / 1000000 > 5
              ) {
                console.log(values);
                setMessageObject({
                  text: 'File type/size not supported.',
                  severity: 'error',
                });
                setLoading(false);
                setOpenSnackbar(true);
                // setShowTypeError(true);
                // setTimeout(() => {
                //   setShowTypeError(false);
                // }, 3000);
                return;
              }
              // setShowTypeError(false);
              // handleChange(e);
              await setFieldValue('fileName', e.target.files[0]);

              console.log('fileName', e.target.files[0]);
              try {
                handleSubmit();
              } catch (err) {
                console.log(err);
              }
            } else {
              console.log('no file selected');
            }
          }}
          //   // submitForm();
          // }}
          // onChange={(val) => handleSubmit(val)}
          errorclass={
            errors.category && touched.category ? 'errorMessage' : null
          }
        />
        {/* <button type='submit'>submit</button> */}
      </Box>
    </form>
  );
}

export default ProfilePictureUpload;
