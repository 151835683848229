import { Typography, Grid, Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import { useFormik } from 'formik';
import { addCategorySchema } from './validation';
import styled from 'styled-components';
import ImagePreview from '../ImagePreview/ImagePreview';
import { addCategory, getPresignedURL } from '../../redux/services/category';
import axios from 'axios';
import { addCategory as addCategoryInRedux } from '../../redux/actions/category';
import { useDispatch } from 'react-redux';
import StyledTextArea from '../Input/TextArea';
import { useTranslation } from 'react-i18next';

const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  // marginTop: '5px',
  lineHeight: '24px',
  marginBottom: '12px',
});

const initialValues = {
  category: '',
  description: '',
  fileName: null,
};

// Upload Image function
const uploadImage = async (
  values,
  handleClose,
  setLoading,
  category,
  description,
  locale
) => {
  // get presign url
  const { presignedUrl, imgKey } = await getPresignedURL({
    contentType: values.fileName.type,
    key: values.fileName.name
      .split(' ')
      .join('-')
      // eslint-disable-next-line
      .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ''),
  });
  // upload image
  // eslint-disable-next-line no-unused-vars
  const res = await axios({
    method: 'PUT',
    data: values.fileName,
    headers: {
      'Content-Type': values.fileName.type,
    },
    url: presignedUrl,
  });
  let data;
  if (locale === 'en') {
    data = {
      image: imgKey,
      name_en: values.category || '',
      name_sw: category || '',
      description_en: values.description || '',
      description_sw: description || '',
    };
  } else {
    data = {
      image: imgKey,
      name_en: category || '',
      name_sw: values.category || '',
      description_en: description || '',
      description_sw: values.description || '',
    };
  }
  // add category
  const categoryRes = await addCategory(data, locale);

  // console.log('Category API response', categoryRes);

  handleClose(false);
  setLoading(false);

  return categoryRes;
};

function AddCategoryForm({
  handleClose,
  setMessageObject,
  setOpenSnackbar,
  categoryNameSW,
  categoryDesSW,
  setCategoryNameSW,
  setCategoryDesSW,
  categoryNameEN,
  categoryDesEN,
  setCategoryNameEN,
  setCategoryDesEN,
  translate = false,
  locale,
}) {
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTypeError, setShowTypeError] = useState(false);
  const {t}=useTranslation();
  const inputRef = useRef();

  const toggleDisplay = (file) => {
    return file ? 'none' : '';
  };
  const dispatch = useDispatch();
  const handleToggle = () => {
    // setFile('');
    inputRef.current.click();
  };
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addCategorySchema,
      onSubmit: (values) => {
        setLoading(true);
        console.log('Values', values);

        uploadImage(
          values,
          handleClose,
          setLoading,
          categoryNameSW,
          categoryDesSW,
          locale
        )
          .then((data) => {
            console.log('Response', data?.data?.data);

            data.data.data.name = data?.data?.data?.name_en;
            data.data.data.description = data?.data?.data?.description_en;
            dispatch(addCategoryInRedux(data?.data?.data));
            setMessageObject({
              text: t('Category added successfully'),
              severity: 'success',
            });
            setOpenSnackbar(true);
          })
          .catch((err) => {
            // console.log('Category', err?.response?.data?.message);
            setMessageObject({
              text: err?.response?.data?.message
                ? err?.response?.data?.message
                : err?.response?.data?.data,
              severity: 'error',
            });
            setOpenSnackbar(true);
            handleClose(false);
            setLoading(false);
          });
      },
      validate: (values) => {
        setCategoryNameEN(values?.category);
        setCategoryDesEN(values?.description);
      },
    });

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} sx={{ p: 2 }}>
        <form onSubmit={handleSubmit} noValidate={true}>
          <Box sx={{ mb: 2 }}>
            <StyledInput
              type='file'
              style={{
                display: toggleDisplay(file),
                marginBottom: '8px',
              }}
              ref={inputRef}
              multiple={false}
              name='fileName'
              onChange={(e) => {
                console.log(e?.target?.files[0]?.size / 1000000);
                if (
                  !['image/png', 'image/jpg', 'image/jpeg'].includes(
                    e?.target?.files[0]?.type
                  ) ||
                  e?.target?.files[0]?.size / 1000000 > 10
                ) {
                  setFile('');
                  setShowTypeError(true);
                  // setTimeout(() => {
                  //   setShowTypeError(false);
                  // }, 3000);
                  setFieldValue('fileName', null);
                  return;
                }

                setShowTypeError(false);
                handleChange(e);
                setFieldValue('fileName', e.target.files[0]);
                setFile(URL.createObjectURL(e.target.files[0]));
              }}
              errorclass={
                errors.fileName && touched.fileName ? 'errorMessage' : null
              }
            />
            <ImagePreview
              style={{ display: toggleDisplay(!file) }}
              id='image-preview'
              src={file}
              alt={values.fileName?.name}
              onClick={handleToggle}
            />
            {errors.fileName && touched.fileName && showTypeError ? (
              <ErrorMessage>File type/size not supported.</ErrorMessage>
            ) : errors.fileName && touched.fileName ? (
              <ErrorMessage>{errors.fileName}</ErrorMessage>
            ) : showTypeError ? (
              <ErrorMessage>File type/size not supported.</ErrorMessage>
            ) : null}
            <Typography
              variant='subtitle1'
              align='left'
              fontFamily='Open Sans'
              sx={{ fontSize: '12px', my: 1 }}
            >
              {t(`Category Name`)}<sup style={{ color: '#B1484E' }}>*</sup>
            </Typography>
            <>
              <StyledInput
                placeholder={t('Enter Category Name')}
                type='text'
                name='category'
                value={values.name}
                variant3='true'
                secondary='true'
                onChange={handleChange}
                errorclass={
                  errors.category && touched.category ? 'errorMessage' : null
                }
              />
              {translate ? (
                <StyledInput
                  placeholder='Enter Category Name'
                  type='text'
                  name='category'
                  value={categoryNameSW}
                  variant3='true'
                  secondary='true'
                  onChange={(e) => {
                    setCategoryNameSW(e.target.value);
                  }}
                  errorclass={
                    errors.category && touched.category ? 'errorMessage' : null
                  }
                />
              ) : null}
            </>

            {errors.category && touched.category ? (
              <ErrorMessage>{t(errors.category)}</ErrorMessage>
            ) : null}

            <Typography
              variant='subtitle1'
              align='left'
              fontFamily='Open Sans'
              sx={{ fontSize: '12px', my: 1 }}
            >
              {t(`Category Description`)}
            </Typography>
            <>
              <StyledTextArea
                placeholder={t('Enter Category Description')}
                type='text'
                name='description'
                value={values.name}
                variant3='true'
                secondary='true'
                onChange={handleChange}
                errorclass={
                  errors.description && touched.description
                    ? 'errorMessage'
                    : null
                }
              />
              {translate ? (
                <StyledTextArea
                  placeholder='Enter Category Description'
                  type='text'
                  name='description'
                  value={categoryDesSW}
                  style={{ marginTop: '-7px' }}
                  variant3='true'
                  secondary='true'
                  onChange={(e) => {
                    setCategoryDesSW(e?.target?.value);
                  }}
                  errorclass={
                    errors.description && touched.description
                      ? 'errorMessage'
                      : null
                  }
                />
              ) : null}
            </>
            {errors.description && touched.description ? (
              <ErrorMessage>{t(errors.description)}</ErrorMessage>
            ) : null}
          </Box>
          <StyledButton btnvariant='true' type='submit' loading={loading}>
            {t(`Add Category`)}
          </StyledButton>
        </form>
      </Grid>
    </Grid>
  );
}

export default AddCategoryForm;
