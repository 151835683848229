import { actions } from '../actions/permissions';

const initialState = {
  permissions: null,
};

export const allPermissions = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_PERMISSIONS:
      return { ...state, permissions: data };
    default:
      return state;
  }
};
