import { Grid } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import LoginCover from '../../assets/login-cover.jpg';
import { ReactComponent as Logo } from '../../assets/logo-1.svg';
import Switcher from '../LanguageSwitcher/Switcher';

const ImageContainer = styled('div')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  '& img': {
    objectFit: 'fill',
    width: '100%',
    height: '100vh',
  },
});
const FormContainer = styled('div')({
  width: '400px',
});
const HeaderContainer = styled('div')({
  width: '400px',
  display: 'flex',
  // height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  transform: 'translateX(25%)',
  '@media (max-width: 1024px)': {
    transform: 'translateX(15%)',
  },
});

function AuthLayout({ children }) {
  return (
    <div style={{position:"relative"}}>
    <Grid container style={{ height: '100vh' }}>
      <Grid
        item
        justifyContent='center'
        lg={5}
        md={6}
        sm={12}
        xs={12}
        sx={{
          py: 2,
          background: '#FFFAF1',
          position: 'relative',
          height: '100%',
        }}
      >

        <HeaderContainer>
          <Logo />
        </HeaderContainer>
        <Grid
          item
          container
          justifyContent='center'
          alignContent='center'
          flexDirection='column'
          alignItems='flex-start'
          sx={{ width: '400px', m: 'auto', height: '100%' }}
        >
          <FormContainer>{children}</FormContainer>
        </Grid>
      </Grid>
      <Grid
        item
        lg={7}
        md={6}
        sx={{
          height: '100%',
          display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' },
        }}
      >
        <ImageContainer>
          <img src={LoginCover} alt='login cover' />
        </ImageContainer>
      </Grid>
    </Grid>
    <div style={{position: 'absolute', left: '90%', bottom: '93%'}}>
    <Switcher />
    </div>
    </div>
  );
}

export default AuthLayout;
