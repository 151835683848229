import React, { useState, useRef, useEffect } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';

import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from './debounceEffect';
import StyledButton from '../Button/Button';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 1000,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropperV2({
  file,
  uploadImage,
  loading,
  handleCancel,
  imageUrl,
  setImageUrl,
  aspectRatio,
}) {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale] = useState(1);
  const [rotate] = useState(0);
  const [aspect] = useState(aspectRatio ? aspectRatio : 286 / 198);
  // const [imageUrl, setImageUrl] = useState(undefined);

  useEffect(() => {
    // console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );
      reader.readAsDataURL(file);
    }
  }, [file]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  const getImageUrl = () => {
    var url = previewCanvasRef.current.toDataURL('image/jpeg');
    // console.log(url);
    const blobImg = b64toBlob(url);
    // console.log(blobImg);
    setImageUrl(blobImg);
    uploadImage(blobImg);
  };

  const handleCancelModal = () => {
    setImageUrl(null);
    handleCancel();
    // setFile(null);
  };

  return (
    <>
      {!!imgSrc && (
        <ReactCrop
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
          }}
          crop={crop}
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
          }}
          onComplete={(c) => {
            // console.log(c);
            setCompletedCrop(c);
          }}
          aspect={aspect}
        >
          <div style={{ width: '100%', height: '400px' }}>
            <img
              ref={imgRef}
              alt='Crop me'
              src={imgSrc}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                height: '100%',
                width: '100%',
              }}
              onLoad={onImageLoad}
            />
          </div>
        </ReactCrop>
      )}
      <div>
        {!!completedCrop && (
          <canvas
            style={{
              display: 'none',
            }}
            ref={previewCanvasRef}
            // style={{
            //   border: '1px solid black',
            //   objectFit: 'contain',
            //   width: 500,
            //   height: 600,
            // }}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '16px',
        }}
      >
        <StyledButton
          secondary='true'
          style={{ marginRight: '12px' }}
          onClick={handleCancelModal}
        >
          Cancel
        </StyledButton>
        <StyledButton
          btnvariant='true'
          loading={loading}
          disable={!file}
          onClick={() =>
            file?.name && crop ? getImageUrl(imageUrl) : uploadImage()
          }
        >
          Save
        </StyledButton>
      </div>
    </>
  );
}
