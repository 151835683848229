import React from 'react';
import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { ReactComponent as TranslateIcon } from '../../assets/translate-icon.svg';
import Trash from 'src/assets/trash.png';
import StyledButton from '../Button/Button';
import TranslateButton from '../Button/TranslateBtn';
import CheckPermissions from '../CheckPermissions/CheckPermissions';
import { useTranslation } from 'react-i18next';
const TopBarContainer = styled('div')({
  width: '100%',
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  padding: '16px',

  boxSizing: 'border-box',
  borderBottom: '1px solid #F2F2F2',
});
const ImageWrapper = styled('div')({
  width: '34px',
  height: '34px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #D9DEE2',
  marginRight: '12px',
  cursor: 'pointer',
});
const StyledLink = styled(Link)({
  color: '#BB8D0B',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});
const LeftSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minWidth: '400px',
});
const RightSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  // width: '103px',
  justifyContent: 'space-between',
});
function TopBar({
  disabledButton,
  setAllowSwFields,
  handleTranslateData,
  handleAddJournal,
  loading,
  setLoading,
  journalId,
  setNewFileComponent,
  translateBtnLoading,
  setTranslateBtnLoading,
  setRemoveJournalComponent,
  setJournalId,
  handleNavigation,
  tagsSW,
  tagsEN,
  swFields,
}) {
  const { t } = useTranslation();
  return (
    <TopBarContainer>
      <LeftSide>
        <StyledLink
          // onClick={() => {
          //   // setNewFileComponent(false);

          //   // setJournalId(null);
          //   // console.log('ID -->', journalId);
          // }}
          onClick={(e) => {
            e.preventDefault();
            handleNavigation();
          }}
        >
          <ArrowRight />
          <Typography
            variant='subtitle'
            align='left'
            sx={{
              fontSize: '14px',
              letterSpacing: '1.5px',
              fontWeight: '420',
              fontFamily: 'brandon-grotesque',
              ml: 1,
            }}
          >
            {`${t('BACK')} /`}
          </Typography>
        </StyledLink>
        <Typography
          variant='subtitle'
          align='left'
          sx={{
            fontSize: '14px',
            letterSpacing: '1.5px',
            color: '#7B858F',
            fontFamily: 'brandon-grotesque',
            fontWeight: '420',
            ml: 1,
            marginRight: '15px',
          }}
        >
          {journalId ? t('EDIT FILE') : t('ADD NEW FILE')}
        </Typography>
        <TranslateButton
          loading={translateBtnLoading}
          onClick={async () => {
            setAllowSwFields(true);
            setTranslateBtnLoading(true);
            await handleTranslateData();
          }}
        >
          <TranslateIcon style={{ marginRight: '4px' }} />
          {t(`Translate`)}
        </TranslateButton>
      </LeftSide>
      <RightSide>
        <CheckPermissions
          requiredPermission={
            journalId
              ? ['634d3737298b7ce5da21b6a4']
              : ['634d371c298b7ce5da21b6a1']
          }
        >
          {journalId ? (
            <ImageWrapper onClick={() => setRemoveJournalComponent(true)}>
              <img src={Trash} width='18px' alt='trash' />
            </ImageWrapper>
          ) : null}
          <Box sx={{ width: '150px' }}>
            <StyledButton
              disable={
                disabledButton
                // (swFields === true
                //   ? !(tagsSW?.length === tagsEN?.length)
                //   : false)
              }
              btnvariant='true'
              onClick={async () => {
                setLoading(true);
                await handleAddJournal();
                setLoading(false);
              }}
              loading={loading}
            >
              {t(`Save Details`)}
            </StyledButton>
          </Box>
        </CheckPermissions>
      </RightSide>
    </TopBarContainer>
  );
}

export default TopBar;
