import { Typography, Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import { styled } from '@mui/system';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { ForgotPasswordFormSchema } from './validation';
import { forgotPassword } from '../../redux/services/auth';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

const initialValues = {
  email: '',
};
const backLink = {
  display: 'flex',
  alignItems: 'center',
  color: '#BB8D0B',
  textDecoration: 'none',
  cursor: 'pointer',
};
const AlertBox = styled('div')({
  background: '#CFFCDA',
  padding: '16px',
  color: '#178233',
  fontSize: '14px',
  lineHeight: '24px',
  textAlign: 'justify',
});
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  marginTop: '5px',
});
function ForgotPasswordForm({ title, subtitle }) {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const [countdown, setCountdown] = useState(0);
  const {t}=useTranslation();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotPasswordFormSchema,
    onSubmit: (values) => {
      setLoading(true);
      forgotPassword(values)
        .then((res) => {
          setLoading(false);
          setIsRequestSent(true);
          setIsBtnDisabled(true);
          setCountdown(30);
          setTimeout(() => {
            setIsBtnDisabled(false);
          }, 30000);
        })
        .catch(({ response }) => {
          setLoading(false);
          setIsRequestSent(false);
          setMessageObject({
            severity: 'error',
            text: response?.data?.message,
          });
          setOpenSnackbar(true);
          console.log('err', response);
        });
    },
  });

  useEffect(() => {
    // Timer that decrements itself each second and updates the mins/seconds downwards
    let timerInterval = setInterval(() => {
      // Countdown timer up, clear timer and do nothing
      if (countdown === 0) {
        clearInterval(timerInterval);
      } else {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [countdown]);
  const handleClick = () => {};
  return (
    <Grid container item xs={12}>
      <Grid item sx={{ mb: 3 }} xs={12}>
        <Link style={backLink} to='/'>
          <ArrowRight />
          <Typography
            variant='subtitle'
            align='left'
            sx={{
              fontSize: '14px',
              letterSpacing: '1.5px',
              fontWeight: '400',
              fontFamily: 'brandon-grotesque',
              ml: 1,
            }}
          >
            {t('BACK')}
          </Typography>
        </Link>
      </Grid>
      <Grid item sx={{ mb: 4 }} xs={12}>
        <Typography
          variant='h1'
          align='left'
          sx={{
            fontSize: '32px',
            mb: 2,
            letterSpacing: '1px',
            fontWeight: '400',
            fontFamily: 'brandon-grotesque',
          }}
        >
          {t(title)}
        </Typography>
        <Typography
          variant='subtitle1'
          align='left'
          sx={{ fontSize: '14px', fontFamily: 'Open Sans' }}
        >
          {t(subtitle)}
        </Typography>
      </Grid>
      {isRequestSent && (
        <Grid item sx={{ mb: 2 }}>
          <AlertBox>
            {t('We’ve sent the password reset instructions to your email if it has been registered with us.')}
          </AlertBox>
        </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} noValidate={true}>
          <Box sx={{ mb: '32px' }}>
            <Typography
              variant='subtitle1'
              align='left'
              sx={{
                fontSize: '14px',
                mb: 1,
                fontFamily: 'Open Sans',
                color: '#292D32',
                fontWeight: '600',
              }}
            >
              {t('Email Address')}
            </Typography>
            <StyledInput
              placeholder={t('Email')}
              type='email'
              onChange={handleChange}
              name='email'
              value={values.name}
              errorclass={errors.email && touched.email ? 'errorMessage' : null}
            />
            {errors.email && touched.email ? (
              <ErrorMessage>{t(errors.email)}</ErrorMessage>
            ) : null}
          </Box>
          <StyledButton
            type='submit'
            disable={isBtnDisabled}
            onClick={handleClick}
            loading={loading}
          >
            {isBtnDisabled
              ? `Resend in (${countdown} sec)`
              : t('SEND RESET INSTRUCTION')}
          </StyledButton>
        </form>
      </Grid>
      {openSnackbar && (
        <StyledSnackbar
          handleClose={() => setOpenSnackbar(false)}
          open={openSnackbar}
          messageObject={messageObject}
        />
      )}
    </Grid>
  );
}

export default ForgotPasswordForm;
