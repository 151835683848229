import React, { useState } from 'react';
import { styled } from '@mui/system';
import RemoveIcon from '../../assets/bin.svg';
import { Typography } from '@mui/material';
import StyledButton from '../Button/Button';
import { deleteCategory } from '../../redux/services/category';
import { removeCategory as removeCategoryInRedux } from '../../redux/actions/category';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

const Container = styled('div')({
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  marginBottom: '16px',
});
function RemoveCategory({
  category,
  setOpen,
  setMessageObject,
  setOpenSnackbar,
}) {
  const {t}=useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const delCategory = () => {
    setLoading(true);
    deleteCategory(category?._id)
      .then((res) => {
        if (res?.data) {
          setOpen(false);
        }
        setMessageObject({
          text: t('Category removed'),
          severity: 'success',
        });
        setOpenSnackbar(true);
        dispatch(removeCategoryInRedux(category?._id));
      })
      .catch((err) => {
        // console.log(err);
        setMessageObject({
          text: err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.data,
          severity: 'error',
        });
        setOpenSnackbar(true);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Container>
      <img src={RemoveIcon} alt='remove icon' width={200} />
      <Typography
        variant='subtitle1'
        align='left'
        sx={{
          fontSize: '14px',
          mb: 2,
          fontFamily: 'Open Sans',
          textAlign: 'center',
          fontWeight: '500',
          lineHeight: '24px',
          padding: '0px 10px',
        }}
      >
        {t(`Are you really want to remove`)}{' '}
        <strong>
          {category?.name?.length > 25
            ? category?.name?.slice(0, 25) + '...'
            : category?.name}
        </strong>
        ? {t(`If you remove this category, it will remove permanently from the list.`)}
      </Typography>
      <StyledButton onClick={delCategory} btnvariant='true' loading={loading}>
        {t(`Remove`)}
      </StyledButton>
    </Container>
  );
}

export default RemoveCategory;
