import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { ReactComponent as TranslateIcon } from '../../assets/translate-icon.svg';
import { useTranslation } from 'react-i18next';
import TranslateButton from '../Button/TranslateBtn';

const TopBarContainer = styled('div')({
  width: '100%',
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  padding: '16px',

  boxSizing: 'border-box',
  borderBottom: '1px solid #F2F2F2',
});

const LeftSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const RightSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
function TopBar({
  title,
  onClick,
  rightSide,
  fontSize,
  tag,
  handleTranslateData,
  translateBtnLoading,

  setTranslateBtnLoading,
}) {
  const { t } = useTranslation();
  return (
    <TopBarContainer>
      <LeftSide>
        <Typography
          variant='subtitle'
          align='left'
          sx={{
            fontSize: '20px',
            letterSpacing: '1.5px',
            color: '#292D32',
            fontFamily: 'brandon-grotesque',
            textTransform: 'uppercase',
            fontWeight: '420',
            ml: 1,
            mr: 2,
          }}
        >
          {title}
        </Typography>

        <TranslateButton
          loading={translateBtnLoading}
          onClick={async () => {
            // setAllowSwFields(true);
            setTranslateBtnLoading(true);
            await handleTranslateData();
            setTranslateBtnLoading(false);
          }}
        >
          <TranslateIcon style={{ marginRight: '4px' }} />
          {t(`Translate`)}
        </TranslateButton>
      </LeftSide>
      <RightSide>{rightSide}</RightSide>
    </TopBarContainer>
  );
}

export default TopBar;
