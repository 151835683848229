import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { setAuthenticated, setLogoutState } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StyledAvatar from '../Avatar/Avatar';
import apiConfig from 'src/config/api.service';
import { useTranslation } from 'react-i18next';

function Logout() {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    // console.log('event', event);
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();
  const user = useSelector((store) => store?.auth?.user);
  // eslint-disable-next-line no-unused-vars
  const profileImage = useSelector((store) => store?.auth?.profileImage);

  // console.log(user);
  return (
    <div>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}

        {user?.image ? (
          <StyledAvatar
            src={apiConfig.imageUrlPrefix + user?.image}
            width={36}
            height={36}
          />
        ) : (
          <StyledAvatar width={36} height={36}>
            {console.log(user?.name)}
            {user?.name ? user?.name.charAt(0).toUpperCase() : ''}
          </StyledAvatar>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            width: '150px',
            left: '725px',
            borderRadius: '0px',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            '&:hover': { background: 'none' },
          }}
          onClick={() => {
            navigate('/settings');
            handleClose();
          }}
        >
          {t(`Settings`)}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            color: '#BB8D0B',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            '&:hover': { background: 'none' },
          }}
          onClick={() => {
            dispatch(setLogoutState());
            handleClose();
            dispatch(setAuthenticated('unauthenticated'));
            navigate('/');
          }}
        >
          {t(`Logout`)}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Logout;
