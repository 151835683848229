import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { useTranslation } from 'react-i18next';

const TopBarContainer = styled('div')({
  width: '100%',
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  padding: '16px',

  boxSizing: 'border-box',
  borderBottom: '1px solid #F2F2F2',
});
const StyledLink = styled(Link)({
  color: '#BB8D0B',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});
const LeftSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const RightSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
function TopBar({ title, backLink, onClick, rightSide, fontSize, tag }) {
  const { t } = useTranslation();
  return backLink ? (
    <TopBarContainer>
      <LeftSide>
        <StyledLink onClick={onClick}>
          <ArrowRight />
          <Typography
            variant='subtitle'
            align='left'
            sx={{
              fontSize: '14px',
              letterSpacing: '1.5px',
              fontWeight: '420',
              fontFamily: 'brandon-grotesque',

              ml: 1,
            }}
          >
            {t(`BACK`)} /
          </Typography>
        </StyledLink>
        <Typography
          variant='subtitle'
          align='left'
          sx={{
            fontSize: '14px',
            letterSpacing: '1.5px',
            color: '#7B858F',
            fontFamily: 'brandon-grotesque',
            textTransform: 'uppercase',
            fontWeight: '420',
            ml: 1,
          }}
        >
          {title ? title : t(`CHANGE PASSWORD`)}
        </Typography>
      </LeftSide>
      <RightSide>{rightSide}</RightSide>
    </TopBarContainer>
  ) : (
    <TopBarContainer>
      <LeftSide>
        <Typography
          variant='subtitle'
          align='left'
          sx={{
            fontSize: fontSize,
            color: 'black',
            letterSpacing: '1.5px',
            fontFamily: 'brandon-grotesque',
            fontWeight: '420',
            px: 1,
          }}
        >
          {title}
        </Typography>
        {tag}
      </LeftSide>
      <RightSide>{rightSide}</RightSide>
    </TopBarContainer>
  );
}

export default TopBar;
