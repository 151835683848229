import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';
import React from 'react';
import { Box } from '@mui/system';

const Container = styled('div')({
  // marginRight: '2px',
  // display: 'flex',
  width: '85px',
  height: '85px',
  position: 'absolute',
  top: '-2px',
  left: '-3px',
  right: '0',
  bottom: '0',
  // opacity: '0.5',
  borderRadius: '50%',
  // backgroundColor: '#E9D5B2',
  border: '2px dashed #BB8D0B',
  boxSizing: 'border-box',
  // boxShadow: ' inset 0px 0px 0px 1px #bb8d0b',

  '@keyframes rotate': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  animation: 'rotate 10s linear infinite',
});

function StyledAvatar({
  src,
  alt,
  width,
  height,
  marginBottom,
  children,
  loading,
}) {
  return (
    <Box
      sx={{
        width: width,
        height: height,
        position: 'relative',
        mb: marginBottom,
      }}
    >
      <Avatar
        alt={alt}
        src={src}
        // loading={loading}
        sx={{
          width: width,
          height: height,
          fontSize: '12px',
          backgroundColor: '#F6EEE0',
          color: '#BB8D0B',
          // mb: marginBottom,
        }}
      >
        {children}
      </Avatar>
      {loading ? <Container /> : null}
    </Box>
  );
}

export default StyledAvatar;
