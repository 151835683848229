import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { verifyUser } from './redux/services/auth';
import Authentication from './routes/Authentication/Authentication';
import Main from './routes/Main/Main';
import { setAuthenticated } from './redux/actions/auth';
// import { refreshToken } from './redux/services/auth';
import Loader from './components/Loader/Loader';
import {  getToken } from './helpers/utils';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { setLocale } from 'src/redux/actions/general';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next"
        }
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });



function App() {
  const authenticate = useSelector((state) => state.auth.authenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('refresh token check');
    if (window.location.pathname === '/invite') {
      localStorage.clear();
      dispatch(setAuthenticated('unauthenticated'));
    } else {
      const token = getToken();
      if (token) {
        // let isExpired = isTokenExpired();
        // if (isExpired) {
        //   let refToken = {
        //     refreshToken: getRefreshToken(),
        //   };
        //   dispatch(refreshToken)(refToken)
        //     .then((res) => {
        //       let receivedTime = new Date();
        //       localStorage.setItem('token', JSON.stringify(res?.token));
        //       localStorage.setItem(
        //         'refreshToken',
        //         JSON.stringify(res?.data?.refreshToken)
        //       );
        //       localStorage.setItem('time', JSON.stringify(receivedTime));
        //     })
        //     .catch((err) => {});
        // }

        dispatch(verifyUser)()
          .then((res) => {
            dispatch(setAuthenticated('authenticated'));
          })
          .catch((err) => {
            dispatch(setAuthenticated('unauthenticated'));
          });
      } else {
        dispatch(setAuthenticated('unauthenticated'));
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log('auth value', authenticate);
  }, [authenticate]);

  useEffect(()=>{
    const locale=localStorage.getItem('locale');
    dispatch(setLocale(locale || "sw"))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  switch (authenticate) {
    case 'authenticated':
      return <Main />;
    case 'unauthenticated':
      return <Authentication />;

    default:
      return (
        <div className='fullScreenLoader'>
          <Loader />
        </div>
      );
  }
}

export default App;
