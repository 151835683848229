import React, { useEffect } from 'react';
import { request } from '../../shared/verb';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

function Invite({ setOpenSnackbar }) {
  const Navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('c');
    // console.log(code);
    if (!code) {
      Navigate('/');
    } else {
      request(`/invitation/verify`, { code: code }, 'post')
        .then((res) => {
          console.log('then -> invite');
          localStorage.setItem('InviteData', JSON.stringify(res?.data?.data));
          Navigate('/register');
          // return res;
        })
        .catch((err) => {
          console.log('Error -->', err);
          console.log('Invite Not Found');
          Navigate('/');
          setOpenSnackbar(true);
          console.log('Updated');
        });
    }
    // eslint-disable-next-line
  }, []);
  return <Loader />;
}

export default Invite;
