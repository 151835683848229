import React from 'react';
import styled from 'styled-components';

const TextArea = styled('textarea')({
  // display: 'none',
  minHeight: '88px !important',
  maxHeight: '150px !important',
  width: '100% !important',
  borderRadius: '0px',
  fontSize: '14px',
  outline: 'none',
  // border:'1px solid #D9DEE2',
  border: (props) =>
    props.errorclass ? '1px solid #B1484E' : '1px solid #D9DEE2',
  padding: '6px 12px',
  fontFamily: 'Open Sans',
  boxSizing: ' border-box',
  '::placeholder': {
    color: '#7B858F',
    opacity: '1',
    fontSize: '14px',
  },
});

function StyledTextArea({
  placeholder,
  type,
  name,
  style,
  value,
  onChange,
  errorclass,
  ...props
}) {
  return (
    <TextArea
      placeholder={placeholder}
      type={type}
      name={name}
      errorclass={errorclass}
      style={style}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

export default StyledTextArea;
