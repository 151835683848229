/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { initializeStore as store } from '../redux/configureStore';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getToken } from 'src/helpers/utils';
import apiObj from '../config/api.service';
import { setAuthenticated, setLogoutState } from 'src/redux/actions/auth';

const api = apiObj.url;
console.log('API -->', api);

const refreshAuthLogic = (failedRequest) => {
  const refreshToken = localStorage.getItem('refreshToken');
  console.log(refreshToken);
  return axios
    .post(`${api}/users/refreshToken`, {
      refreshToken,
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem('token', tokenRefreshResponse.data.data.token);
      localStorage.setItem(
        'refreshToken',
        tokenRefreshResponse.data.data.refreshToken
      );
      failedRequest.response.config.headers = {
        ...failedRequest.response.config.headers,
        'x-access-token': tokenRefreshResponse.data.data.token,
      };
      return Promise.resolve();
    })
    .catch(() => {
      console.log('failed to refresh token');
      window.location.href = '/';
      store.dispatch(setLogoutState());
      store.dispatch(setAuthenticated('unauthenticated'));
      console.log('refresh token - after dispatch');
      return Promise.reject();
    });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: true,
});

export const fetchToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  if (token) {
    localStorage.setItem('token', token);
    return token;
  }
  return localStorage.getItem('token');
};

const skipRefreshLogicRoutes = [
  '/users/login',
  '/users/register',
  '/users/change-password',
  '/users/remove',
];

// export const request = (url, data, type, noHeaders, params) =>
export const request = (url, data, type, noHeaders, params, locale) =>
  new Promise((resolve, reject) => {
    let token = '' || getToken();
    const apiUrl = api;
    const requestObj = {
      method: type,
      url: apiUrl + url,
    };
    console.log(locale);
    noHeaders || (token = fetchToken());
    type !== 'get' && (requestObj.data = data);
    noHeaders ||
      (requestObj.headers = {
        'x-access-token': token,
        'x-locale': locale || localStorage.getItem('locale') || 'sw',
      });
    params && (requestObj.params = params);
    console.log(requestObj.url);
    skipRefreshLogicRoutes.forEach((o) => {
      if (requestObj.url.includes(o)) {
        requestObj.skipAuthRefresh = true;
      }
    });
    // axios({ ...requestObj })
    //   .then((response) => resolve(response))
    //   .catch((err) => reject(err));
    axios({ ...requestObj }).then(resolve, reject);
    // interceppedAxios(requestObj).then(resolve, reject);
  });

// axios.interceptors.request.use((request) => {
//   console.log({ request });
//   if (getLocalToken()) {
//     request.headers['x-access-token'] = getLocalToken();
//     request.headers['x-locale'] = 'en';
//   }
//   return request;
// });
// // const refreshAuthLogic = (failedRequest) =>
// //   axios
// //     .post(`${api}/users/refreshToken`, {
// //       refreshToken: JSON.parse(localStorage.getItem('refreshToken')),
// //     })
// //     .then((tokenRefreshResponse) => {
// //       console.log(tokenRefreshResponse?.data?.data?.token);
// //       localStorage.setItem(
// //         'token',
// //         JSON.stringify(tokenRefreshResponse?.data?.datna?.token)
// //       );
// //       localStorage.setItem(
// //         'refreshToken',
// //         JSON.stringify(tokenRefreshResponse?.data?.data?.refreshToken)
// //       );
// //       failedRequest.response.config.headers.Authorization =
// //         tokenRefreshResponse?.data?.data?.token;
// //       console.log('after', {
// //         ...failedRequest,
// //       });
// //       return Promise.resolve();
// //     })
// //     .catch(() => {
// //       return Promise.reject();
// //     });
// export const refreshIdToken = async () => {
//   try {
//     const refreshToken = localStorage.getItem('refreshToken');
//     if (!refreshToken) throw new Error('Refresh token not found.');
//     const { data: { data: { token, user } = {} } = {} } = await axios.post(
//       `${api}/users/refreshToken`,
//       {
//         refreshToken,
//       }
//     );
//     console.log(user, token);
//     localStorage.setItem('token', token);
//   } catch (err) {
//     if (
//       err.message === 'Refresh token not found.' ||
//       err?.response?.status === 400
//     ) {
//       // send to login
//     }
//     console.error(err);
//   }
// };
// function getIdToken() {
//   return localStorage.getItem('token') || '';
// }
// export const request = (url, data, type, noHeaders, params) => {
//   return new Promise((resolve, reject) => {
//     const headers = {
//       'x-access-token': getIdToken(),
//       'x-locale': 'en',
//     };
//     console.log(url, data, type, noHeaders, params);
//     axios({
//       method: type,
//       url: api + url,
//       headers,
//       data,
//       params,
//     })
//       .then((res) => {
//         console.log(res);
//         return resolve(res);
//       })
//       .catch((err) => {
//         if (err?.response?.status === 401) {
//           refreshIdToken()
//             .then(() => {
//               console.log(err.config.headers, 'PREV');
//               err.config.headers = {
//                 ...err.config.headers,
//                 'x-access-token': getIdToken(),
//               };
//               console.log(err.config.headers);
//               axios(err.config)
//                 .then((res) => {
//                   resolve(res);
//                 })
//                 .catch((err) => reject(err?.response?.data));
//             })
//             .catch((error) => reject(error));

//           return;
//         }
//         reject(err?.response?.data);
//       });
//   });
// };
