// import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import minus from 'src/assets/minus.svg';
import add from 'src/assets/add.svg';
import LinearProgress from 'src/components/LinearProgress/LinearProgress'

const PageViewerWrapper = styled('div')({
  position: 'relative',
  width: '937px',
  height: '625px',
  img: {
    height: '100%',
    width: '100%',
  },
});
const BtnWrapper = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '8px',
  alignItems: 'center',
  top: '5%',
  right: '5%',
  width: '90px',
  height: '48px',
  borderRadius: '24px',
  background: 'rgba(233, 213, 178, 0.6)',
  button: {
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    border: 'none',
    background: 'rgba(187, 141, 11, 0.4)',
    color: '#FFFFFF',
    cursor: 'pointer',
    img: {
      width: '20px',
    },
  },
});

const imgProgressStyling={
  width: '50%',
  position: 'absolute',
  top: '50%',
  left: '25%',
  background: '#BB8D0B',
  borderRadius: '5px',
}

const PageViewer = ({ img, percentage, progressBar }) => {
  return (
    <TransformWrapper initialScale={1} disabled={progressBar}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div
          style={{
            position: "relative",
          }}
        >
          <TransformComponent>
            <PageViewerWrapper>
              <img src={img} style={{opacity: progressBar ? '.7': '1'}} alt="journal-page" />
              {progressBar ?
                <LinearProgress style={imgProgressStyling} percentage={percentage} />
                :  null
              }
            </PageViewerWrapper>
          </TransformComponent>
          <BtnWrapper>
            <button onClick={() => zoomOut()} disabled={progressBar}>
              <img alt="" src={minus} />
            </button>
            <button onClick={() => zoomIn()} disabled={progressBar}>
              <img alt="" src={add} />
            </button>
          </BtnWrapper>
        </div>
      )}
    </TransformWrapper>
  );
};

export default PageViewer;
