import React, { useState } from 'react';
import CategoryCard from '../CategoryCard/CategoryCard';
import { styled } from '@mui/system';
import Modal from '../Modal/Modal';
import RemoveCategory from '../RemoveCategory/RemoveCategory';
import UpdateCategoryForm from '../AddCategoryForm/UpdateCategoryForm';
import { useSelector } from 'react-redux';
import { translateData, translateDataSWToEN } from 'src/redux/services/general';
import { useTranslation } from "react-i18next";

const Container = styled('div')({
  display: 'flex',
  alignItem: 'center',
  flexWrap: 'wrap',
  padding: '8px',
});

function CategoriesList({ setMessageObject, setOpenSnackbar }) {
  const [open, setOpen] = useState(false);
  const [handleModalKey, setHandleModalKey] = useState();
  const [category, setCategory] = useState();
  const [translate,setTranslate]=useState(false);
  const [categoryNameSW,setCategoryNameSW]=useState();
  const [categoryDesSW,setCategoryDesSW]=useState();
  const [categoryNameEN,setCategoryNameEN]=useState();
  const [categoryDesEN,setCategoryDesEN]=useState();
  const [loading, setLoading]=useState();
  
  const {t}=useTranslation();
  const categoriesData = useSelector(
    (state) => state.allCategories.categories?.data
  );
  const {locale}=useSelector((state)=>state.general);
  const handleClickOpen = (data, key) => {
    setOpen(true);
    setCategory(data);
    setHandleModalKey(key);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const categoryTranslation= async ()=>{
    setLoading(true);
    let data={
      category: categoryNameEN,
      description: categoryDesEN,
    }

    let res;
    if(locale === "en"){
      res=await translateData(data);
    }
    else{
      res=await translateDataSWToEN(data);
    }
    setCategoryNameSW(res?.category);
    setCategoryDesSW(res?.description)
    if(res){
      setLoading(false);
      setTranslate(true);
    }
  }
  return (
    <Container>
      {categoriesData ?
        categoriesData.map((ele, index) => {
          // console.log(index, ele);
          return (
            <CategoryCard
              key={index}
              title={locale=== "en" ? ele?.name_en || ele?.name : ele?.name_sw || ele?.name}
              image={ele.image}
              categoryData={ele}
              handleClickOpen={handleClickOpen}
            />
          );
        }) : null}
      {open ? (
        <Modal
          handleClose={()=> {
            handleClose()
            setCategoryNameSW("");
            setCategoryDesSW("");
            setTranslate(false);
          }}
          open={open}
          title={handleModalKey === 'del' ? t('Remove Category') : t('Edit Category')}
          translateBtn={!(handleModalKey === 'del')}
          categoryTranslation={categoryTranslation}
          loading={loading}
        >
          {handleModalKey === 'del' ? (
            <RemoveCategory
              category={category}
              setOpen={setOpen}
              setOpenSnackbar={setOpenSnackbar}
              setMessageObject={setMessageObject}
            />
          ) : (
            <UpdateCategoryForm
              category={category}
              setOpen={setOpen}
              setOpenSnackbar={setOpenSnackbar}
              setMessageObject={setMessageObject}
              handleClose={handleClose}
              categoryNameSW={categoryNameSW}
              categoryDesSW={categoryDesSW}
              setCategoryNameSW={setCategoryNameSW}
              setCategoryDesSW={setCategoryDesSW}
              categoryNameEN={categoryNameEN}
              categoryDesEN={categoryDesEN}
              setCategoryNameEN={setCategoryNameEN}
              setCategoryDesEN={setCategoryDesEN}
              translate={translate}
              locale={locale}
            />
          )}
        </Modal>
      ) : null}
    </Container>
  );
}

export default CategoriesList;
