import React from 'react';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import SignInForm from '../../components/SignInForm/SignInForm';
import { useTranslation } from 'react-i18next';

function SignIn({ openSnackbar, setOpenSnackbar }) {
  const {t}=useTranslation();
  return (
    <AuthLayout>
      <SignInForm
        title={t('SIGN IN')}
        subtitle={t('Welcome to the Åland Maritime Museum admin panel')}
      />
      {openSnackbar === true ? (
        <StyledSnackbar
          handleClose={() => setOpenSnackbar(false)}
          open={openSnackbar}
          messageObject={{
            severity: 'error',
            text: t('Invite link is invalid or expired.'),
          }}
        />
      ) : null}
    </AuthLayout>
  );
}

export default SignIn;
