import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoAccessRights from '../NoAccessRights/NoAccessRights';

function CheckPermissions({
  children,
  requiredPermission = [],
  NoAccessRight,
}) {
  const userPermissions = useSelector(
    (store) => store?.auth?.user?.permissions
  );
  const [allowAccess, setAllowAccess] = useState(false);
  const [status, setStatus] = useState('pending');


  useEffect(() => {
    if (userPermissions) {
      let counter = 0;
      requiredPermission?.forEach((ele) => {
        if (userPermissions.includes(ele)) counter++;
      });
      if (requiredPermission.length === counter) {
        setAllowAccess(true);
      }
      setStatus('decided');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissions]);
  
  return (
    <>
      {allowAccess ? (
        children
      ) : status === 'decided' && NoAccessRight ? (
        <NoAccessRights />
      ) : (
        ''
      )}
    </>
  );
}

export default CheckPermissions;
