import React, { useEffect } from 'react';
import { useState } from 'react';
import AppWrapper from '../../components/AppWrapper/AppWrapper';
import CategoriesList from '../../components/CategoriesList/CategoriesList';
import Modal from '../../components/Modal/Modal';
import Tag from '../../components/Tag/Tag';
import TopBar from '../../components/TopBar/TopBar';
import CategoryTopRightBar from '../../components/TopRightBar/Category';
import AddCategoryForm from '../../components/AddCategoryForm/AddCategoryForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../redux/services/category';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import DataNotFound from 'src/components/DataNotFound/DataNotFound';
import Loader from 'src/components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { translateData, translateDataSWToEN } from 'src/redux/services/general';

function Category() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  // const [imageData, setImageData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const [translate, setTranslate] = useState(false);
  const [categoryNameSW, setCategoryNameSW] = useState();
  const [categoryDesSW, setCategoryDesSW] = useState();
  const [categoryNameEN, setCategoryNameEN] = useState();
  const [categoryDesEN, setCategoryDesEN] = useState();
  const [loading, setLoading] = useState();

  const { locale } = useSelector((state) => state.general);

  const handleSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setCategoryDesEN('');
    setCategoryDesSW('');
    setCategoryNameEN('');
    setCategoryNameSW('');
    setTranslate(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const categoryTranslation = async () => {
    setLoading(true);
    let data = {
      category: categoryNameEN,
      description: categoryDesEN,
    };
    let res;
    if (locale === 'en') {
      res = await translateData(data);
    } else {
      res = await translateDataSWToEN(data);
    }
    setCategoryNameSW(res?.category);
    setCategoryDesSW(res?.description);
    if (res) {
      setLoading(false);
      setTranslate(true);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(() => getAllCategories(dispatch, locale))()
      .then((res) => {
        setCategoryData(res?.data);
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, [locale]);

  // console.log('CATEGORY -->', categoryData);
  return (
    <AppWrapper>
      <TopBar
        fontSize='20px'
        title={t('CATEGORY')}
        tag={
          <Tag>
            {categoryData?.length} {t(`CATEGORIES`)}
          </Tag>
        }
        rightSide={<CategoryTopRightBar handleClickOpen={handleClickOpen} />}
      />

      {categoryData ? (
        categoryData?.length >= 1 ? (
          <CategoriesList
            handleClose={handleClose}
            setOpenSnackbar={setOpenSnackbar}
            setMessageObject={setMessageObject}
          />
        ) : (
          <DataNotFound data={t('Categories')} />
        )
      ) : (
        <Loader />
      )}

      {open && (
        <Modal
          handleClose={() => {
            handleClose();
            setCategoryNameSW('');
            setCategoryDesSW('');
            setTranslate(false);
          }}
          open={open}
          title={t('Add Category')}
          translateBtn
          categoryTranslation={categoryTranslation}
          loading={loading}
        >
          <AddCategoryForm
            handleClose={handleClose}
            setCategoryData={setCategoryData}
            setOpenSnackbar={setOpenSnackbar}
            setMessageObject={setMessageObject}
            categoryNameSW={categoryNameSW}
            categoryDesSW={categoryDesSW}
            setCategoryNameSW={setCategoryNameSW}
            setCategoryDesSW={setCategoryDesSW}
            categoryNameEN={categoryNameEN}
            categoryDesEN={categoryDesEN}
            setCategoryNameEN={setCategoryNameEN}
            setCategoryDesEN={setCategoryDesEN}
            translate={translate}
            locale={locale}
          />
        </Modal>
      )}

      {openSnackbar && (
        <StyledSnackbar
          handleClose={handleSnackbar}
          open={openSnackbar}
          messageObject={messageObject}
        />
      )}
    </AppWrapper>
  );
}

export default Category;
