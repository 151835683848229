import React from 'react';
import { styled } from '@mui/system';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';

const SearchBar = styled('input')({
  width: '232px',
  height: '22px',
  padding: '6px 12px',
  border: '1px solid #D9DEE2',
  outline: 'none',
  fontFamily: 'Open Sans',
});
const Container = styled('div')({
  height: '36px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
});
function StyledSearchBar({
  handleChange,
  handleClick,
  placeholder,
  onEnter = false,
}) {
  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && onEnter) {
      handleClick();
    }
  };

  return (
    <Container>
      <SearchBar
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
      />
      <SearchIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
    </Container>
  );
}

export default StyledSearchBar;
