import { Typography, Grid, Box, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../../assets/unlock.svg';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import { resetPasswordSchema } from './validation';
import { resetPassword } from '../../redux/services/auth';

const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  marginTop: '5px',
});
const backLink = {
  display: 'flex',
  alignItems: 'center',
  color: '#BB8D0B',
  textDecoration: 'none',
  cursor: 'pointer',
};
const initialValues = {
  password: '',
};
function ResetPasswordForm({ title, subtitle }) {
  const [toggleInputType, setToggleInputType] = useState('password');
  const Navigate = useNavigate();
  const setFormData = (values) => {
    let data = {
      email: JSON.parse(localStorage.getItem('ForgotPassInviteData')),
      code: localStorage.getItem('token'),
      ...values,
    };
    return data;
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      let data = setFormData(values);
      resetPassword(data)
        .then((res) => {
          Navigate('/');
        })
        .catch((err) => console.log(err));
    },
  });
  const setInputAdornment = () => {
    return (
      <InputAdornment position='end'>
        {toggleInputType === 'password' ? (
          <LockIcon onClick={() => setToggleInputType('text')} />
        ) : (
          <UnlockIcon onClick={() => setToggleInputType('password')} />
        )}
      </InputAdornment>
    );
  };
  return (
    <Grid container item xs={12}>
      <Grid item sx={{ mb: 3 }} xs={12}>
        <Link style={backLink}>
          <ArrowRight />
          <Typography
            variant='subtitle'
            align='left'
            sx={{
              fontSize: '14px',
              letterSpacing: '1.5px',
              fontWeight: '400',
              fontFamily: 'brandon-grotesque',
              ml: 1,
            }}
          >
            BACK
          </Typography>
        </Link>
      </Grid>
      <Grid item sx={{ mb: 4 }} xs={12}>
        <Typography
          variant='h1'
          align='left'
          sx={{
            fontSize: '32px',
            mb: 2,
            letterSpacing: '1.5px',
            fontWeight: '400',
            fontFamily: 'brandon-grotesque',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='subtitle1'
          align='left'
          sx={{ fontSize: '14px', fontFamily: 'Open Sans' }}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} noValidate={true}>
          <Box sx={{ mb: '32px' }}>
            <Typography
              variant='subtitle1'
              align='left'
              sx={{
                fontSize: '14px',
                mb: 1,
                fontFamily: 'Open Sans',
                color: '#292D32',
                fontWeight: '600',
              }}
            >
              Password{' '}
            </Typography>
            <StyledInput
              placeholder='Password'
              type={toggleInputType}
              name='password'
              value={values.name}
              errorclass={
                errors.password && touched.password ? 'errorMessage' : null
              }
              onChange={handleChange}
              InputProps={{
                endAdornment: setInputAdornment(),
              }}
            />
            {errors.password && touched.password ? (
              <ErrorMessage>{errors.password}</ErrorMessage>
            ) : null}
          </Box>
          <StyledButton type='submit'>UPDATE PASSWORD</StyledButton>
        </form>
      </Grid>
    </Grid>
  );
}

export default ResetPasswordForm;
