import * as yup from 'yup';

export const addHeroSectionSchema = yup.object().shape({
  pb_title_en: yup
    .string()
    .required('Title is required')
    .trim()
    .max(60, 'Must be less than 60 characters'),
  pb_title_sw: yup.string().trim().max(60, 'Must be less than 60 characters'),
  pb_subtitle_en: yup
    .string()
    .trim()
    .required('Sub-Heading is required')
    .max(600, 'Must be less than 600 characters'),
  pb_subtitle_sw: yup
    .string()
    .trim()
    .max(600, 'Must be less than 600 characters'),
});
