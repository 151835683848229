import React from 'react';
import RegisterLayout from '../../components/RegisterLayout/RegisterLayout';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
  const {t}=useTranslation();
  return (
    <RegisterLayout>
      <ForgotPasswordForm
        title={t('FORGOT PASSWORD?')}
        subtitle={t('No problem! Just enter the email address that you signed up with to reset it.')}
      />
    </RegisterLayout>
  );
}

export default ResetPassword;
