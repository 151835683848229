import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import { Box, styled } from '@mui/system';
import { useFormik } from 'formik';
import { InviteUserFormSchema } from './validation';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import StyledCheckbox from '../ContainedCheckbox/ContainedCheckbox';
import { ReactComponent as SendInviteIcon } from '../../assets/sendInviteIcon.svg';
import { useSelector } from 'react-redux';
import { createInvitation } from '../../redux/services/auth';
import { useTranslation } from "react-i18next";

const initialValues = {
  email: '',
  permissions: [],
};
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  lineHeight: '24px',
  // marginTop: '-8px',
});

function InviteUserForm({
  handleClose,
  users,
  setUsers,
  setMessageObject,
  setOpenSnackbar,
}) {
  const [loading, setLoading] = useState(false);
  const [resMessage, setResponseMessage] = useState();

  // Get permissions from Redux
  const permissions = useSelector(
    (state) => state.allPermissions?.permissions?.data
  );
  const {t}=useTranslation();

  // Send user invitation call
  const userInvite = (values, data, resetForm) => {
    createInvitation(values)
      .then((res) => {
        setLoading(false);
        if (res?.data?.message === 'Invite already exists') {
          setResponseMessage('Invite already exists');
        } else {
          data = {
            ...res?.data?.data?.userInfo,
            status: 'pending',
          };
          setUsers([...users, data]);

          setMessageObject({
            text: 'Invite sent',
            severity: 'success',
          });
          setOpenSnackbar(true);
          resetForm();
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        setMessageObject({
          text: err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.data,
          severity: 'error',
        });
        setOpenSnackbar(true);
        resetForm();
      });
  };

  // Formik
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: InviteUserFormSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      let data;
      userInvite(values, data, resetForm);
      setSubmitting(false);
    },
  });

  return (
    <form
      onSubmit={handleSubmit}
      noValidate={true}
      style={{ padding: '8px 16px', paddingBottom: '16px' }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography
          variant='subtitle1'
          align='left'
          fontFamily='Open Sans'
          sx={{ fontSize: '14px', mb: 1 }}
        >
          {t(`Email Address`)}<sup style={{ color: '#B1484E' }}>*</sup>
        </Typography>
        <StyledInput
          placeholder='Email'
          type='email'
          variant3='true'
          secondary='true'
          onChange={handleChange}
          name='email'
          value={values.email}
          errorclass={errors.email && touched.email ? 'errorMessage' : null}
        />
        {errors.email && touched.email ? (
          <ErrorMessage>{errors.email}</ErrorMessage>
        ) : null}
        <Typography
          variant='subtitle1'
          fontFamily='Open Sans'
          align='left'
          sx={{ fontSize: '14px', my: 1 }}
        >
          {t(`Permissions`)}<sup style={{ color: '#B1484E' }}>*</sup>
        </Typography>
        {permissions &&
          permissions.map((ele, index) => {
            return (
              <Box
                sx={{ marginBottom: '12px', fontFamily: 'Open Sans' }}
                key={index}
              >
                <StyledCheckbox
                  isChecked={values.permissions.includes(ele._id)}
                  label={t(ele.name)}
                  id={ele._id}
                  name='permissions'
                  value={ele._id}
                  onChange={handleChange}
                  errorclass={
                    errors.permissions && touched.permissions
                      ? 'errorMessage'
                      : null
                  }
                />
              </Box>
            );
          })}
        {errors.permissions && touched.permissions ? (
          <ErrorMessage>{errors.permissions}</ErrorMessage>
        ) : null}
      </Box>
      <StyledButton loading={loading} type='submit' iconButton={true}>
        <SendInviteIcon style={{ marginRight: '6px' }} />
        Send
      </StyledButton>
      {resMessage && (
        <ErrorMessage style={{ marginTop: '12px' }}>{resMessage}</ErrorMessage>
      )}
    </form>
  );
}

export default InviteUserForm;
