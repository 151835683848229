import React, { useState, useEffect } from 'react';
import AppWrapper from '../../components/AppWrapper/AppWrapper';
import InviteUserForm from '../../components/InviteUserForm/InviteUserForm';
import Modal from '../../components/Modal/Modal';
import Tag from '../../components/Tag/Tag';
import TopBar from '../../components/TopBar/TopBar';
import UsersList from '../../components/UsersList/UsersList';
import UsersTopRightBar from '../../components/TopRightBar/Users';
import { getAllUsers } from '../../redux/services/users';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';
import DataNotFound from 'src/components/DataNotFound/DataNotFound';
import Loader from 'src/components/Loader/Loader';
function Users() {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [filter, setFilter] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const [usersCount, setUsersCount] = useState(null);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getAllUsers()
      .then((res) => {
        setUsers(res?.data);
        setUsersCount(res?.data?.length);
        setShowLoader(false);
      })
      .catch((err) => console.log(err));
  }, []);
  let filteredUsers = users;
  if (filter) {
    // console.log('INSIDE filter');
    filteredUsers = filteredUsers.filter((user) =>
      user?.name?.toLowerCase().includes(filter?.toLowerCase())
    );
  } else {
    filteredUsers = users;
  }
  useEffect(() => {
    setUsersCount(filteredUsers?.length);
  }, [filteredUsers]);

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <AppWrapper>
      <TopBar
        fontSize='20px'
        title={t('USERS')}
        tag={
          <Tag>
            {usersCount} {t(`USERS`)}
          </Tag>
        }
        rightSide={
          <UsersTopRightBar
            handleClickOpen={handleClickOpen}
            text={t('Invite User')}
            handleChange={handleChange}
          />
        }
      />
      {/* {users && !filter ? (
        <UsersList
          users={users}
          filteredUsers={filteredUsers}
          setUsers={setUsers}
          filter={filter}
          setOpenSnackbar={setOpenSnackbar}
          setMessageObject={setMessageObject}
        />
      ) : filter && filteredUsers?.length >= 1 ? (
        <UsersList
          users={users}
          filteredUsers={filteredUsers}
          setUsers={setUsers}
          filter={filter}
          setOpenSnackbar={setOpenSnackbar}
          setMessageObject={setMessageObject}
        />
      ) : (
        <DataNotFound data='users' />
      )} */}
      {showLoader ? (
        <Loader />
      ) : users && !filter ? (
        <UsersList
          users={users}
          filteredUsers={filteredUsers}
          setUsers={setUsers}
          filter={filter}
          setOpenSnackbar={setOpenSnackbar}
          setMessageObject={setMessageObject}
        />
      ) : filter && filteredUsers?.length >= 1 ? (
        <UsersList
          users={users}
          filteredUsers={filteredUsers}
          setUsers={setUsers}
          filter={filter}
          setOpenSnackbar={setOpenSnackbar}
          setMessageObject={setMessageObject}
        />
      ) : (
        <DataNotFound data='users' />
      )}

      {open && (
        <Modal
          handleClose={handleClose}
          open={open}
          title={t('Invite User')}
          subtitle={t('All invited people will be granted access to the site.')}
        >
          <InviteUserForm
            handleClose={handleClose}
            setUsers={setUsers}
            users={users}
            setOpenSnackbar={setOpenSnackbar}
            setMessageObject={setMessageObject}
          />
        </Modal>
      )}

      {openSnackbar && (
        <StyledSnackbar
          handleClose={handleSnackbar}
          open={openSnackbar}
          messageObject={messageObject}
        />
      )}
    </AppWrapper>
  );
}

export default Users;
