/* eslint-disable no-self-assign */
import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TopBar from '../../../components/TopBar/NewJournal';
import { styled } from '@mui/system';
import StyledButton from '../../../components/Button/Button';
import { Typography } from '@mui/material';
import AddJournal from '../../../components/AddJournalForm/AddJournalForm';
import { useNavigate } from 'react-router-dom';
import Modal from 'src/components/Modal/Modal';
import DragDrop from 'src/components/DragDrop/DragDrop';
import { useDispatch } from 'react-redux';
import UploadJournalCoverForm from '../../../components/AddJournalForm/UploadJournalCover/UploadJournalCoverForm';
import { addNewFileSchema } from '../../../components/AddJournalForm/validation';
import {
  translateData,
  translateDataSWToEN,
} from '../../../redux/services/general';
import { useSelector } from 'react-redux';
import {
  addJournal,
  getSingleJournal,
  updateJournal,
} from '../../../redux/services/journals';
import { getAllLanguagesTags, getAllTags } from '../../../redux/services/tags';
import { ReactComponent as CoverIcon } from '../../../assets/cover-icon.svg';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import GalleryImport from 'src/assets/gallery-import.png';
import CloseIcon from 'src/assets/closeicon.svg';
import CheckPermissions from 'src/components/CheckPermissions/CheckPermissions';
import RemoveJournal from 'src/components/AddJournalForm/RemoveJournal';
import { getTag, tagsToBeTranslated, translatedTags } from 'src/helpers/tags';
import { useTranslation } from 'react-i18next';
import { setJournalDetails } from 'src/redux/actions/general';

const StyledGrid = styled(Grid)({
  height: 'calc(100vh - 125px)',
});
const PagesCount = styled('span')({
  color: '#292D32',
  fontSize: '14px',
  fontWeight: '500',
});

const RightSideWrapper = styled('div')({
  margin: '24px 22px',
  border: '1px solid #F2F2F2',
});

const UploadModalBtnWrapper = styled('div')({
  display: 'flex',
  gap: '10px',
  margin: '15px',
  alignItems: 'center',
});

const buttonStyles = {
  // minWidth: '317px',
  maxWidth: '47%',
  margin: '8px',
  background: 'white !important',
  color: '#BB8D0B !important',
};
const uploadImgModalStyles = {
  minWindth: '432px',
  minHeight: '341px',
  display: 'flex',
};
const UploadedFilesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
  width: '400px',
  minHeight: '69px',
  border: '1px solid #F2F2F2',
  '#Selected-files-details': {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    img: {
      marginLeft: '8px',
      alignSelf: 'center',
    },
    div: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      gap: '6px',
      fontWeight: '500',
      '.files-count': {
        color: '#292D32',
      },
      '.files-size': {
        color: '#7B858F',
      },
    },
  },
  '#fileDel-icon': {
    alignSelf: 'center',
    marginRight: '10px',
    cursor: 'pointer',
  },
});

function NewJournal({
  setNewFileComponent,
  journalId,
  journals,
  uploadImageModal,
  setUploadImageModal,
  handleFileUpload,
  files,
  setFiles,
  fileSize,
  uploadImages,
  setMessageObject,
  openSnackbar,
  handleSnackbar,
  messageObject,
  setOpenSnackbar,
  disableBtn,
  setJournalId,
  pagesUploaded,
  pagesCount,
  updateNewJournal,
  updateExistingJournal,
  removeFromJournalsList,
  locale,
}) {
  const [fileData, setFileData] = useState();
  const [swFields, setSwFields] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [allowsSwFields, setAllowSwFields] = useState(true);
  const [removeJournalComponent, setRemoveJournalComponent] = useState(false);
  const [fileCover, setFileCover] = useState();
  const [disabledButton, setDisabledButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [translateBtnLoading, setTranslateBtnLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(true);

  const [tagsSW, setTagsSW] = useState([]);
  const [tagsEN, setTagsEN] = useState([]);
  const [selectedTagsEN, setSelectedTagsEN] = useState([]);
  const [selectedTagsSW, setSelectedTagsSW] = useState([]);

  const [tagsOptions,setTagsOptons]=useState([]);

  // const allTags = useSelector((state) => state.general.tags?.data);
  const { allLanguagesTags } = useSelector((getState) => getState?.general);
  const { t } = useTranslation();

  useEffect(() => {
    getAllLanguagesTags(dispatch, locale);
    dispatch(getAllTags)()
      .then((res) => {})
      .catch((err) => {});
    // eslint-disable-next-line
  }, [locale]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (url) => {
    if (journalId) {
      navigate(url);
    }
  };

  const categoriesData = useSelector(
    (state) => state.allCategories.categories?.data
  );
  const userId = useSelector((state) => state.auth.user._id);

  useEffect(() => {
    if (journalId) {
      getSingleJournal(journalId, locale)
        .then(({ data }) => {
          setFileTitle(data?.title);
          console.log('data', data);
          data.fileTitle = data?.title;
          data.details = data?.subtitle;
          data.category = data?.category?._id;
          let tag = [];
          tag = data?.tags.map((ele) => {
            return ele.name;
          });
          setTagsEN(tag);
          console.log(data?.tags);
          setTagsOptons(data?.tags);
          delete data?.tags;
          setFileData((prevData) => {
            return { ...prevData, ...data };
          });
          // console.log('new data', data);
          dispatch(setJournalDetails(data));
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId, updateJournal, locale]);

  useEffect(() => {
    // console.log('fileCover');
    if (swFields === true) fileData.showSW = true;
    addNewFileSchema
      .validate(fileData)
      .then(function (value) {
        setDisabledButton(false);
      })
      .catch(function (err) {
        setDisabledButton(true);
        // console.log('validate', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData, swFields]);

  // Translate Button Func
  const handleTranslateData = async () => {
    try{
    setErrorDisplay(false);
    console.log('ENTER HERE', tagsEN);
    // let tempVar = tagsEN.map((ele) => {
    //   if (typeof ele !== 'object') {
    //     return ele;
    //   } else {
    //     return '';
    //   }
    // });
    // console.log('TEMP', tempVar);
    if (!fileData) return;
    // console.log('Inside Translated Call', fileData);
    const data = { ...fileData };
    let category;
    if (fileData.category)
      category = categoriesData.find(
        (ele) => ele._id === fileData?.category
      ).name;
    const newData = {
      contributor_sw: fileData.contributor,
      category_sw: category,
      description_sw: fileData.description,
      details_sw: fileData.details,
      year_sw: fileData.year,
      fileTitle_sw: fileData.fileTitle,
      tags: tagsToBeTranslated(tagsOptions, locale),
    };

    console.log('Before Translation', newData);

    let translatedData;
    if (locale === 'en') {
      translatedData = await translateData(newData);
    } else {
      translatedData = await translateDataSWToEN(newData);
    }

    console.log('After Translation', translatedData);
    // setTagsSW(
    //   getExistingSWTags(
    //     allLanguagesTags,
    //     translatedData?.tag_sw,
    //     tagsEN,
    //     locale
    //   )
    // );
    setTagsOptons(translatedTags(tagsOptions, translatedData?.tags, locale));

    translatedData = {
      ...data,
      ...translatedData,
    };

    delete translatedData.tag_en;
    delete translatedData.tags;
    translatedData.category_sw = translatedData.category_sw;
    console.log('FILE DATA =>', data);
    setFileData(translatedData);
    console.log('FILE DATA', translatedData);
    setSwFields(true);
  }
  catch(err){
   console.log(err);
  }
  finally{
    setTranslateBtnLoading(false);
  }
  };

  // Save Details / Add Journal Func
  const handleAddJournal = async () => {
    setLoading(true);
    const {
      fileTitle: title_en,
      description: description_en,
      details: subtitle_en,
      contributor: contributor_en,
      fileTitle_sw: title_sw,
      description_sw,
      details_sw: subtitle_sw,
      contributor_sw,
      category,
      year,
      // tag,
      tag_sw,
    } = fileData;

    let tagsFormat= tagsOptions.map((tags)=>{
      if("_id" in tags){
        return {_id: tags?._id, en: tags.name_en, sw: tags.name_sw};
      }
      else{
        return {en: tags.name_en || "", sw: tags.name_sw || ""}
      }
    })
   console.log(tagsFormat)
  
    let data;
    if (locale === 'en') {
      data = {
        title_en,
        description_en,
        subtitle_en,
        contributor_en,
        title_sw,
        description_sw,
        subtitle_sw,
        contributor_sw,
        category,
        year,
        tag: tagsFormat,
        tag_sw,
        image: fileCover,
        creator: userId,
      };
    } else {
      data = {
        title_en: title_sw,
        description_en: description_sw,
        subtitle_en: subtitle_sw,
        contributor_en: contributor_sw,
        title_sw: fileData?.fileTitle,
        description_sw: fileData?.details,
        subtitle_sw: fileData?.subtitle,
        contributor_sw: fileData?.contributor,
        category,
        year,
        tag: tagsFormat,
        tag_sw,
        image: fileCover,
        creator: userId,
      };
    }

    console.log(fileData);

    let temp = tagsEN.map((ele) => getTag(ele, locale));
    console.log('EN', temp);
    console.log('SW', tagsSW);
    // console.log('DATA', data, fileData);
    let tags = [];
    if (locale === 'en') {
      tags = temp.map((ele, index) => {
        return {
          en: ele,
          sw: tagsSW[index] || '',
        };
      });
      console.log('TAGS', tags);
    } else {
      tags = temp.map((ele, index) => {
        return {
          en: tagsSW[index] || '',
          sw: ele,
        };
      });
    }

    // data.tag.push(data.title_en);
    // const newData = {
    //   contributor_sw: data.contributor_en,
    //   description_sw: data.description_en.trim(),
    //   subtitle_sw: data.subtitle_en.trim(),
    //   title_sw: data.title_en.trim(),
    //   tag_sw: fileData.tag.map((ele) => ele.name || ele),
    // };

    // if (newData.tag_sw.length === 0) {
    //   delete newData.tag_sw;
    // }

    // let translatedData = await translateData(newData);
    // console.log('After Translation', translatedData);

    // let transformedData = {
    //   ...data,
    // };
    data.tags = tagsFormat;
    delete data.tag;
    delete data.tag_sw;
    console.log('DATA -> ', data);

    Object.keys(data).map(
      (val) =>
        (data[val] =
          typeof data[val] == 'string' ? data[val].trim() : data[val])
    );
    // // Add journal call
    if (!journalId) {
      // eslint-disable-next-line no-unused-vars
      const res = await addJournal(data, locale)
        .then((res) => {
          // console.log(res?.data?._id);
          //Upload Pages Before General Creation
          if (pagesUploaded) {
            uploadImages(files, res?.data?._id);
          }

          // setJournalId(res?.data?._id);
          setFileData();
          setFileCover();
          setLoading(false);
          setMessageObject({
            text: t('Journal added successfully'),
            severity: 'success',
          });
          setNewFileComponent(false);
          // setJournalId();
          setOpenSnackbar(true);
          // add new journal here
          updateNewJournal(res?.data);
          dispatch(setJournalDetails(res?.data));
        })
        .catch((err) => {
          // console.log(response?.data);
          setMessageObject({
            text: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.data,
            severity: 'error',
          });
          setLoading(false);
          setOpenSnackbar(true);
        });
    } else {
      updateJournal(journalId, data, locale)
        .then((res) => {
          // setFileData();
          // setFileCover();
          setMessageObject({
            text: t('Journal Updated.'),
            severity: 'success',
          });
          setOpenSnackbar(true);
          console.log(res?.data);
          updateExistingJournal(res?.data, res?.data?._id);
          // dispatch(getAllTags).then((res) => {});
          dispatch(setJournalDetails(res?.data));
        })
        .catch((err) => {
          dispatch(getAllTags);
          setMessageObject({
            text: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.data,
            severity: 'error',
          });
          setLoading(false);
          setOpenSnackbar(true);
        });
    }
  };
  return (
    <div>
      {uploadImageModal ? (
        <Modal
          title={t('Upload and attach file')}
          open={uploadImageModal}
          handleClose={() => setUploadImageModal(false)}
          style={
            files
              ? { ...uploadImgModalStyles, minHeight: '209px' }
              : { ...uploadImgModalStyles }
          }
          children={
            <>
              {!files ? (
                <DragDrop handleFileUpload={handleFileUpload} />
              ) : (
                <UploadedFilesWrapper>
                  <div id='Selected-files-details'>
                    <img
                      src={GalleryImport}
                      alt=''
                      width='32px'
                      height='32px'
                    />
                    <div>
                      <p className='files-count'>
                        {files?.length} Pages selected
                      </p>
                      <p className='files-size'>{fileSize}</p>
                    </div>
                  </div>
                  <div id='fileDel-icon' onClick={() => setFiles(null)}>
                    <img alt='' src={CloseIcon} />
                  </div>
                </UploadedFilesWrapper>
              )}
              <UploadModalBtnWrapper>
                <StyledButton
                  secondary='true'
                  onClick={() => setUploadImageModal(false)}
                >
                  {t(`Cancel`)}
                </StyledButton>
                <StyledButton
                  secondary
                  disable={disableBtn || !files}
                  onClick={() => uploadImages(files, journalId)}
                >
                  {t(`Upload`)}
                </StyledButton>
              </UploadModalBtnWrapper>
            </>
          }
        />
      ) : null}{' '}
      <TopBar
        fileData={fileData}
        journalId={journalId}
        setJournalId={setJournalId}
        // onClick={handleClick}
        setAllowSwFields={setAllowSwFields}
        disabledButton={disabledButton}
        handleTranslateData={handleTranslateData}
        handleAddJournal={handleAddJournal}
        setLoading={setLoading}
        setTranslateBtnLoading={setTranslateBtnLoading}
        translateBtnLoading={translateBtnLoading}
        loading={loading}
        setNewFileComponent={setNewFileComponent}
        tagsSW={tagsSW}
        tagsEN={tagsEN}
        swFields={swFields}
        setRemoveJournalComponent={setRemoveJournalComponent}
      />
      <StyledGrid container>
        <Grid
          item
          xs={6}
          sx={{ p: 2, height: 'calc(100vh - 125px)', overflow: 'auto' }}
        >
          <AddJournal
            setFileData={setFileData}
            fileData={fileData}
            swFields={swFields}
            allTags={allLanguagesTags}
            setErrorDisplay={setErrorDisplay}
            errorDisplay={errorDisplay}
            tagsEN={tagsEN}
            tagsSW={tagsSW}
            setTagsEN={setTagsEN}
            setTagsSW={setTagsSW}
            selectedTagsEN={selectedTagsEN}
            setSelectedTagsEN={setSelectedTagsEN}
            setSelectedTagsSW={setSelectedTagsSW}
            selectedTagsSW={selectedTagsSW}
            tagsOptions={tagsOptions}
            setTagsOptons={setTagsOptons}
            language={locale}
          />
        </Grid>
        <Grid item xs={6}>
          <RightSideWrapper>
            <Grid
              item
              container
              justifyContent='space-between'
              xs={12}
              sx={{ p: 2 }}
            >
              <Typography
                align='left'
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  fontFamily: 'Open Sans',
                  color: '#292D32',
                }}
              >
                {t(`Pages`)}
              </Typography>
              <Typography
                align='left'
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '24px',
                  color: ' #7B858F',
                  fontFamily: 'Open Sans',
                }}
              >
                {t(`Total Pages`)} <PagesCount>{pagesCount}</PagesCount>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: '477px', background: '#F2F2F2' }}>
              <UploadJournalCoverForm
                setFileCover={setFileCover}
                open={open}
                setOpen={setOpen}
                fileCover={fileCover}
                fileData={fileData}
                setMessageObject={setMessageObject}
                setOpenSnackbar={setOpenSnackbar}
              />
              {!fileData?.image && (
                <div
                  style={{ height: '100%', width: '100%' }}
                  onClick={() => handleNavigation(`/files/${journalId}`)}
                >
                  <CoverIcon
                    style={{
                      display: fileCover ? 'none' : 'flex',
                      margin: 'auto',
                      height: '100%',
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ p: 1 }}
              container
              justifyContent='space-between'
            >
              <CheckPermissions
                requiredPermission={
                  journalId
                    ? ['634d3737298b7ce5da21b6a4']
                    : ['634d371c298b7ce5da21b6a1']
                }
              >
                <StyledButton
                  secondary='true'
                  style={buttonStyles}
                  // disable={!journalId && true}
                  onClick={() => setUploadImageModal(true)}
                >
                  {t(`Upload Page`)}
                </StyledButton>
              </CheckPermissions>
              <StyledButton
                style={buttonStyles}
                disable={!journalId}
                onClick={() => {
                  handleNavigation(`/files/${journalId}`);
                }}
                secondary='true'
                // disable={!journalId && true}
              >
                {t(`View All Pages`)}
              </StyledButton>
            </Grid>
          </RightSideWrapper>
        </Grid>
      </StyledGrid>
      {openSnackbar ? (
        <StyledSnackbar
          handleClose={handleSnackbar}
          open={openSnackbar}
          messageObject={messageObject}
        />
      ) : null}
      {removeJournalComponent && (
        <Modal
          title='Remove Journal'
          open={removeJournalComponent}
          handleClose={() => setRemoveJournalComponent(false)}
        >
          <RemoveJournal
            journalId={journalId}
            journals={journals}
            fileTitle={fileTitle}
            removeFromJournalsList={removeFromJournalsList}
            setNewFileComponent={setNewFileComponent}
            handleClose={() => setRemoveJournalComponent(false)}
            setMessageObject={setMessageObject}
            setOpenSnackbar={setOpenSnackbar}
          />
        </Modal>
      )}
    </div>
  );
}

export default NewJournal;
