import { Box } from '@mui/material';
import React from 'react';
import StyledButton from '../Button/Button';
import { ReactComponent as AddIcon } from '../../assets/add.svg';
import CheckPermissions from '../CheckPermissions/CheckPermissions';
import { useTranslation } from "react-i18next";

function CategoryTopRightBar({ handleClickOpen }) {
  const {t}=useTranslation();
  return (
    <>
      <CheckPermissions requiredPermission={['634fafba9b08601a8fff9b41']}>
        <Box style={{ width: '142px', height: '36px', marginLeft: '16px' }}>
          <StyledButton iconButton={true} onClick={handleClickOpen}>
            <AddIcon /> {t(`New Category`)}
          </StyledButton>
        </Box>
      </CheckPermissions>
    </>
  );
}

export default CategoryTopRightBar;
