import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DefaultComponent from '../../components/DefaultComponent/DefaultComponent';
import Category from '../../containers/Category/Category';
import Dashboard from '../../containers/Dashboard/Dashboard';
import Journals from '../../containers/Journals/Journals';
import Settings from '../../containers/Settings/Settings';
import Users from '../../containers/Users/Users';
import UploadPage from 'src/containers/Journals/UploadPage/UploadPage';
import { progressBarHTML } from 'src/helpers/fileprogress';
import AddJournalContainer from 'src/containers/Journals/AddJournal/AddJournal';
import EditJournalContainer from 'src/containers/Journals/EditJournal/EditJournal';
import PublicSite from 'src/containers/PublicSite/PublicSite';

function Main() {
  const [minimize, setMinimize] = useState(false);

  return (
    <div>
      {progressBarHTML(setMinimize, minimize)}
      <Router>
        <Routes>
          <Route path='/users' element={<Users />} />
          <Route path='/category' element={<Category />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/public-site' element={<PublicSite />} />
          <Route path='/files' element={<Journals />} />
          <Route path='/files/add' element={<AddJournalContainer />} />
          <Route path='/files/:id/edit' element={<EditJournalContainer />} />
          <Route path='*' element={<DefaultComponent />} />
          <Route path='/files/:id' element={<UploadPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default Main;
