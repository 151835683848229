import * as React from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as CheckedIcon } from '../../assets/checkedIcon.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/uncheckedIcon.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
const CustomCheckbox = styled(Checkbox)({
  background: 'white',
  boxSizing: 'border-box',
  fontFamily: 'Open Sans',
  '& .MuiTypography-root': {
    fontFamily: 'Open Sans !important',
  },
  '&.MuiFormControlLabel-label': {
    fontFamily: 'Open Sans !important',
  },
  // '&.Mui-checked': {
  //   '&, & + .MuiFormControlLabel-label': {
  //     color: '#BB8D0B',
  //   },
  //   backgroundColor: '#F6EEE0',
  // },
});
const CheckboxLabel = styled(FormControlLabel)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  fontFamily: 'Open Sans !important',
  border: '1px solid #D9DEE2',
  color: '#7B858F',
  marginLeft: '0px',
  boxSizing: 'border-box',
  '& .MuiTypography-root': {
    fontFamily: 'Open Sans !important',
    fontSize: '14px',
  },
});
export default function StyledCheckbox({
  isChecked,
  label,
  onChange,
  ...props
}) {
  // console.log('IS CHECKED', isChecked);
  return (
    <CheckboxLabel
      label={label}
      sx={{
        backgroundColor: isChecked ? '#F6EEE0' : null,
        border: isChecked ? '1px solid #F6EEE0 !important' : null,
        color: isChecked ? '#BB8D0B !important' : null,
        fontFamily: 'Open Sans',
      }}
      control={
        <CustomCheckbox
          sx={{
            backgroundColor: isChecked ? '#F6EEE0 !important' : null,
            fontFamily: 'Open Sans',
          }}
          value={isChecked}
          onChange={onChange}
          icon={isChecked ? <CheckedIcon /> : <UncheckedIcon />}
          checkedIcon={isChecked ? <CheckedIcon /> : <UncheckedIcon />}
          {...props}
        />
      }
    />
  );
}
