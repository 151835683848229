import * as yup from 'yup';

const emailRe =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const userSignInSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('This field is required.')
    .matches(emailRe, 'Email is Invalid'),
  password: yup
    .string()
    .trim()
    .min(6, 'Password must be at least 6 characters.')
    .required('This field is required.'),
});
