import * as yup from 'yup';

export const InviteUserFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email.')
    .trim()
    .required('This field is required.'),
  permissions: yup.array().min(1).of(yup.string().required()).required(),
});
