import { Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import StyledButton from '../Button/Button';

function UnsavedChangesWarning({
  handleSaveChanges,
  handleDiscardChanges,
  loading,
}) {
  return (
    <div
      style={{
        width: '405px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <WarningIcon style={{ marginBottom: '24px' }} />
      <Typography
        align='center'
        fontFamily='Open Sans'
        sx={{
          fontSize: '14px',
          lineHeight: '24px',
          marginBottom: '24px',
          fontWeight: '500',
        }}
      >
        You are about to leave this page without saving. All changes will be
        lost. Do you really want to leave without saving?
      </Typography>
      <div
        style={{
          display: 'flex',
          width: '405px',
          justifyContent: 'space-between',
        }}
      >
        <StyledButton
          btnvariant='true'
          secondary='true'
          style={{ width: '196px' }}
          onClick={handleDiscardChanges}
          // loading={loading}
        >
          Leave without saving
        </StyledButton>

        <StyledButton
          style={{ width: '196px' }}
          btnvariant='true'
          onClick={handleSaveChanges}
          loading={loading}
        >
          Save changes
        </StyledButton>
      </div>
    </div>
  );
}

export default UnsavedChangesWarning;
