import * as yup from 'yup';


export const addCategorySchema = yup.object().shape({
  category: yup
    .string()
    .trim()
    .max(120, 'Category name cannot be greater than 120 characters.')
    .required('This field is required.'),
  description: yup
    .string()
    .trim()
    .max(5000, 'Category description cannot be greater than 5000 characters.'),
  fileName: yup.mixed().required('You need to provide a file'),
  // .test(2500, 'File Size is too large', (value) => value.size <= 2500),
});
