import { React } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import { ReactComponent as HomeIcon } from '../../assets/homeicon.svg';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { ReactComponent as PublicSiteIcon } from '../../assets/publicSite.svg';
import { ReactComponent as FilesIcon } from '../../assets/files.svg';
import { ReactComponent as CategoryIcon } from '../../assets/category.svg';
import { ReactComponent as SettingsIcon } from '../../assets/setting.svg';
import { ReactComponent as Collapse } from '../../assets/collapse.svg';
import { ReactComponent as Logout } from '../../assets/logout.svg';
import { Typography } from '@mui/material';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { setDrawer } from '../../redux/actions/general';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const StyledListItem = styled(ListItem)({
  ':hover': { background: 'none' },
});
const StyledListItemButton = styled(ListItemButton)({
  minHeight: 48,
  padding: '0px !important',
  margin: '0pc 10px !important',
  marginBottom: '5px !important',
  borderRadius: '4px',
  ':hover': { background: 'none' },
  '&.Mui-selected': {
    background: 'none',
  },
  '&.Mui-selected:hover': {
    background: 'none',
  },
  '&.Mui-selected .MuiListItemIcon-root': {
    background: '#F6EEE0',
  },
});
const StyledListItemIcon = styled(ListItemIcon)({
  padding: '10px',
  borderRadius: '4px',
  marginRight: '0px !important',
  ':hover': {
    background: '#F6EEE0',
  },
});
const StyledListItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontSize: '14px !important',
    fontFamily: 'Open Sans',
    fontWeight: '500',
    color: '#292D32',
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '56px',
      paddingBottom: '50px !important',
      boxSizing: 'border-box',
      borderRight: '1px solid #F2F2F2',
      '& .MuiList-root .MuiListItem-root .MuiButtonBase-root:hover': {
        background: '#F6EEE0',
      },
      '& .MuiList-root .MuiListItem-root .Mui-selected': {
        background: '#F6EEE0',
      },
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '62px',
      alignContent: 'flex-start',
      marginTop: '56px',
      paddingBottom: '34px !important',
      boxSizing: 'border-box',
      borderRight: '1px solid #F2F2F2',
    },
  }),
}));

const sidebarData = [
  // {
  //   id: 1,
  //   text: 'Home',
  //   icon: <HomeIcon />,
  //   route: '/dashboard',
  // },
  {
    id: 2,
    text: 'Files',
    icon: <FilesIcon />,
    route: '/files',
  },
  {
    id: 3,
    text: 'Category',
    icon: <CategoryIcon />,
    route: '/category',
  },
  {
    id: 4,
    text: 'Users',
    icon: <UserIcon />,
    route: '/users',
  },
  {
    id: 5,
    text: 'Public Site',
    icon: <PublicSiteIcon />,
    route: '/public-site',
  },
  {
    id: 6,
    text: 'Settings',
    icon: <SettingsIcon />,
    route: '/settings',
  },
];
export default function MiniDrawer() {
  const location = useLocation();
  // let currentRoute = location.pathname;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { drawer } = useSelector((state) => state.general);
  const navigate = useCustomNavigate();
  const toggleDrawer = () => {
    dispatch(setDrawer(!drawer));
  };
  const activeRoute = (routeName, currentRoute) => routeName === currentRoute;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Drawer variant='permanent' open={drawer}>
        <List sx={{ width: '170px' }}>
          {sidebarData.map((ele, index) => (
            <StyledListItem
              key={index}
              disablePadding
              disableRipple
              button
              // component={Link}
              onClick={() => {
                navigate(ele.route);
              }}
              to={ele.route}
              sx={{ display: 'block' }}
            >
              <StyledListItemButton
                disableRipple
                selected={activeRoute(ele.route, location?.pathname)}
                sx={{
                  justifyContent: drawer ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                {drawer === false ? (
                  <Tooltip title={ele.text} arrow placement='left'>
                    <StyledListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawer ? 3 : 'auto',
                        justifyContent: 'center',
                        // backgroundColor: '#F6EEE0',
                      }}
                    >
                      {ele.icon}
                    </StyledListItemIcon>
                  </Tooltip>
                ) : (
                  <StyledListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawer ? 3 : 'auto',
                      justifyContent: 'center',
                      // backgroundColor: '#F6EEE0',
                    }}
                  >
                    {ele.icon}
                  </StyledListItemIcon>
                )}
                {/* <Tooltip title={ele.text} arrow placement='left'>
                  <StyledListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawer ? 3 : 'auto',
                      justifyContent: 'center',
                      // backgroundColor: '#F6EEE0',
                    }}
                  >
                    {ele.icon}
                  </StyledListItemIcon>
                </Tooltip> */}
                <StyledListItemText
                  primary={t(ele.text)}
                  sx={{ opacity: drawer ? 1 : 0 }}
                />
              </StyledListItemButton>
            </StyledListItem>
          ))}
        </List>

        <DrawerFooter sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          {drawer ? (
            <IconButton
              sx={{ height: '80px' }}
              disableRipple
              onClick={toggleDrawer}
            >
              {theme.direction === 'rtl' ? <Collapse /> : <Collapse />}
              <Typography
                variant='subtitle1'
                align='left'
                fontFamily='Open Sans'
                sx={{ fontSize: '14px', ml: 1, color: '#292D32' }}
              >
                Collapse
              </Typography>
            </IconButton>
          ) : (
            <IconButton
              disableRipple
              sx={{ height: '80px' }}
              onClick={toggleDrawer}
              // sx={{ marginBottom: '-30px' }}
            >
              {theme.direction === 'rtl' ? <Logout /> : <Logout />}
            </IconButton>
          )}
        </DrawerFooter>
      </Drawer>
    </Box>
  );
}
