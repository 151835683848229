import React, { useEffect } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../redux/services/category';
import { useTranslation } from 'react-i18next';

const SelectBox = styled(Select)({
  width: '230px',
  // width: (props) => (props.value ? '100%' : '230px'),
  height: '36px !important',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  borderRadius: '0px !important',
  border: (props) =>
    props.errorclass ? '1px solid #B1484E' : '1px solid #D9DEE2',
  padding: '0px !important',
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& svg': {
    width: '40px',
    position: 'absolute',
    right: '8px',
    top: '8px',
    cursor: 'pointer',
  },
});
const StyledMenuItem = styled(MenuItem)({
  fontSize: '14px',
  padding: '6px 12px',
  fontFamily: 'Open Sans',
  color: '#7B858F',
  '&.MuiButtonBase-root:not(:last-child)': {
    borderBottom: '1px solid #D9DEE2',
  },
  '&.MuiButtonBase-root:hover': {
    background: 'none',
    color: '#BB8D0B',
  },
  '&.MuiButtonBase-root.Mui-selected': {
    background: 'none',
    color: '#BB8D0B',
  },
  '&.MuiButtonBase-root:has(> .MuiList-root)': {
    padding: '0px',
  },
  '&.MuiList-root-MuiMenu-list': {
    padding: '18px',
  },
});

function StyledSelect({
  selectedOption,
  handleChange,
  value,
  secondary,
  name,
  errorclass,
  defaultVal,
  style,
  ...props
}) {
  const categoriesData = useSelector(
    (state) => state.allCategories.categories?.data
  );
  const { locale } = useSelector((gState) => gState?.general);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(() => getAllCategories(dispatch, locale))()
      .then((res) => {})
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);

  return (
    <SelectBox
      style={style}
      errorclass={errorclass}
      fullWidth
      {...props}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {
          style: {
            padding: 0,
          },
        },
        PaperProps: {
          style: {
            // minWidth: 228,
            // minWidth: style ? 'auto' : 228,
            // marginLeft: 14,
            maxHeight: '300px',
            boxShadow: 'none',
            border: '1px solid #D9DEE2',
            borderRadius: 0,
            top: '116px',
            left: '637px',
            padding: '0px !important',
          },
        },
      }}
      defaultValue={defaultVal}
      value={value ? value : selectedOption}
      onChange={handleChange}
      label='Select'
      name={name}
      IconComponent={(props) => <ArrowDownIcon {...props} />}
    >
      {!name && (
        <StyledMenuItem value={'everything'}>{t('Everything')}</StyledMenuItem>
      )}
      {categoriesData
        ? categoriesData.map((ele, index) =>
            secondary ? (
              <StyledMenuItem key={index} value={ele._id}>
                {locale === 'en'
                  ? ele?.name_en?.length > 65
                    ? ele?.name_en.slice(0, 65) + '...'
                    : ele?.name_en
                  : ele?.name_sw?.length > 65
                  ? ele?.name_sw.slice(0, 65) + '...'
                  : ele?.name_sw}
              </StyledMenuItem>
            ) : (
              <StyledMenuItem key={index} value={ele._id}>
                {locale === 'en'
                  ? ele?.name_en?.length > 26
                    ? ele?.name_en.slice(0, 26) + '...'
                    : ele?.name_en
                  : ele?.name_sw?.length > 26
                  ? ele?.name_sw.slice(0, 26) + '...'
                  : ele?.name_sw}
              </StyledMenuItem>
            )
          )
        : null}
    </SelectBox>
  );
}

export default StyledSelect;
