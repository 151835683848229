/* eslint-disable array-callback-return */
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import StyledInput from '../Input/Input';
import StyledTextArea from '../Input/TextArea';
import { ReactComponent as InfoIcon } from '../../assets/info-circle.svg';
import { useFormik } from 'formik';
import { addHeroSectionSchema } from './validation';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Spacer = styled('div')({
  height: '24px',
});
const IconWrapper = styled(InfoIcon)({
  marginRight: '2px',
  // display: 'flex',
});
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  marginBottom: '8px',
  lineHeight: '24px',
});
function AddHeroSectionForm({ swFields, setFileData, fileData }) {
  const { t } = useTranslation();
  const { locale } = useSelector((state) => state.general);
  const initialValues = {
    pb_title_sw: '',
    pb_title_en: '',
    pb_subtitle_en: '',
    pb_subtitle_sw: '',
  };
  const FormFields = [
    {
      id: '1',
      fieldName: 'Hero Title',
      field: 'text-input',
      placeholder: 'Enter file title',
      key: locale === 'en' ? 'pb_title_en' : 'pb_title_sw',
      isRequired: true,
    },
    {
      id: '2',
      fieldName: 'Hero Title',
      field: 'text-input',
      placeholder: 'Enter file title',
      key: locale === 'en' ? 'pb_title_sw' : 'pb_title_en',
      issw: true,
    },
    {
      id: '3',
      fieldName: 'Hero Sub-Heading',
      field: 'text-area',
      isRequired: true,
      placeholder: 'Enter sub-heading for public site',
      key: locale === 'en' ? 'pb_subtitle_en' : 'pb_subtitle_sw',
    },
    {
      id: '4',
      fieldName: 'Hero Sub-Heading',
      field: 'text-area',
      placeholder: 'Enter sub-heading for public site',
      key: locale === 'en' ? 'pb_subtitle_sw' : 'pb_subtitle_en',
      issw: true,
    },
  ];
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    // setFieldValue,
    handleSubmit,
    // setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addHeroSectionSchema,
    // validateOnChange: true,
    onSubmit: (values) => {
      console.log('Values', values);
    },
    validate: (values) => {
      setFileData(values);
      // setErrors(false);
      // console.log('Values', values);
    },
  });

  useEffect(() => {
    // console.log('ugh');
    // console.log(tagsList(allTags));
    if (fileData) setValues(fileData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        {FormFields?.map((ele, index) => {
          if (ele.issw) if (!swFields) return;
          return (
            <Box
              key={ele.id}
              swFields={swFields}
              issw={ele.issw}
              sx={!swFields ? { mb: 2 } : { mb: 0 }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {ele.issw !== true ? (
                  <Typography
                    variant='subtitle1'
                    align='left'
                    fontFamily='Open Sans'
                    sx={{
                      fontSize: '12px',
                      lineHeight: '24px',
                      marginBottom: '4px',
                      fontWeight: '600',
                    }}
                  >
                    {t(ele.fieldName)}
                    {ele.isRequired ? (
                      <sup style={{ color: '#B1484E' }}>*</sup>
                    ) : null}
                  </Typography>
                ) : null}
                {ele.issw !== true ? (
                  <Typography
                    variant='subtitle1'
                    align='right'
                    display='flex'
                    alignItems='center'
                    fontFamily='Open Sans'
                    sx={{
                      fontSize: '12px',
                      lineHeight: '24px',
                      marginBottom: '4px',
                      fontWeight: '400',
                      color: '#7B858F',
                    }}
                  >
                    <IconWrapper />
                    Max {ele.field === 'text-input' ? '60' : '600'}{' '}
                    {t(`characters`)}
                  </Typography>
                ) : null}
              </Box>
              {ele.field === 'text-area' ? (
                <StyledTextArea
                  placeholder={ele.placeholder}
                  type='text'
                  name={ele.key}
                  onBlur={handleBlur}
                  value={values[ele.key]}
                  errorclass={
                    errors[ele.key] && touched[ele.key] ? 'errorMessage' : null
                  }
                  onChange={handleChange}
                  style={{
                    marginTop: ele.issw ? '-4.5px' : '0px',
                  }}
                />
              ) : (
                <StyledInput
                  placeholder={ele.placeholder}
                  type='text'
                  variant3='true'
                  onChange={handleChange}
                  secondary='true'
                  name={ele.key}
                  onBlur={handleBlur}
                  value={values[ele.key]}
                  errorclass={
                    errors[ele.key] && touched[ele.key] ? 'errorMessage' : null
                  }
                  style={{ marginBottom: '0px !important', fontSize: '14px' }}
                />
              )}
              {ele.issw ? <Spacer /> : ''}
              {/* <Spacer /> */}
              {errors[ele.key] && touched[ele.key] ? (
                <ErrorMessage>{t(errors[ele.key])}</ErrorMessage>
              ) : null}
            </Box>
          );
        })}
      </Grid>
    </form>
  );
}

export default AddHeroSectionForm;
