import { Typography, Grid, Box } from '@mui/material';
import React, { useState } from 'react';
import StyledButton from '../Button/Button';
import { styled } from '@mui/system';
import StyledInput from '../Input/Input';
import { Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../../assets/unlock.svg';
import { useFormik } from 'formik';
import { userSignInSchema } from './validation';
import { useNavigate } from 'react-router-dom';
import { userLogin } from '../../redux/services/auth';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../redux/actions/auth';
import { useTranslation } from "react-i18next";

const LinkContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '12px',
  '& a': {
    color: '#BB8D0B',
    textDecorationColor: '#BB8D0B',
    fontSize: '14px',
  },
});
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  // marginTop: '5px',
  // fontSize: '12px',
  lineHeight: '24px',
  minHeight: '24px',
});

const SignInFormFields = [
  {
    id: '1',
    fieldName: 'Email Address',
    placeholder: 'Email',
    type: 'email',
    adornment: false,
    key: 'email',
  },
  {
    id: '2',
    fieldName: 'Password',
    placeholder: 'Password',
    adornment: true,
    key: 'password',
  },
];

const initialValues = {
  email: '',
  password: '',
};
function SignInForm({ title, subtitle }) {
  const [toggleInputType, setToggleInputType] = useState('password');
  // const [formData, setFormData] = useState(null);
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [message, setMessage] = useState(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {t}=useTranslation();
  const [loading, setLoading] = useState(false);
  const onSubmit = (values, setErrors) => {
    setLoading(true);
    // setFormData(values);
    dispatch(userLogin)(values)
      .then((res) => {
        let receivedTime = new Date();
        dispatch(setAuthenticated('authenticated'));
        Navigate('/files');
        localStorage.setItem('token', res?.data?.token);
        localStorage.setItem('refreshToken', res?.data?.user?.refreshToken);
        localStorage.setItem('time', JSON.stringify(receivedTime));
        setLoading(false);
      })
      .catch((err) => {
        // setShowErrorMessage(true);
        dispatch(setAuthenticated('unauthenticated'));
        // setMessage(err?.response?.data.message);
        if (err?.response?.data.message.includes('Password')) {
          setErrors({
            password:
              err?.response?.data?.message?.charAt(0).toUpperCase() +
              err?.response?.data?.message?.slice(1),
          });
        } else {
          setErrors({
            email:
              err?.response?.data?.message?.charAt(0).toUpperCase() +
              err?.response?.data?.message?.slice(1),
          });
        }
        setLoading(false);
      });
  };

  const { values, errors, touched, handleChange, handleSubmit, setErrors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userSignInSchema,
      onSubmit: (e) => onSubmit(e, setErrors),
    });
  const setInputAdornment = () => {
    return (
      <InputAdornment position='end'>
        {toggleInputType === 'password' ? (
          <LockIcon onClick={() => setToggleInputType('text')} />
        ) : (
          <UnlockIcon onClick={() => setToggleInputType('password')} />
        )}
      </InputAdornment>
    );
  };

  return (
    <Grid container item xs={12}>
      <Grid item sx={{ mb: '36px' }} xs={12}>
        <Typography
          variant='h1'
          align='left'
          sx={{
            fontSize: '36px',
            mb: '8px',
            letterSpacing: '1.5px',
            fontFamily: 'brandon-grotesque',
            fontWeight: '420',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='subtitle1'
          align='left'
          sx={{ fontSize: '14px', fontFamily: 'Open Sans' }}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} noValidate={true}>
          <Box sx={{ mb: '12px' }}>
            {SignInFormFields.map((ele) => {
              return (
                <Box key={ele.id} sx={{ marginBottom: '12px' }}>
                  <Typography
                    variant='subtitle1'
                    align='left'
                    sx={{
                      fontSize: '14px',
                      mb: '4px',
                      fontFamily: 'Open Sans',
                      color: '#292D32',
                      fontWeight: '600',
                    }}
                  >
                    {t(ele.fieldName)}
                  </Typography>
                  <StyledInput
                    required
                    style={{ marginBottom: '0px' }}
                    placeholder={t(ele.placeholder)}
                    type={ele.adornment ? toggleInputType : ele.type}
                    name={ele.key}
                    value={values.name}
                    onChange={handleChange}
                    errorclass={
                      errors[ele.key] && touched[ele.key]
                        ? 'errorMessage'
                        : null
                    }
                    InputProps={{
                      endAdornment: ele.adornment ? setInputAdornment() : null,
                    }}
                  />

                  <ErrorMessage>
                    {errors[ele.key] && touched[ele.key]
                      ? t(errors[ele.key])
                      : null}
                  </ErrorMessage>
                </Box>
              );
            })}
          </Box>
          <StyledButton loading={loading} type='submit'>
            {t(`SIGN IN`)}
          </StyledButton>
        </form>
        <LinkContainer>
          <Link to='/forgot-password'>{t(`Forgot password?`)}</Link>
        </LinkContainer>
      </Grid>
    </Grid>
  );
}

export default SignInForm;
