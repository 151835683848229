

export const imgFileNameConvention = (fileName) => {
  return (
    fileName
      .split(' ')
      .join('-')
      // eslint-disable-next-line
      .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
  );
};

export function formatBytes(bytes, decimals) {
  if (bytes === 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getImageLinkForResolution = (link, resolution) => {
  if (!link) return;
  const linkChunks = link?.split('/');
  let imgName = linkChunks[linkChunks.length - 1];
  const imgNameChunks = imgName?.split('-');
  imgNameChunks[0] = resolution || imgNameChunks[0];
  imgName = imgNameChunks.join('-');
  linkChunks[linkChunks.length - 1] = imgName;
  return linkChunks.join('/');
};

export const signout = () => {};

export const getToken = () => {
  return localStorage.getItem('token');
};
export const getRefreshToken = () => {
  return JSON.parse(localStorage.getItem('refreshToken'));
};

// export const isTokenExpired = () => {
//   let time = JSON.parse(localStorage.getItem('time'));
//   time = new Date(time);
//   let currentTime = new Date();
//   let msBetweenDates = Math.abs(time.getTime() - currentTime.getTime());
//   let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
//   if (hoursBetweenDates > 24) {
//     return true;
//   } else {
//     return false;
//   }
// };

// export const filterTags = (fileData) => {
//   console.log('Filter Tags Func');
//   getAllTags()
//     .then(({ data }) => {
//       let filteredTags = data?.filter((ele1) =>
//         fileData?.tags?.map((ele2) => ele2._id).includes(ele1._id)
//       );

//       console.log('Filtered tags', filteredTags);
//     })
//     .catch((err) => console.log(err));
// };

export const tagsList = (allTags,language) => {
  let tags=[]
  console.log(language)
  if(language === "en"){
  allTags?.forEach((ele) => {
    if (ele?.hidden) {
      let tag = {
        _id: ele?._id,
        name: ele?.name_en,
        name_en: ele?.name_en,
        name_sw: ele?.name_sw,
      };
      tags.push(tag);
    }
  });
}
else{
  allTags?.forEach((ele) => {
    if (ele?.hidden) {
      let tag = {
        _id: ele._id,
        name: ele.name_sw,
        name_en: ele?.name_en,
        name_sw: ele?.name_sw,
      };
      tags.push(tag);
    }
  });
 }
  return tags;
};

export const validateFileUpload = (allowedFormat, fileName) => {
  console.log(allowedFormat, fileName);
  let allowed_extensions = new Array(...allowedFormat);
  let file_extension = fileName.split('.').pop().toLowerCase();

  for (var i = 0; i <= allowed_extensions.length; i++) {
    if (allowed_extensions[i] === file_extension) {
      return true; // valid file extension
    }
  }

  return false;
};

export const switchKey = (key,locale)=>{
  if(locale === "en"){
  // if(key === 'title_en'){
  //   return 'fileTitle';
  // }
  // else if(key === 'title_sw'){
  //   return 'fileTitle_sw'
  // }
  if(key === 'description_en'){
    return 'description';
  }
  else if(key === 'contributor_en'){
    return 'contributor'
  }
  return key;
}
else{
  // if(key === 'title_sw'){
  //   return 'fileTitle';
  // }
  // else if(key === 'title_en'){
  //   return 'fileTitle_sw'
  // }
  if(key === 'description_sw'){
    return 'description';
  }
  else if(key === 'contributor_sw'){
    return 'contributor'
  }
  // else if(key === 'details'){
  //   return 'subtitle_sw';
  // }
  return key;
}
}

export const journalFieldsToBeTranslated=(updatedData,existingData,locale)=>{
  console.log(updatedData)
  console.log(existingData);
  console.log(locale)
  let data={};
  if(locale === "en"){
  updatedData.description_en=updatedData?.description;
  updatedData.contributor_en=updatedData?.contributor;
  for(let key in existingData){
    if(['contributor_en','category_en','description_en','details','fileTitle','subtitle_en'].includes(key) && existingData?.[key] !== updatedData[switchKey(key,locale)]){
      console.log(key+"=>"+existingData[key], updatedData[switchKey(key,locale)]);
      if(key === 'fileTitle'){
        data['title_en']=updatedData[switchKey(key,locale)];
      }
      else{
       data[key]=updatedData[switchKey(key,locale)];
       }
    }
  }
}
else{
  for(let key in existingData){
    if(['contributor_sw','category_sw','description_sw','details','fileTitle'].includes(key) && existingData?.[key] !== updatedData?.[switchKey(key,locale)]){
      console.log(key+"=>"+existingData[key], updatedData[switchKey(key,locale)]);
      if(key === 'fileTitle'){
        data['title_sw']=updatedData[switchKey(key,locale)];
      }
      if(key === 'details'){
        data['details_sw']=updatedData[switchKey(key,locale)];
      }
      else{
       data[key]=updatedData[switchKey(key,locale)];
      }
    }
  }
}

 console.log(data);

 return data;
  
}


export const translatedJournalFormat=(data,journalDetails,locale)=>{
  console.log(data);
  console.log(journalDetails);
  let res = data;
  //Source changed:Added translated data, else no change 
  
  if (locale === "en") {
    //Translated Key with empty data
    if ("contributor_en" in res && res?.["contributor_en"] === "") {
      res["contributor_sw"] = "";
    } else {
      res["contributor_sw"] =
        res?.["contributor_en"] || journalDetails?.["contributor_sw"];
    }
    // res["category_sw"] = category?.name;
    if ("title_en" in res && res?.["title_en"] === "") {
      res["fileTitle_sw"] = "";
    } else {
      res["fileTitle_sw"] =
        res?.["title_en"] ||
        journalDetails?.["fileTitle_sw"] ||
        journalDetails?.["title_sw"];
    }
    if ("description_en" in res && res?.["description_en"] === "") {
      res["description_sw"] = "";
    } else {
      res["description_sw"] =
        res?.["description_en"] || journalDetails?.["description_sw"];
    }
    if ("details" in res && res?.["details"] === "") {
      res["details_sw"] = "";
    } else {
      res["details_sw"] =
        res?.["details"] ||
        journalDetails?.["details_sw"] ||
        journalDetails?.["subtitle_sw"];
    }
    res["year_sw"] = journalDetails?.["year"];

    delete res?.["contributor_en"];
    delete res?.["title_en"];
    delete res?.["description_en"];
    delete res?.["details"];
  } else {
    console.log(res);
    res["contributor_sw"] =
      res?.["contributor_sw"] || journalDetails?.["contributor_en"];
    // res["category_sw"] = category?.name;
    res["fileTitle_sw"] = res?.["title_sw"] || journalDetails?.["fileTitle_sw"] || journalDetails?.["title_en"] 
    res["description_sw"] =
      res?.["description_sw"] || journalDetails?.["description_en"];
    res["details_sw"] = res?.["details_sw"] || journalDetails?.["details_sw"] || journalDetails?.["subtitle_en"];
    res["year_sw"] = journalDetails?.["year"];
    // res['details'] = journalDetails?.["subtitle_sw"];  //en field

    // delete res?.["contributor_sw"];
    // delete res?.["category_sw"];
    // delete res?.["title_sw"];
    // delete res?.["description_sw"];
    // delete res?.["details"];
  }

  console.log(res);
  return res;

}