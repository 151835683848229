import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { FormControl, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { addNewFileSchema } from './validation';
import StyledInput from '../../components/Input/Input';
import StyledSelect from '../StyledSelect/StyledSelect';
import StyledTextArea from '../Input/TextArea';
import styled from 'styled-components';
import { tagsList } from 'src/helpers/utils';
import { ReactComponent as InfoIcon } from '../../assets/info-circle.svg';
import { useTranslation } from 'react-i18next';
import TagsFieldList from '../TagsField/TagsFieldList';

const IconWrapper = styled(InfoIcon)({
  marginRight: '2px',
  // display: 'flex',
});

const StyledBox = styled(Box)({
  // marginBottom: '24px',
  marginBottom: (props) => (props.swFields === true ? '0px' : '24px'),
});

const Spacer = styled('div')({
  height: '24px',
});
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  marginBottom: '8px',
  lineHeight: '24px',
});

function AddJournal({
  setFileData,
  swFields,
  fileData,
  allTags,
  tagsOptions,
  setTagsOptons,
  setDetectTagsField,
  language,
}) {
  const initialValues = {
    fileTitle: '',
    category: '',
    details: '',
    contributor: '',
    year: '',
    description: '',
    tag: [],
    fileTitle_sw: '',
    category_sw: '',
    details_sw: '',
    contributor_sw: '',
    year_sw: '',
    description_sw: '',
    tag_sw: [],
    showSW: swFields === true ? true : false,
  };
  // console.log('SW FIELDS', swFields, initialValues);
  // Form fileds data
  const FormFields = [
    {
      id: '1',
      fieldName: 'File Title',
      field: 'text-input',
      key: 'fileTitle',
      placeholder: 'Enter file title',
      isRequired: true,
    },
    {
      id: '2',
      fieldName: 'File Title',
      field: 'text-input',
      key: 'fileTitle_sw',
      placeholder: 'Enter file title',
      isSw: true,
    },
    {
      id: '3',
      fieldName: 'Category',
      field: 'select-input',
      key: 'category',
      placeholder: 'Select Category',
      isRequired: true,
    },
    {
      id: '4',
      fieldName: 'Category',
      field: 'text-input',
      key: 'category_sw',
      isDisable: true,
      placeholder: 'Select Category',
      isSw: true,
    },
    {
      id: '5',
      fieldName: 'About this file',
      field: 'text-area',
      key: 'details',
      placeholder: 'Enter details about this file',
      isRequired: true,
    },
    {
      id: '6',
      fieldName: 'About this file',
      field: 'text-area',
      key: 'details_sw',
      placeholder: 'Enter details about this file',
      isSw: true,
    },
    {
      id: '7',
      fieldName: 'Contributor',
      field: 'text-input',
      key: 'contributor',
      placeholder: 'Enter contributor',
    },
    {
      id: '8',
      fieldName: 'Contributor',
      field: 'text-input',
      key: 'contributor_sw',
      placeholder: 'Enter contributor',
      isSw: true,
    },
    {
      id: '9',
      fieldName: 'Year',
      field: 'text-input',
      key: 'year',
      placeholder: 'Enter year',
    },
    {
      id: '10',
      fieldName: 'Year',
      field: 'text-input',
      key: 'year_sw',
      placeholder: 'Enter year',
      isSw: true,
    },
    {
      id: '11',
      fieldName: 'Short Description',
      key: 'description',
      field: 'text-input',
      placeholder: 'Enter short description',
    },
    {
      id: '12',
      fieldName: 'Short Description',
      key: 'description_sw',
      field: 'text-input',
      placeholder: 'Enter short description',
      isSw: true,
    },
    {
      id: '13',
      fieldName: 'Tags',
      field: 'autocomplete',
      options: tagsList(allTags, language),
      key: 'tag',
    },
    {
      id: '14',
      fieldName: 'Tags',
      field: 'autocomplete',
      key: 'tag_sw',
      // disable: true,
      isSw: true,
    },
  ];
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    // setFieldValue,
    handleSubmit,
    // setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addNewFileSchema,
    // validateOnChange: true,
    onSubmit: (values) => {},
    validate: (values) => {
      console.log(values);
      setFileData(values);
      // setErrors(false);
    },
  });

  const { t } = useTranslation();
  useEffect(() => {
    // console.log(tagsList(allTags));
    if (fileData) setValues(fileData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  return (
    <form onSubmit={handleSubmit}>
      {FormFields?.map((ele, index) => {
        // eslint-disable-next-line array-callback-return
        if (ele.isSw) if (!swFields) return;
        return (
          <>
            <StyledBox key={ele.id} swFields={swFields} isSw={ele.isSw}>
              {ele.isSw !== true ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    align='left'
                    fontFamily='Open Sans'
                    sx={{
                      fontSize: '12px',
                      lineHeight: '24px',
                      marginBottom: '4px',
                      fontWeight: '600',
                    }}
                  >
                    {t(ele.fieldName)}
                    {ele.isRequired ? (
                      <sup style={{ color: '#B1484E' }}>*</sup>
                    ) : null}
                  </Typography>
                  {ele.key === 'description' || ele.key === 'details' ? (
                    <Typography
                      variant='subtitle1'
                      align='right'
                      display='flex'
                      alignItems='center'
                      fontFamily='Open Sans'
                      sx={{
                        fontSize: '12px',
                        lineHeight: '24px',
                        marginBottom: '4px',
                        fontWeight: '400',
                        color: '#7B858F',
                      }}
                    >
                      <IconWrapper />
                      Max {ele.key === 'description' ? '1000' : '5000'}{' '}
                      {t(`characters`)}
                    </Typography>
                  ) : null}
                </Box>
              ) : (
                ''
              )}
              {ele.field === 'select-input' ? (
                <FormControl sx={{ width: '100%' }}>
                  <StyledSelect
                    name={ele.key}
                    secondary
                    selectedOption={values[ele.key]}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    errorclass={
                      errors[ele.key] && touched[ele.key]
                        ? 'errorMessage'
                        : null
                    }
                    style={{ width: '100%' }}
                  />
                </FormControl>
              ) : ele.field === 'autocomplete' && !ele?.isSw ? (
                <TagsFieldList
                  options={tagsList(allTags, language)}
                  tagsOptions={tagsOptions}
                  setTagsOptons={setTagsOptons}
                  setDetectTagsField={setDetectTagsField}
                  language={language}
                  journalTags
                />
              ) : // <TagsFields
              //   field={{
              //     options: ele?.options,
              //     key: ele?.key,
              //     // error: fieldError?.autocompleteSW,
              //     style: ele?.isSw && { marginTop: '-16px' },
              //   }}
              //   // tags={values[ele.key]}
              //   tags={ele.isSw ? tagsSW : tagsEN}
              //   setTags={ele.isSw ? setTagsSW : setTagsEN}
              //   selectedTags={ele.isSw ? selectedTagsSW : selectedTagsEN}
              //   setSelectedTags={
              //     ele.isSw ? setSelectedTagsSW : setSelectedTagsEN
              //   }
              // />
              ele.field === 'text-area' ? (
                <StyledTextArea
                  placeholder={t(ele.placeholder)}
                  type='text'
                  style={ele.isSw ? { marginTop: '-5px' } : null}
                  name={ele.key}
                  value={values[ele.key]}
                  onBlur={handleBlur}
                  errorclass={
                    errors[ele.key] && touched[ele.key] ? 'errorMessage' : null
                  }
                  onChange={handleChange}
                />
              ) : ele?.field !== 'autocomplete' ? (
                <StyledInput
                  placeholder={t(ele.placeholder)}
                  type='text'
                  variant3='true'
                  secondary='true'
                  disabled={ele.isDisable ? true : false}
                  name={ele.key}
                  onBlur={handleBlur}
                  value={values[ele.key]}
                  onChange={handleChange}
                  style={{ marginBottom: '0px !important', fontSize: '14px' }}
                  errorclass={
                    errors[ele.key] && touched[ele.key] ? 'errorMessage' : null
                  }
                />
              ) : null}
              {errors[ele.key] && touched[ele.key] ? (
                <ErrorMessage>{t(errors[ele.key])}</ErrorMessage>
              ) : null}
              {ele.isSw ? <Spacer /> : ''}
            </StyledBox>
          </>
        );
      })}
    </form>
  );
}

export default AddJournal;
