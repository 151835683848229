import * as React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { ReactComponent as LoadingIcon } from '../../assets/loading.svg';

const CustomButton = styled(Button)({
  borderRadius: '0 !important',
  // color: 'white !important',
  color: (props) =>
    props.secondary === 'true' ? '#BB8D0B !important' : 'white !important',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  width: '100%',
  padding: (props) =>
    props.btnvariant === 'true' ? '' : '13px 18px !important',
  // padding: '13px 18px !important',
  // background: '#BB8D0B !important',
  background: (props) =>
    props.secondary === 'true' ? 'white !important' : '#BB8D0B !important',
  height: (props) =>
    props.btnvariant === 'true' || props.secondary
      ? '35px !important'
      : '50px !important',
  textTransform: (props) =>
    props.btnvariant === 'true' || props.secondary === 'true'
      ? 'capitalize !important'
      : '',
  // height: '50px !important',
  border: (props) =>
    props.secondary === 'true' ? ' 1px solid #D9DEE2 !important' : '0',
  fontSize: (props) =>
    props.btnvariant === 'true' || props.secondary ? '14px' : '16px',
  ':hover': {
    // background: '#A97F0A !important',
    background: (props) =>
      props.secondary === 'true' ? 'white !important' : '#A97F0A !important',
  },
  '&.Mui-disabled': {
    background: '#E9D5B2 !important',
    border: 'none !important',
    color: 'white !important',
  },
});

const Loading = styled(LoadingIcon)({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  animation: 'rotate 2s linear infinite',
});

const IconButton = styled(Button)({
  border: '0',
  borderRadius: '0 !important',
  color: 'white !important',
  width: '100%',
  padding: '6px 12px !important',
  background: '#BB8D0B !important',
  height: '100% !important',
  fontSize: '14px !important',
  fontFamily: 'Open Sans',
  textTransform: 'capitalize !important',
  ':hover': {
    background: '#A97F0A !important',
  },
  '&.Mui-disabled': {
    background: '#E9D5B2 !important',
  },
});

export default function StyledButton({
  iconButton,
  onClick,
  type,
  children,
  disable,
  loading,
  btnvariant,
  style,
  secondary,
}) {
  return iconButton === true ? (
    <IconButton onClick={onClick} type={type} disabled={disable || loading}>
      {loading ? <Loading /> : children}
    </IconButton>
  ) : (
    <CustomButton
      onClick={onClick}
      disabled={disable || loading}
      type={type}
      style={style}
      btnvariant={btnvariant}
      secondary={secondary}
    >
      {loading ? <Loading /> : children}
    </CustomButton>
  );
}
