import { Box } from '@mui/material';
import React from 'react';
import StyledButton from '../Button/Button';
import StyledSearchBar from '../SearchBar/SearchBar';
import { ReactComponent as AddIcon } from '../../assets/add.svg';
import StyledSelect from '../StyledSelect/StyledSelect';
import CheckPermissions from '../CheckPermissions/CheckPermissions';
import { useTranslation } from "react-i18next";
function JournalsTopRightBar({
  handleClickOpen,
  text,
  handleChange,
  handleClick,
  handleSearch,
  selectedOption,
  setSelectedOption,
  handleSelectChange,
  display = { search: true, btn: true, dropdown: true, iconBtn: true },
}) {
  const {t}=useTranslation();
  return (
    <>
      {display?.dropdown ? (
        <StyledSelect
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleChange={handleSelectChange}
        />
      ) : null}
      {display?.search ? (
        <StyledSearchBar
          handleSearch={handleSearch}
          handleChange={handleChange}
          handleClick={handleClick}
          onEnter
          placeholder={t('Search for tags...')}
        />
      ) : null}
      <CheckPermissions requiredPermission={['634d371c298b7ce5da21b6a1']}>
        {display?.btn ? (
          <Box style={{ width: '150px', height: '36px', marginLeft: '16px' }}>
            <StyledButton iconButton={true} onClick={handleClickOpen}>
              {display?.iconBtn ? <AddIcon /> : null}
              {text}
            </StyledButton>
          </Box>
        ) : null}
      </CheckPermissions>
    </>
  );
}

export default JournalsTopRightBar;
