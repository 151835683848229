import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)({
  padding: '16px',
  borderBottom: 'none',
  fontFamily: 'Open Sans',
  fontWeight: '500',
  fontSize: '14px !important',
  letterSpacing: '1px !important',
  boxSizing: 'border-box',
  '& .name': {
    width: '180px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
const StyledTableHead = styled(TableHead)({
  '& .MuiTableRow-root .MuiTableCell-root': {
    color: '#7B858F',
    fontSize: '12px !important',
    fontWeight: 500,
  },
});

const StyledTableRow = styled(TableRow)({
  boxSizing: 'border-box',
  // border: '1px solid transparent',
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFBF5',
  },
  '&:hover': {
    boxShadow: 'inset 0px 0px 0px 1px #bb8d0b',
    cursor: 'pointer',
  },
});

function JournalsList({ journals, setNewFileComponent, setJournalId, page }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const handleJournalUpdate = (ele) => {
    // console.log(ele);
    // setNewFileComponent(true);
    // History.pushState({ pageNumber: '2' }, null, `/files/${ele._id}/edit`);
    navigate(`/files/${ele._id}/edit`, {
      state: { page: location.state ? location.state?.page?.page : page },
    });
    console.log('LOCATION', location.state, page);
    setJournalId(ele._id);
  };
  return (
    <>
      <TableContainer sx={{ height: 'calc(100vh - 202px)' }}>
        <Table sx={{ minWidth: 1100 }}>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>{t(`File Name`)}</StyledTableCell>
              <StyledTableCell>{t(`Category`)}</StyledTableCell>
              <StyledTableCell>{t(`Total Pages`)}</StyledTableCell>
              <StyledTableCell>{t(`Contributor`)}</StyledTableCell>
              <StyledTableCell>{t(`Uploader`)}</StyledTableCell>
              <StyledTableCell>{t(`Last Update`)}</StyledTableCell>
            </TableRow>
          </StyledTableHead>

          <TableBody>
            {journals &&
              journals.map((ele, index) => (
                <StyledTableRow
                  key={index}
                  onClick={() => handleJournalUpdate(ele)}
                >
                  <StyledTableCell>
                    <p className='name'>{ele.title}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    <p className='name'>{ele.category.name}</p>
                  </StyledTableCell>
                  <StyledTableCell>{ele.pagesCount}</StyledTableCell>
                  <StyledTableCell>
                    <p className='name'> {ele.contributor}</p>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '100px' }}>
                    <p className='name'> {ele?.creator?.name}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(ele.updatedAt).format('lll')}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default JournalsList;
