import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { ReactComponent as UploadIcon } from '../../assets/Remove.svg';
import { ReactComponent as Ellipse } from '../../assets/ellipse.svg';
// import { ReactComponent as CoverIcon } from '../../assets/cover-icon.svg';
import { React, forwardRef } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FileUploadInput = styled(TextField)({
  display: 'none',
  fontFamily: 'Open Sans !important',
});

const FileUploadButton = styled('label')({
  display: 'flex',
  width: '100px',
  height: '36px',
  marginRight: 'auto',
  border: '1px solid #D9DEE2',
  alignItems: 'center',
  fontSize: '14px',
  color: '#BB8D0B',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  cursor: 'pointer',
  pointerEvents: (props) => (props.loading === true ? 'none' : 'initial'),
  // pointerEvents: 'none',
});
const Container = styled('p')({
  // border: '1px dashed #BB8D0B',
  border: (props) =>
    props.secondary === 'true' ? 'none' : '1px dashed #BB8D0B',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  textAlign: 'center',
  fontFamily: 'Open Sans',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  width: '100%',
  cursor: 'pointer',
  '& span': {
    color: '#7B858F',
    fontSize: '12px',
    lineHeight: '18px',
  },
});
const CoverUploadLabel = styled('label')({
  width: '40px',
  position: 'absolute',
  top: '0',
  right: '4px',
});

const FileUploadLabel = styled('label')({
  display: 'flex',
  fontFamily: 'Open Sans !important',
  // width: '200px',
  width: (props) => (props.secondary === 'true' ? '100%' : '230px'),
  // height: '200px',
  height: (props) => (props.secondary === 'true' ? '477px' : '200px'),
  margin: 'auto',
  // background: '#F6EEE0',
  background: (props) => (props.secondary === 'true' ? '#F2F2F2' : '#F6EEE0'),
  boxSizing: 'border-box',
  padding: '8px',
  '& .MuiInputBase-root .MuiInputBase-input': {
    display: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
});
// const AbsoluteIconContainer = styled('div')({
//   position: 'absolute',
//   top: '0',
//   right: '4px',
// });

const StyledToolTip = styled(Tooltip)({});

const CustomInput = styled(TextField)({
  width: '100%',
  background: 'white',
  fontFamily: 'Open Sans !important',
  marginBottom: (props) =>
    props.variant3 === 'true' ? '0px !important' : '16px',
  marginTop: (props) => (props.variant3 === 'true' ? '' : ''),
  boxSizing: 'border-box',
  '& ::placeholder': {
    color: '#7B858F !important',
    opacity: '1',
    fontFamily: 'Open Sans !important',
    fontWeight: '400',
    fontSize: (props) => (props.secondary === 'true' ? '14px' : '16px'),
  },
  '& .MuiInputBase-root': {
    border: '1px solid #D9DEE2',
    borderRadius: 0,
    fontFamily: 'Open Sans !important',
    // height: '48px',
    height: (props) =>
      props.secondary === 'true'
        ? '36px'
        : props.autoheight === 'true'
        ? 'auto'
        : '48px',
  },
  '& .MuiInputBase-root.Mui-focused': {
    border: '1px solid #BB8D0B',
    borderRadius: 0,
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
    marginTop: '21px',
    color: '#B1484E',
  },
  '& .MuiInputBase-root .Mui-disabled': {
    '-webkit-text-fill-color': '#1A2128 !important',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiInputAdornment-root': {
    cursor: 'pointer',
  },
});

const StyledInput = forwardRef(
  (
    {
      placeholder,
      type,
      profileUpload,
      errorclass,
      style,
      variant3,
      secondary,
      autoheight,
      loading = false,
      min,
      max,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    return type === 'file' && profileUpload === true ? (
      <FileUploadButton style={style} loading={loading}>
        {t('Choose File')}
        <FileUploadInput
          inputProps={{ accept: 'image/png,image/jpg,image/gif' }}
          ref={ref}
          {...props}
          type={type}
        />
      </FileUploadButton>
    ) : type === 'file' ? (
      secondary === 'true' ? (
        <CoverUploadLabel style={style} secondary={secondary}>
          <StyledToolTip title='Upload File Cover' arrow placement='left'>
            <UploadIcon width={36} style={{ cursor: 'pointer' }} />
          </StyledToolTip>
          <FileUploadInput
            inputProps={{ accept: 'image/png,image/jpg,image/gif' }}
            ref={ref}
            {...props}
            type={type}
          ></FileUploadInput>
        </CoverUploadLabel>
      ) : (
        <FileUploadLabel style={style} secondary={secondary}>
          <Container>
            <UploadIcon />
            {t(`Click to upload Image`)} <span>PNG, JPG, or JPEG </span>
            <span
              style={{
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Max 800x800px
              <Ellipse style={{ margin: '0 2px' }} /> {t(`Max size`)} 10mb
            </span>
          </Container>
          <FileUploadInput
            inputProps={{ accept: 'image/png,image/jpg,image/gif' }}
            ref={ref}
            {...props}
            type={type}
          ></FileUploadInput>
        </FileUploadLabel>
      )
    ) : (
      <CustomInput
        style={style}
        autoheight={autoheight}
        secondary={secondary}
        inputProps={{
          autoComplete: 'new-password',
        }}
        InputProps={{
          inputProps: { min: min, max: max },
        }}
        sx={{
          '& .MuiInputBase-root': {
            border: `${errorclass && '1px solid #B1484E !important'}`,
            fontSize: `${secondary && '14px'}`,
          },
        }}
        ref={ref}
        placeholder={placeholder}
        type={type}
        variant3={variant3}
        {...props}
      />
    );
  }
);

export default StyledInput;
