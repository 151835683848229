import React, { useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from 'react-grid-dnd';
import { sortPages } from 'src/redux/services/category';

export default function Drageable({ pages, searchItems,Component }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // target id will only be set if dragging from one dropzone to another.
  const [items, setItems] = useState();
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    //  console.log(targetId, sourceId);
    //  console.log(sourceIndex, targetIndex);
    if(!searchItems){
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      console.log(result);
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }
    const result = swap(items[sourceId], sourceIndex, targetIndex);

    //Changing page no after swap
    for(let i=0;i<result.length;i++){
      result[i].pageNum=i+1;
    }

    //Get sorted Pages
    setTimeout(() => {
      getSortedPages(result);
    }, 1000);

    return setItems({
      ...items,
      [sourceId]: result,
    });
   }
  }

  const getSortedPages = async (newItems) => {
    const items = await newItems;
    let data = [];
    //update page numbers and format data
    items.forEach((val, index) => {
      //Reflect Current changes
      // let sortItems = document.getElementById(`${val?._id}`);
      // if(sortItems){
      // sortItems.innerHTML =
      //   (index + 1)?.toString().length > 1 ? index + 1 : `0${index + 1}`;
      // }

      data.push({ _id: val?._id, pageNum: index + 1 });
    });

    const obj = { sort: data };
    // eslint-disable-next-line no-unused-vars

     await sortPages(obj);
  
    //Reflect Current changes
    // setItems({ items: items });
  };
  const boxesPerRow = (screenWidth) => {
    if (screenWidth >= 1400) {
      return 5;
    } else if (screenWidth >= 1200) {
      return 4;
    } else if (screenWidth >= 1000) {
      return 3;
    } else if (screenWidth >= 700) {
      return 2;
    } else {
      return 1;
    }
  };

  console.log(items?.items)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (pages) {
      setItems({ items: pages });
    }
  }, [pages]);
  return (
    <>
      {
        <GridContextProvider onChange={onChange}>
          <div>
            <GridDropZone
              className='dropzone items'
              id='items'
              boxesPerRow={boxesPerRow(screenWidth)}
              rowHeight={250}
              style={{
                height: `${
                  255 *
                  Math.floor(items?.items?.length / boxesPerRow(screenWidth))
                }px`,
                margin: '12px',
                display: 'flex',
                minWidth: '90%',
              }}
            >
              {items
                ? items?.items.map((item) => (
                    <GridItem key={item?.pageNum}>{Component(item)}</GridItem>
                  ))
                : null}
            </GridDropZone>
          </div>
        </GridContextProvider>
      }
    </>
  );
}
