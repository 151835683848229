import { request } from '../../shared/verb';
import { setUser } from '../actions/auth';

export const userLogin = (dispatch) => (data) => {
  return request(`/users/login`, { ...data }, 'post', true).then((res) => {
    dispatch(setUser(res.data.data.user));
    return res.data;
  });
};
export const verifyUser = (dispatch) => () =>
  request(`/users/me`, 'get').then((res) => {
    dispatch(setUser(res.data.data.user));
    return res.data;
  });

export const refreshToken = (dispatch) => (data) =>
  request(`/users/refreshToken`, { ...data }, 'post', true).then((res) => {
    dispatch(setUser(res.data));

    return res.data;
  });

export const userRegister = (dispatch) => (data) =>
  request(`/users/register`, { ...data }, 'post').then((res) => {
    // dispatch(setUser(res.data.user));
    // verifyUser();
    return res.data;
  });

export const forgotPassword = (data) =>
  request(`/users/forget-password`, { ...data }, 'post').then((res) => {
    return res;
  });

export const resetPassword = (data) =>
  request(`/users/forget-password/reset`, { ...data }, 'post').then((res) => {
    return res;
  });

export const createInvitation = (data) =>
  request(`/invitation/send`, { ...data }, 'post').then((res) => {
    return res;
  });

export const UpdateInvitation = (data) =>
  request(`/users/update`, { ...data }, 'put').then((res) => {
    return res;
  });
