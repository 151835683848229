import React from 'react';
import { styled } from '@mui/system';

const TagWrapper = styled('span')({
  width: 'fit-content',
  height: '22px',
  padding: '4px 6px',
  color: '#BB8D0B',
  background: '#F6EEE0',
  borderRadius: '2px',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',
  // marginBottom: '8px',
});
function Tag({ children, style }) {
  return <TagWrapper style={style}>{children}</TagWrapper>;
}

export default Tag;
