import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const LoaderContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
});
function Loader() {
  return (
    <LoaderContainer>
      <CircularProgress sx={{ color: '#A97F0A' }} />
    </LoaderContainer>
  );
}

export default Loader;
