import { Box } from '@mui/material';
import React from 'react';
import StyledButton from '../Button/Button';
import { ReactComponent as AddIcon } from '../../assets/add.svg';
// import CheckPermissions from '../CheckPermissions/CheckPermissions';
import { useTranslation } from 'react-i18next';

function PublicSiteTopRightBar({ handleClickOpen, disabledButton, loading }) {
  const { t } = useTranslation();
  return (
    <>
      {/* <CheckPermissions requiredPermission={['634fafba9b08601a8fff9b41']}> */}
      <Box style={{ minWidth: '152px', height: '36px', marginLeft: '16px' }}>
        <StyledButton
          loading={loading}
          iconButton={true}
          onClick={handleClickOpen}
          disable={disabledButton}
        >
          <AddIcon /> {t(`Save Details`)}
        </StyledButton>
      </Box>
      {/* </CheckPermissions> */}
    </>
  );
}

export default PublicSiteTopRightBar;
