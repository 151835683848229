import React, { useEffect } from 'react';
import { request } from '../../shared/verb';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

function ResetPasswordInvite() {
  const Navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('token');
    console.log('reset pass - token', code);
    request(`/users/forget-password/verify`, { code: code }, 'post')
      .then((res) => {
        Navigate('/new-pass');
        // console.log('Verify Register invite', res);
        localStorage.setItem(
          'ForgotPassInviteData',
          JSON.stringify(res?.data?.data?.email)
        );
        return res;
      })
      .catch((err) => console.log('error -->', err));
    // eslint-disable-next-line
  }, []);
  return <Loader />;
}

export default ResetPasswordInvite;
