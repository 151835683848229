export const actions = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  ADD_CATEGORIES: 'ADD_CATEGORIES',
  REMOVE_CATEGORIES: 'REMOVE_CATEGORIES',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
};

export const setCategory = (data) => ({
  type: actions.SET_CATEGORIES,
  data,
});
export const addCategory = (data) => ({
  type: actions.ADD_CATEGORIES,
  data,
});
export const removeCategory = (id) => ({
  type: actions.REMOVE_CATEGORIES,
  data: id,
});
export const updateCategory = (data, id) => ({
  type: actions.UPDATE_CATEGORIES,
  data: {
    updatedData: data,
    id: id,
  },
});
