import React, { useState } from 'react';
import { styled } from '@mui/system';
import RemoveIcon from '../../assets/bin.svg';
import { Typography } from '@mui/material';
import StyledButton from '../Button/Button';
import { removeUser } from '../../redux/services/users';
import { useTranslation } from "react-i18next";

const Container = styled('div')({
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  marginBottom: '16px',
});
function RemoveUser({
  userData,
  handleClose,
  users,
  setUsers,
  setOpenSnackbar,
  setMessageObject,
}) {
  const {t}=useTranslation();
  const [loading, setLoading] = useState(false);
  console.log(userData.email);
  const deleteUser = () => {
    setLoading(true);
    let data = {
      email: userData.email,
    };
    removeUser(data)
      .then((res) => {
        setLoading(false);
        handleClose();
        setUsers(users?.filter((ele) => ele?.email !== data.email));
        setMessageObject({
          text: t('User removed'),
          severity: 'success',
        });
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setLoading(false);
        setMessageObject({
          text: err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.data,
          severity: 'error',
        });
        setOpenSnackbar(true);
      });
  };
  return (
    <Container>
      <img src={RemoveIcon} alt='remove icon' width={200} />
      <Typography
        variant='subtitle1'
        align='left'
        sx={{
          fontSize: '14px',
          mb: 2,
          fontFamily: 'Open Sans',
          textAlign: 'center',
          fontWeight: '500',
          lineHeight: '24px',
          padding: '0px 10px',
        }}
      >
        {t(`Are you really want to remove`)} <strong>{userData.email}</strong>? {t(`If you remove this user, it will remove permanently from user’s list.`)}
      </Typography>
      <StyledButton onClick={deleteUser} btnvariant='true' loading={loading}>
        Remove
      </StyledButton>
    </Container>
  );
}

export default RemoveUser;
