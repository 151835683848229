import { Grid } from '@mui/material';
import React from 'react';
import Header from '../Header/Header';

function RegisterLayout({ children }) {
  return (
    <>
      <Header />
      <Grid
        container
        flexDirection='column'
        alignContent='center'
        justifyContent='center'
        sx={{ background: '#FFFBF5', width: '100%', height: '100vh' }}
      >
        <Grid item></Grid>
        <Grid
          item
          sx={{
            maxWidth: '472px',
            background: '#FFFFFF',
            padding: '36px',
            border: '1px solid #F2F2F2',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default RegisterLayout;
