import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9D5B2',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#BB8D0B' : '#E9D5B2',
  },
}));



export default function CustomizedProgressBars({style, percentage}) {
  return (
    <Box sx={style}>
      <BorderLinearProgress variant="determinate" value={percentage} />
    </Box>
  );
}