import { combineReducers } from 'redux';
import { auth } from './auth';
import { allPermissions } from './permissions';
import { allCategories } from './category';
import { general } from './general';
// import verifyuser from './verifyuser';

// combining multiple reducers to create one rootReducer
const reducers = combineReducers({
  auth,
  allPermissions,
  allCategories,
  general,
});

export default reducers;
