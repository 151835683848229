import { useNavigate } from 'react-router-dom';

let preNavigateCallback = null;

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const customNavigate = (url, options) => {
    if (preNavigateCallback) {
      let allowNavigation = preNavigateCallback({
        url,
        options,
      });
      if (allowNavigation) navigate(url, options);
    } else {
      navigate(url, options);
    }
  };

  return customNavigate;
};

export const validateBeforeNavigate = (cb) => {
  preNavigateCallback = cb;
};
