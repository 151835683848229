import { Box, Typography, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import StyledInput from '../Input/Input';
import { resetPasswordSchema } from './validation';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../../assets/unlock.svg';
import { useFormik } from 'formik';
import StyledButton from '../Button/Button';
import { changePassword } from '../../redux/services/users';

const Container = styled('div')({
  padding: '16px',
});
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  lineHeight: '24px',
  marginTop: '5px',
  minHeight: '24px',
});
const initialVal = {
  password: 'password',
  newpassword: 'password',
};
const initialValues = {
  password: '',
  newpassword: '',
};
const FormFields = [
  {
    id: '1',
    fieldName: 'Password',
    placeholder: 'Enter old password',
    adornment: true,
    key: 'password',
  },
  {
    id: '2',
    fieldName: 'New Password',
    placeholder: 'Set a password',
    adornment: true,
    key: 'newpassword',
  },
];
function ChangePassword({
  setChangePassComponent,
  setMessageObject,
  setOpenSnackbar,
}) {
  const [toggleInputType, setToggleInputType] = useState(initialVal);
  // const [helperTxt, setHelperTxt] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePasswordToggle = (type) => {
    const temp = { ...toggleInputType };
    temp[type] = temp[type] === 'text' ? 'password' : 'text';
    setToggleInputType(temp);
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      const data = {
        currentPassword: values.password,
        newPassword: values.newpassword,
      };
      // console.log('data', data);
      changePassword(data)
        .then(async (res) => {
          setLoading(false);
          console.log('res', res);
          resetForm();
          setOpenSnackbar(true);
          setMessageObject({
            severity: 'success',
            text: res?.data?.message,
          });
          await setChangePassComponent(false);
        })
        .catch((err) => {
          setLoading(false);
          resetForm();
          // if (err?.response?.data.message === 'Current Password is incorrect') {
          //   setErrors({
          //     password:
          //       err?.response?.data?.message?.charAt(0).toUpperCase() +
          //       err?.response?.data?.message?.slice(1),
          //   });
          // }
          setOpenSnackbar(true);
          setMessageObject({
            severity: 'error',
            text: err?.response?.data?.message,
          });
          resetForm();
          console.log(err?.response?.data?.message);
        });
      setSubmitting(false);
    },
  });
  const setInputAdornment = (type) => {
    return (
      <InputAdornment position='end'>
        {toggleInputType[type] === 'password' ? (
          <LockIcon onClick={() => handlePasswordToggle(type)} />
        ) : (
          <UnlockIcon onClick={() => handlePasswordToggle(type)} />
        )}
      </InputAdornment>
    );
  };
  return (
    <Container>
      <Typography
        variant='subtitle1'
        align='left'
        sx={{
          fontSize: '16px',
          mb: 1,
          fontFamily: 'Open Sans',
          fontWeight: '600',
        }}
      >
        Account Details
      </Typography>

      <form
        onSubmit={handleSubmit}
        noValidate={true}
        style={{ maxWidth: '624px' }}
      >
        <Box sx={{ mb: '12px' }}>
          {FormFields.map((ele, index) => {
            return (
              <Box key={index} sx={{ mb: 1 }}>
                <Typography
                  variant='subtitle1'
                  align='left'
                  sx={{ fontSize: '14px', mb: 1, fontFamily: 'Open Sans' }}
                >
                  {ele.fieldName}
                </Typography>
                <StyledInput
                  placeholder={ele.placeholder}
                  style={{ marginBottom: '0px' }}
                  type={
                    ele.adornment
                      ? ele.key === 'password'
                        ? toggleInputType.password
                        : toggleInputType.newpassword
                      : ele.type
                  }
                  name={ele.key}
                  value={values[ele.key]}
                  secondary='true'
                  onChange={(e) => {
                    handleChange(e);

                    // setHelperTxt(false);
                  }}
                  errorclass={
                    errors[ele.key] && touched[ele.key] ? 'errorMessage' : null
                  }
                  // onChange={(e) => handleFormInput(ele.key, e.target.value)}
                  InputProps={{
                    endAdornment: ele.adornment
                      ? setInputAdornment(ele.key)
                      : null,
                  }}
                />
                <ErrorMessage>
                  {errors[ele.key] && touched[ele.key] ? errors[ele.key] : null}
                </ErrorMessage>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ width: '78px' }}>
          <StyledButton type='submit' btnvariant='true' loading={loading}>
            Confirm
          </StyledButton>
        </Box>
      </form>
    </Container>
  );
}

export default ChangePassword;
