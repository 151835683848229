import { actions } from '../actions/general';

const initialState = {
  drawer: false,
  pages: null,
  tags: null,
  journalDetails: null,
  allLanguagesTags: null,
  locale: 'sw',
};

export const general = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.SET_DRAWER:
      return { ...state, drawer: data };
    case actions.SET_PAGES:
      return { ...state, pages: data };
    case actions.SET_TAGS:
      return { ...state, tags: data };
    case actions.SET_JOURNAL_DETAILS:
      return { ...state, journalDetails: data };
    case actions.SET_LANGUAGE_TAGS:
      return { ...state, allLanguagesTags: data };
    case actions.SET_LOCALE:
      return { ...state, locale: data };
    default:
      return state;
  }
};
