import * as yup from 'yup';

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters.')
    .required('This field is required.'),
  newpassword: yup
    .string()
    .min(6, 'Password must be at least 6 characters.')
    .required('This field is required.'),
});
