let host = [];
if (typeof window !== 'undefined') {
  host = window.location.host.split('.');
}
let url = '';
let imageUrlPrefix = '';
let APP_CLIENT = '';
let env = '';
if (host?.includes('admin-test')) {
  APP_CLIENT = 'admin-test';
} else if (host?.includes('admin-stage')) {
  APP_CLIENT = 'admin-stage';
} else if (host?.includes('admin')) {
  APP_CLIENT = 'admin';
}
// else {
//   APP_CLIENT = 'admin-test';
//   env = 'local';
// }
console.log('API SERVICE');
switch (APP_CLIENT) {
  case 'admin-test':
    url =
      // 'http://sjofarts-museum-test.eba-2u5bsvg5.us-east-1.elasticbeanstalk.com/api/v1.0';
      'https://api-test.sjofartsmuseum.ax/api/v1.0';
    imageUrlPrefix = 'https://assets-test.sjofartsmuseum.ax/';
    break;

  case 'admin-stage':
    url = 'https://api-stage.sjofartsmuseum.ax/api/v1.0';
    imageUrlPrefix = 'https://assets-stage.sjofartsmuseum.ax/';
    break;

  case 'admin':
    url = 'https://api.sjofartsmuseum.ax/api/v1.0';
    imageUrlPrefix = 'https://assets.sjofartsmuseum.ax/';
    break;

  default:
    url = 'https://api-test.sjofartsmuseum.ax/api/v1.0';
    // url = 'http://192.168.250.102:3000/api/v1.0';
    imageUrlPrefix = 'https://assets-test.sjofartsmuseum.ax/';
    env = 'local';

  // 'http://sjofarts-museum-test.eba-2u5bsvg5.us-east-1.elasticbeanstalk.com/api/v1.0';
}

const apiConfig = {
  url,
  imageUrlPrefix,
  APP_CLIENT,
  env,
};

export default apiConfig;
