import { request } from '../../shared/verb';

export const addConfig = (data, locale) =>
  request(`/config`, data, 'post', null, null, locale).then((res) => {
    return res.data;
  });

export const getConfig = (locale) =>
  request(`/config`, 'get', null, null, null, locale).then((res) => {
    return res.data;
  });
