import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import { Box, styled } from '@mui/system';
import { useFormik } from 'formik';
import { InviteUserFormSchema } from './validation';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import StyledCheckbox from '../ContainedCheckbox/ContainedCheckbox';
import { ReactComponent as SendInviteIcon } from '../../assets/sendInviteIcon.svg';
import { useSelector } from 'react-redux';
import { UpdateInvitation } from '../../redux/services/auth';
import { useTranslation } from "react-i18next";

const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  marginTop: '-8px',
});

function UpdateUserInviteForm({
  user,
  handleClose,
  users,
  setUsers,
  setOpenSnackbar,
  setMessageObject,
}) {
  const [loading, setLoading] = useState(false);
  // console.log('DATA -> ', user);
  // user.permissions.map((ele) => ele._id);
  const {t}=useTranslation();
  const initialValues = {
    email: user.email,
    permissions: user?.permissions?.map((ele) => ele?._id) || [],
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: InviteUserFormSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      values['userEmail'] = values['email'];
      delete values['email'];
      let data, updatedData;
      UpdateInvitation(values)
        .then((res) => {
          // console.log(res);
          setLoading(false);
          data = {
            email: res?.data?.data?.userInfo?.email,
            permissions: res?.data?.data?.userInfo?.permissions,
          };

          users.forEach((ele) => {
            if (ele.email === data?.email) {
              ele.email = data?.email;
              ele.permissions = data?.permissions;
            }
            return ele;
          });
          updatedData = users;
          setUsers(updatedData);
          // console.log('updated Data', updatedData);

          resetForm();
          handleClose();

          setMessageObject({
            text: res?.data?.message,
            severity: 'success',
          });
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setLoading(false);
          setMessageObject({
            text: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.data,
            severity: 'error',
          });
          setOpenSnackbar(true);
          resetForm();
        });
      setSubmitting(false);
    },
    validate: (valuesval) => {},
  });

  const permissions = useSelector(
    (state) => state?.allPermissions?.permissions?.data
  );
  // console.log('Permissions', permissions, values);
  return (
    <form
      onSubmit={handleSubmit}
      noValidate={true}
      style={{ padding: '8px 16px', paddingBottom: '16px' }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography
          variant='subtitle1'
          align='left'
          sx={{ fontSize: '14px', mb: 1 }}
        >
          {t(`Email Address`)}
        </Typography>
        <StyledInput
          placeholder='Email'
          type='email'
          variant3='true'
          secondary='true'
          onChange={handleChange}
          name='email'
          value={values.email}
          disabled
          style={{ background: '#F5F5F5' }}
          errorclass={errors.email && touched.email ? 'errorMessage' : null}
        />
        {errors.email && touched.email ? (
          <ErrorMessage>{errors.email}</ErrorMessage>
        ) : null}
        <Typography
          variant='subtitle1'
          align='left'
          sx={{ fontSize: '14px', my: 1 }}
        >
          {t(`Permissions`)}
        </Typography>
        {permissions &&
          permissions.map((ele, index) => {
            // console.log(ele._id);
            return (
              <Box sx={{ marginBottom: '12px' }} key={index}>
                <StyledCheckbox
                  isChecked={values.permissions.includes(ele._id)}
                  label={t(ele.name)}
                  id={ele._id}
                  name='permissions'
                  value={ele._id}
                  onChange={handleChange}
                  errorclass={
                    errors.permissions && touched.permissions
                      ? 'errorMessage'
                      : null
                  }
                />
              </Box>
            );
          })}
        {errors.permissions && touched.permissions ? (
          <ErrorMessage>{errors.permissions}</ErrorMessage>
        ) : null}
      </Box>
      <StyledButton type='submit' iconButton={true} loading={loading}>
        <SendInviteIcon style={{ marginRight: '6px' }} />
        {t(`Send`)}
      </StyledButton>
    </form>
  );
}

export default UpdateUserInviteForm;
