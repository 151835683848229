/* eslint-disable no-unused-vars */
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { getPresignedURL } from '../../../redux/services/category';
import ImagePreview from '../../ImagePreview/ImagePreview';
import StyledInput from '../../Input/Input';
import { uploadJournalCover } from '../validation';
import axios from 'axios';
import Modal from '../../Modal/Modal';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import ImageCropperV2 from 'src/components/ImageCropper/ImageCropperV2';
import apiConfig from 'src/config/api.service';
import { useTranslation } from 'react-i18next';

const initialValues = {
  fileName: null,
};
function UploadJournalCoverForm({
  setFileCover,
  open,
  setOpen,
  fileData,
  fileCover,
  setMessageObject,
  setOpenSnackbar,
  height,
  aspectRatio,
  publicsite,
}) {
  const allowedFileTypes = `image/png, image/jpeg, image/x-png`;
  const [file, setFile] = useState();
  const [viewImage, setViewImage] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);
  const [showTypeError, setShowTypeError] = useState(false);
  const [persistImage, setPersistImage] = useState(null);
  // const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef();

  const handleToggle = () => {
    setFile(null);
    inputRef.current.click();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const toggleDisplay = (file) => {
    return file ? 'none' : '';
  };

  // Upload Image function
  const uploadImage = async (blob) => {
    // console.log('blob', blob);
    // console.log('file', file);
    setLoading(true);
    // console.log('Upload Image func', file.type);
    // get presign url
    const { presignedUrl, imgKey } = await getPresignedURL({
      contentType: blob ? blob?.type : file.type,
      key: publicsite
        ? 'public/cover/' +
          uuid() +
          file?.name
            .split(' ')
            .join('-')
            // eslint-disable-next-line
            .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
        : uuid() +
          file?.name
            .split(' ')
            .join('-')
            // eslint-disable-next-line
            .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ''),
    });
    // upload image
    const res = await axios({
      method: 'PUT',
      data: blob ? blob : { size: file.size, type: file.type },
      headers: {
        'Content-Type': blob ? blob?.type : file.type,
      },
      url: presignedUrl,
    });
    setLoading(false);
    setFileCover(imgKey);
    setCroppedImage(blob);
    setPersistImage(blob);
    setOpen(false);
    setViewImage(imgKey);
    // console.log('View Image', blob, imgUrl_3200);
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: uploadJournalCover,
    });

  const handleCancel = () => {
    setOpen(false);
    setFile(null);
    console.log('hereeee');
    setFieldValue('fileName', null);
    // setFile(null);
  };

  const handleCancelModal = () => {
    setImageUrl(null);
    handleCancel();
    // setFile(null);
  };
  return (
    <form
      onSubmit={handleSubmit}
      noValidate={true}
      style={{ position: 'relative' }}
    >
      <StyledInput
        type='file'
        secondary='true'
        style={{ display: toggleDisplay(file && fileData?.image) }}
        ref={inputRef}
        multiple={false}
        name='fileName'
        accept={allowedFileTypes}
        onClick={(e) => {
          e.target.value = '';
        }}
        onChange={(e) => {
          if (
            !['image/png', 'image/jpg', 'image/jpeg'].includes(
              e?.target?.files[0]?.type
            ) ||
            e?.target?.files[0]?.size / 1000000 > 30
          ) {
            setFile('');
            setShowTypeError(true);
            setMessageObject({
              text: t('File type/size not supported.'),
              severity: 'error',
            });
            setLoading(false);
            setOpenSnackbar(true);

            setFieldValue('fileName', null);
            return;
          }

          handleChange(e);
          setFieldValue('fileName', e.target.files[0]);
          if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setOpen(true);
          }
        }}
        errorclass={errors.fileName && touched.fileName ? 'errorMessage' : null}
      />

      <Modal
        handleClose={handleCancelModal}
        open={open}
        title='Crop Image'
        variant='image-cropper'
      >
        <ImageCropperV2
          file={file}
          uploadImage={uploadImage}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          loading={loading}
          handleCancel={handleCancel}
          aspectRatio={aspectRatio}
        />
      </Modal>
      <ImagePreview
        style={{
          display: toggleDisplay(!file && !fileData?.image),
          height: height && height,
        }}
        id='image-preview'
        src={
          croppedImage
            ? URL.createObjectURL(croppedImage)
            : apiConfig.imageUrlPrefix + fileData?.image
        }
        secondary='true'
        alt={values.fileName?.name}
        onClick={handleToggle}
      />
    </form>
  );
}

export default UploadJournalCoverForm;
