/* eslint-disable no-use-before-define */
import React, { useRef, useState } from 'react';
import { Box, styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import StyledInput from '../../components/Input/Input';
import StyledSelect from '../StyledSelect/StyledSelect';
import StyledTextArea from '../Input/TextArea';
// import { ReactComponent as InfoIcon } from '../../assets/info-circle.svg';
// import { useSelector } from 'react-redux';
import StyledButton from 'src/components/Button/Button';
import Modal from 'src/components/Modal/Modal';
import {
  deletePage,
  getPages,
  getPresignedURL,
  updatePage,
} from 'src/redux/services/category';
import Trash from 'src/assets/trash.png';
import * as yup from 'yup';
import { imgFileNameConvention, tagsList, validateFileUpload } from 'src/helpers/utils';
import axios from 'axios';
import { useDispatch } from 'react-redux';
// import { getSWTagsValue, getTag } from 'src/helpers/tags';
// import TagsFields from '../TagsField/TagsFields';
// import { setPages } from 'src/redux/actions/general';
import { useTranslation } from "react-i18next";
import RemoveJournal from '../AddJournalForm/RemoveJournal';
import TagsFieldList from '../TagsField/TagsFieldList';
import { setPages } from 'src/redux/actions/general';


const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  marginBottom: '8px',
});

const ReplaceImgWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '15px',
  '.replace-page': {
    width: '100%',
  },
  '.remove-page': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '36px',
    height: '36px',
    border: '1px solid #D9DEE2',
    cursor: 'pointer',
  },
  Button: {
    borderRadius: '0',
    width: '100%',
    height: '36px',
    color: '#BB8D0B',
    border: '1px solid #D9DEE2',
    textTransform: 'capitalize',
  },
});

const pageUpdateSchema = yup.object().shape({
  fileNo: yup.number(),
  description: yup
    .string()
    .trim()
    .max(5000, 'Must be less than 5000 characters'),
});

function PageUpdateForm({
  setDetailsModal,
  allLanguageTags,
  items,
  setItems,
  setImage,
  image,
  setPercentage,
  setProgressBar,
  progressBar,
  pageDetails,
  setPageDetails,
  translate,
  saveDetails,
  setSaveDetails,
  // eslint-disable-next-line no-use-before-define
  searchItems,
  pages,
  language,
  setOpenSnackbar,
  setMessageSnackbar,
  tagsOptions,
  setTagsOptons,
  clickTranslate,
  setClickTranslate
}) {
  const {
    values,
    // setValues,
    errors,
    touched,
    handleChange,
    // eslint-disable-next-line no-unused-vars
    handleBlur,

    handleSubmit,
  } = useFormik({
    initialValues: {
      fileNo: pageDetails?.pageNum,
      description: pageDetails?.description,
      tag: pageDetails?.tags,
      tags_sw: pageDetails?.tag_sw,
    },
    validationSchema: pageUpdateSchema,
    onSubmit: async (values) => {
      try {
        setSaveDetails(true);
        //Language Wise Data Formation  

       let tagsFormat= tagsOptions.map((tags)=>{
          if("_id" in tags){
            return {_id: tags?._id, en: tags.name_en, sw: tags.name_sw};
          }
          else{
            return {en: tags.name_en || "", sw: tags.name_sw || ""}
          }
        })
       console.log(tagsFormat)


        let data;
        let res;
        //Language Switch Check
        if(language === "en"){
        //Final Data
        data = {
          image: image,
          title_en: `${pageDetails?.pageNum}`,
          title_sw: `${pageDetails?.pageNum}`,
          description_en: pageDetails?.description || "",
          description_sw: pageDetails?.description_sw || "",
          journal: pageDetails?.journalId,
          tags: tagsFormat,
          creator: pageDetails?.creator,
          pageNum: +pageDetails?.pageNum,
        };
        if(!clickTranslate){
          delete data?.description_sw;
      }

       res = await updatePage(pageDetails?.id, data,language);
      }
      else{
        //Final Data
        data = {
          image: image,
          title_en: `${pageDetails?.pageNum}`,
          title_sw: `${pageDetails?.pageNum}`,
          description_en: pageDetails?.description_en || "",
          description_sw: pageDetails?.description || "",
          journal: pageDetails?.journalId,
          tags: tagsFormat,
          creator: pageDetails?.creator,
          pageNum: +pageDetails?.pageNum,
        };
        console.log(clickTranslate)
        if(!clickTranslate){
            delete data?.description_en;
        }

       res = await updatePage(pageDetails?.id, data,language);
      }

      if(!searchItems){
        console.log(res?.pages);
        setItems(res?.pages);
        dispatch(setPages({pages: res?.pages, totalCount: pages?.totalCount}))
      }
      else{
        // eslint-disable-next-line array-callback-return
        const updatedPage = items?.map((item)=>{
          if(item?._id === res?._id){
            item.tags=res?.tags;
            item.description = pageDetails?.description;
          }
          return item;
        })
        setItems(updatedPage);
      }
      
      } catch (err) {
         console.log(err);
      } finally {
        setSaveDetails(false);
        setClickTranslate(false);
      //   if(!translate){
      //   setPageDetails({
      //     ...pageDetails,
      //     description_sw: '',
      //     tags_sw: [],
      //   });
      //  }
       setOpenSnackbar(true);
       setMessageSnackbar({ severity: 'success', text: t('Page Updated') })
       
      }
    },
    onChange: (values) => {
      console.log(values);
    },
    validate: (values) => {
      // setPageDetails({
      //   ...pageDetails,
      //   tags: tagsEN,
      // });
    },
  });
  const {t}=useTranslation();
  const [fieldError, setFieldError] = useState({
    textAreaEN: { state: false, msg: '' },
    textAreaSW: { state: false, msg: '' },
    autocompleteEN: { state: false, msg: '' },
    autocompleteSW: { state: false, msg: '' },
    inputTypeNumber: { state: false, msg: '' },
  });
  const [removeModal,setRemoveModal]=useState(false);

  // Form fileds data
  const FormFields = [
    {
      id: '1',
      fieldName: 'Replace this page',
      field: 'button',
      key: 'replacePage',
      // placeholder: 'Enter file title',
    },
    {
      id: '2',
      fieldName: 'Page Number',
      field: 'text-input',
      key: 'fileNo',
      // placeholder: 'Enter file title',
      // isSw: true,
    },
    {
      id: '3',
      fieldName: 'Page Description',
      field: 'text-area',
      key: 'description',
      placeholder: 'Enter page description',
    },
    {
      id: '4',
      fieldName: 'Tags',
      field: 'autocomplete',
      options: tagsList(allLanguageTags,language),
      key: 'tag',
    },
  ];

  const inputRef = useRef();
  const dispatch = useDispatch();

  const handleFileUpload = async (event) => {
    event?.preventDefault();
   

    try {
      let files = event?.target?.files;

      if(validateFileUpload(['jpg', 'jpeg', 'png', 'gif'], files[0]?.name)){
      //getPresignedUrl
      const { presignedUrl, imgKey } = await getPresignedURL({
        contentType: files[0]?.type,
        key: `/journal/:${pageDetails?.journalId}/page/:${imgFileNameConvention(
          files[0]?.name
        )}`,
      });
      //upload on S3 bucket
      await axios({
        method: 'PUT',
        data: files[0],
        headers: {
          'Content-Type': files[0]?.type,
        },
        url: presignedUrl,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percentage = Math.floor((loaded * 100) / total);
          setProgressBar(true);
          setPercentage(percentage);
        },
      }).then((res) => {
        // console.log(res);
        setProgressBar(false);
        setTimeout(() => {
          setImage(imgKey);
        }, 2000);
      });
    }
    else{
      setOpenSnackbar(true);
      setMessageSnackbar({ severity: 'info', text: t('File Not Supported') });
    }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const removePage = async () => {
    setDetailsModal(false);
    await deletePage(pageDetails?.id);
    await getPages(pageDetails?.journalId, dispatch);
  };
  const inputFields = (Fields_en) => {
    // console.log(inputValues?.tags_sw);
    Fields_en?.forEach((val) => {
      // console.log(val?.options);
    });
    return Fields_en?.map((ele) => {
      return (
        <Box key={ele.id}>
          {!ele?.sw ? (
            <Typography
              variant="subtitle1"
              align="left"
              sx={{ fontSize: "14px", mb: 1, mt: 1 }}
            >
              {t(ele?.fieldName)}
            </Typography>
          ) : null}
          {ele.field === "button" ? (
            <ReplaceImgWrapper>
              <div className="replace-page" onClick={handleClick}>
                <Button>{t(`Upload Page`)}</Button>
                <input
                  ref={inputRef}
                  type="file"
                  onChange={handleFileUpload}
                  hidden
                  accept="image/png, image/jpeg, image/gif "
                />
              </div>
              <div className="remove-page" onClick={() => setRemoveModal(true)}>
                <img src={Trash} alt="delete-page" width="18px" height="18px" />
              </div>
            </ReplaceImgWrapper>
          ) : ele.field === "select-input" ? (
            <StyledSelect
              name={ele.key}
              defaultVal={values[ele.key]}
              value={values[ele.key]}
              handleChange={handleChange}
              validateOnChange={true}
              errorclass={
                errors[ele.key] && touched[ele.key] ? "errorMessage" : null
              }
              style={{ width: "100%" }}
            />
          ) : ele.field === "autocomplete" ? (
            <>
              <TagsFieldList
                options={tagsList(allLanguageTags, language)}
                language={language}
                tagsOptions={tagsOptions}
                setTagsOptons={setTagsOptons}
                page
              />
              {/* <TagsFields
                field={{
                  options: ele?.options,
                  key: ele?.key,
                  error: fieldError?.autocompleteEN,
                }}
                tags={tagsEN}
                setTags={setTagsEN}
                selectedTags={selectedTagsEN}
                setSelectedTags={setSelectedTagsEN}
              />
              {translate ? (
                <TagsFields
                  field={{
                    options: ele?.options,
                    key: ele?.key,
                    error: fieldError?.autocompleteSW,
                    style: { marginTop: '-17px' },
                  }}
                  tags={tagsSW}
                  setTags={setTagsSW}
                  selectedTags={selectedTagsSW}
                  setSelectedTags={setSelectedTagsSW}
                />
              ) : 
              null} */}
            </>
          ) : ele.field === "text-area" ? (
            <>
              <StyledTextArea
                placeholder={t(ele.placeholder)}
                type="text"
                name={ele.key}
                value={pageDetails?.description}
                errorclass={
                  fieldError?.textAreaEN?.state ? "errorMessage" : null
                }
                onChange={(e) => {
                  console.log(e.target.value.length);
                  setPageDetails({
                    ...pageDetails,
                    description: e.target.value,
                  });
                  //Errors
                  if (e.target.value.length >= 5000) {
                    setFieldError({
                      ...fieldError,
                      textAreaEN: {
                        state: true,
                        msg: "Must be less than 5000 characters",
                      },
                    });
                  } else {
                    setFieldError({
                      ...fieldError,
                      textAreaEN: { state: false, msg: "" },
                    });
                  }
                }}
              />
              {fieldError?.textAreaEN?.state ? (
                <ErrorMessage>{fieldError?.textAreaEN?.msg}</ErrorMessage>
              ) : null}
              {translate ? (
                <StyledTextArea
                  placeholder={ele.placeholder}
                  type="text"
                  style={{ marginTop: "-7px" }}
                  name={ele.key}
                  value={pageDetails?.description_sw}
                  onChange={(e) => {
                    setPageDetails({
                      ...pageDetails,
                      description_sw: e.target.value,
                    });
                    //Errors
                    if (e.target.value.length > 5000) {
                      setFieldError({
                        ...fieldError,
                        textAreaSW: {
                          state: true,
                          msg: "Must be less than 5000 characters",
                        },
                      });
                    } else {
                      setFieldError({
                        ...fieldError,
                        textAreaSW: { state: false, msg: "" },
                      });
                    }
                  }}
                  errorclass={
                    fieldError?.textAreaSW?.state ? "errorMessage" : null
                  }
                />
              ) : null}
              {fieldError?.textAreaSW?.state ? (
                <ErrorMessage>{fieldError?.textAreaSW?.msg}</ErrorMessage>
              ) : null}
            </>
          ) : (
            <>
              <StyledInput
                placeholder={ele.placeholder}
                type="number"
                variant3="true"
                name={ele.key}
                // disable={true}
                value={pageDetails?.pageNum}
                onChange={(e) => {
                  //Backspace or value range (1 to total pages)
                  if (
                    (+e.target.value >= 1 &&
                      +e.target.value <= +pageDetails?.pageCount) ||
                    pageDetails?.pageNum?.length > e?.target?.value
                  ) {
                    console.log(e?.target?.value?.length);
                    setPageDetails({
                      ...pageDetails,
                      pageNum: e.target.value,
                    });
                    if (e?.target?.value.length === 0) {
                      setFieldError({
                        ...fieldError,
                        inputTypeNumber: {
                          state: true,
                          msg: "This field is required.",
                        },
                      });
                    } else {
                      setFieldError({
                        ...fieldError,
                        inputTypeNumber: { state: false, msg: "" },
                      });
                    }
                  }
                }}
                style={{ marginBottom: "0px !important" }}
                errorclass={
                  fieldError?.inputTypeNumber?.state ? "errorMessage" : null
                }
                secondary="true"
                min={1}
                max={pageDetails?.pageCount}
              />
              {fieldError?.inputTypeNumber?.state ? (
                <ErrorMessage>{fieldError?.inputTypeNumber?.msg}</ErrorMessage>
              ) : null}
            </>
          )}
        </Box>
      );
    });
  };

  const errorCheck=()=>{
    return fieldError?.textAreaEN?.state || fieldError?.textAreaSW?.state
     || fieldError?.autocompleteEN?.state || fieldError?.autocompleteSW?.state
     || fieldError?.inputTypeNumber?.state
  }

  return (
    <>
    {
      removeModal ? <Modal
      title= {t('Remove Page')}
      open={removeModal}
      handleClose={() => setRemoveModal(false)}
    >
      <RemoveJournal
      removePage
      deletePage={removePage}
      />
    </Modal> : null
    }
    <form onSubmit={handleSubmit}>
      {inputFields(FormFields)}
      <StyledButton
        type='submit'
        style={{ width: '100%', marginTop: '10px', marginBottom: '5px' }}
        disable={
          progressBar ||
          // (translate ? !(tagsSW?.length === tagsEN?.length) : false)
           errorCheck()
        }
        loading={saveDetails}
        secondary
      >
        {t(`Save Details`)}
      </StyledButton>
    </form>
    </>
  );
}

export default PageUpdateForm;
