import React, { useState } from 'react';
import styled from 'styled-components';
import AppWrapper from '../../components/AppWrapper/AppWrapper';
import TopBar from '../../components/TopBar/TopBar';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { updateProfileSchema } from './validation';
import { Box } from '@mui/system';
import StyledInput from '../../components/Input/Input';
import StyledButton from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePictureUpload from '../../components/ProfilePictureUpload/ProfilePictureUpload';
import { updateUser } from '../../redux/services/users';
import { updateUserState } from '../../redux/actions/auth';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

const Container = styled('div')({
  padding: '16px',
});
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  height: '24px',
  // marginTop: '5px',
  lineHeight: '24px',
  // marginBottom: '12px',
});

const FormFields = [
  {
    id: '1',
    fieldName: 'Full Name',
    placeholder: 'Full Name',
    isRequired: true,
    name: 'fullname',
  },
  {
    id: '2',
    fieldName: 'Email address',
    placeholder: 'Email address',
    type: 'email',
    name: 'email',
  },
];
function Settings() {
  const [disableState, setDisableState] = useState(true);
  const [changePassComponent, setChangePassComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const { t } = useTranslation();
  const handleSnackbar = () => {
    setOpenSnackbar(false);
  };
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const initialValues = {
    fullname: user?.name,
    email: user?.email,
  };
  const handleClick = () => {
    setChangePassComponent(false);
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: updateProfileSchema,
    onSubmit: (values) => {
      setLoading(true);
      // console.log('VALUES -->', values);
      const data = {
        name: values.fullname.trim(),
      };
      updateUser(data)
        .then((res) => {
          dispatch(updateUserState(res?.data?.user));
          setLoading(false);
          setMessageObject({
            severity: 'success',
            text: res?.message,
          });
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setLoading(false);
          setMessageObject({
            severity: 'error',
            text: err?.message,
          });
          setOpenSnackbar(true);
        });
    },
  });
  return (
    <AppWrapper>
      {changePassComponent === false ? (
        <Box>
          <TopBar fontSize='20px' title={t('SETTINGS')} />
          <Container>
            <Typography
              variant='subtitle1'
              align='left'
              sx={{
                fontSize: '16px',
                mb: 1,
                fontFamily: 'Open Sans',
                fontWeight: '600',
              }}
            >
              {t(`Account Details`)}
            </Typography>
            <ProfilePictureUpload
              setMessageObject={setMessageObject}
              setOpenSnackbar={setOpenSnackbar}
            />
            <form
              onSubmit={handleSubmit}
              noValidate={true}
              style={{ maxWidth: '624px' }}
            >
              {FormFields.map((ele) => {
                return (
                  <Box key={ele.id} sx={{ mb: 3, maxWidth: '624px' }}>
                    <Typography
                      variant='subtitle1'
                      align='left'
                      variant3='true'
                      sx={{
                        fontSize: '12px',
                        mb: 1,
                        fontFamily: 'Open Sans',
                        color: '#292D32',
                        fontWeight: '600',
                      }}
                    >
                      {t(ele.fieldName)}
                      {ele.isRequired ? (
                        <sup style={{ color: '#B1484E' }}>*</sup>
                      ) : null}
                    </Typography>
                    <StyledInput
                      style={{
                        background: ele.type === 'email' ? '#F5F5F5' : '',
                        marginBottom: '0px',
                      }}
                      placeholder={ele.placeholder}
                      profileUpload={true}
                      type={ele.type}
                      secondary='true'
                      name={ele.name}
                      value={values[ele.name]}
                      disabled={ele.type === 'email' ? true : false}
                      onFocus={() => setDisableState(false)}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      errorclass={
                        errors.category && touched.category
                          ? 'errorMessage'
                          : null
                      }
                    />
                    <ErrorMessage>
                      {errors.fullname &&
                      touched.fullname &&
                      ele.name === 'fullname'
                        ? errors.fullname
                        : null}
                    </ErrorMessage>
                  </Box>
                );
              })}
              <Box sx={{ mb: 3, maxWidth: '135px' }}>
                <StyledButton
                  type='submit'
                  btnvariant='true'
                  loading={loading}
                  disable={disableState === true ? true : false}
                >
                  {t(`Save Changes`)}
                </StyledButton>
              </Box>
            </form>
            <Typography
              variant='subtitle1'
              align='left'
              sx={{
                fontSize: '12px',
                mb: 1,
                fontFamily: 'Open Sans',
                color: '#292D32',
                fontWeight: '600',
              }}
            >
              {t(`Password`)}
            </Typography>
            <Box sx={{ mb: 3, maxWidth: '140px' }}>
              <StyledButton
                type='submit'
                btnvariant='true'
                onClick={() => setChangePassComponent(true)}
              >
                {t(`Change Password`)}
              </StyledButton>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box>
          <TopBar
            fontSize='20px'
            title={t('SETTINGS')}
            backLink='/settings'
            onClick={handleClick}
          />
          <ChangePassword
            setChangePassComponent={setChangePassComponent}
            setMessageObject={setMessageObject}
            setOpenSnackbar={setOpenSnackbar}
          />
        </Box>
      )}
      {openSnackbar && (
        <StyledSnackbar
          handleClose={handleSnackbar}
          open={openSnackbar}
          messageObject={messageObject}
        />
      )}
    </AppWrapper>
  );
}

export default Settings;
