import * as React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { ReactComponent as LoadingIcon } from '../../assets/refresh-b.svg';

const Loading = styled(LoadingIcon)({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  animation: 'rotate 2s linear infinite',
});

const IconButton = styled(Button)({
  color: '#BB8D0B !important',
  width: '100px',
  padding: '6px 12px !important',
  background: '#FFFFFF',
  border: '1px solid #D9DEE2',
  borderRadius: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  height: '36px !important',
  fontSize: '12px !important',
  textTransform: 'capitalize !important',
  ':hover': {
    background: 'white !important',
  },
  '&.Mui-disabled': {
    background: '#E9D5B2 !important',
    color: 'white !important',
  },
});

export default function TranslateButton({
  onClick,
  type,
  children,
  disable,
  loading,
}) {
  return (
    <IconButton onClick={onClick} type={type} disabled={disable}>
      {loading ? <Loading /> : children}
    </IconButton>
  );
}
